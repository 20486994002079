import React, { useEffect } from "react";
import Styles from "./Section4.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
function Section4() {
  useEffect(() => {
    Aos.init({
      offset: 100,
      duration: 1000,
    });
  });
  return (
    <main className={Styles.grid}>
      <section
        className={`${Styles.content} ${Styles.item1}`}
        data-aos="zoom-out"
      >
        <h1>Rules and Regulation</h1>
        <p>
          Please Read the Rules and Regulation Properly. When you play Quiz it
          means you know the Rules and Regulation
        </p>
      </section>
      <section
        className={`${Styles.card} ${Styles.item2}`}
        data-aos="fade-right"
      >
        There will be 10 questions asked every day and 60 second for each
        Question.
      </section>
      <section
        className={`${Styles.card} ${Styles.item3}`}
        data-aos="fade-up-right"
      >
        For each correct & wrong answer you reward +4 and -1 points
        respectivily.
      </section>
      <section
        className={`${Styles.card} ${Styles.item4}`}
        data-aos="fade-down"
      >
        Time for Playing the Quiz-A-Thon is only 8:00 PM to 8:20 PM
      </section>
      <section
        className={`${Styles.card} ${Styles.item12}`}
        data-aos="zoom-right"
      >
        The question only contains topics Math, Science, Reasoning, Aptitude, GK & English.
      </section>
      <section className={`${Styles.card} ${Styles.item5}`} data-aos="zoom-in">
        In case of tie, participant with least wrong answer then least time will be given
        priority.
      </section>
      <section className={`${Styles.card} ${Styles.item7}`} data-aos="fade-up">
        You have to answer the given Question in given format.
      </section>
      <section
        className={`${Styles.card} ${Styles.item8}`}
        data-aos="fade-down-left"
      >
        <p>
          If the participant is found to be using unfair means then they shall
          be eliminated or deduction of points.
        </p>
      </section>
      <section
        className={`${Styles.card} ${Styles.item9}`}
        data-aos="fade-left"
      >
        If a participant skips a question then NO POINT deduction.
      </section>
      <section
        className={`${Styles.card} ${Styles.item10}`}
        data-aos="fade-left"
      >
        Only Top 30 participant will be this round after overall Ranking.
      </section>
      <section
        className={`${Styles.card} ${Styles.item11}`}
        data-aos="fade-up-left"
      >
        In case of any queries/issues the decision of the organisers will be
        final.
      </section>
    </main>
  );
}

export default Section4;
