import React, { useEffect, useState } from "react";
import Styles from "./Section3.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "wouter";
function Section3() {
  const [ani, setAni] = useState(true);
  useEffect(() => {
    Aos.init({
      offset: 100,
      duration: 1000,
      delay: 500,
    });
  });
  return (
    <div className="container-fluid">
      <div className="row">
        <div className={Styles.sec}>
          <div className="col-lg-12">
            <h1
              className={Styles.title}
              data-aos={ani ? "fade-down" : "fade-up"}
            >
              Benefits
            </h1>
          </div>
          <div className="row">
            <div className={`col-lg-12 ${Styles.card__sec}`}>
              <div className={`col-lg-4 ${Styles.Card} `}>
                <div
                  className={`${Styles.card} ${Styles.card2}`}
                  data-aos={ani ? "fade-right" : "fade-up"}
                >
                  <h3>Exciting Prize</h3>
                  <p>
                    A Quiz-a-thon is an event by {""}
                    <span className={Styles.udaan}>Udaan</span> where you can
                    win cash amount, rewards, and a lot of things.
                  </p>
                </div>
              </div>

              <div className={`col-lg-4 ${Styles.Card} `}>
                <div
                  className={`${Styles.card} ${Styles.card3}`}
                  data-aos={ani ? "zoom-in" : "fade-up"}
                >
                  <h3>Develop Skills</h3>
                  <p>
                    Quiz-a-thon help you to improve your speed, logical
                    thinking, and helps you in time management.
                  </p>
                </div>
              </div>

              <div className={`col-lg-4 ${Styles.Card} `}>
                <div
                  className={`${Styles.card} ${Styles.card1}`}
                  data-aos={ani ? "fade-left" : "fade-up"}
                >
                  <h3>Only For You</h3>
                  <p>
                    This year, we are conducting this Quiz-a-thon for our
                    RECians, the most awaited event by team{" "}
                    <span className={Styles.udaan}>Udaan</span>
                  </p>
                </div>
              </div>

              <div className={`col-lg-4 ${Styles.Card} `}>
                <div
                  className={`${Styles.card} ${Styles.card1}`}
                  data-aos={ani ? "fade-up-right" : "fade-up"}
                >
                  <h3>Win Means</h3>
                  <p>
                    Apart from winning prizes in Quiz-a-thon, and also you will
                    get a chance to be featured in portal{" "}
                    <Link href="/winner">Winner</Link> section.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className={`${Styles.card} ${Styles.card2}`}
                  data-aos={ani ? "fade-up" : "fade-up"}
                >
                  <h3>Learning</h3>
                  <p>
                    <span className={Styles.udaan}>Udaan</span> designs
                    Quiz-a-thon in such a way that you can make the most out of
                    it .you can learn and enjoy together.
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className={`${Styles.card} ${Styles.card3}`}
                  data-aos={ani ? "fade-up-left" : "fade-up"}
                >
                  <h3>Talent</h3>
                  <p>
                    Quiz-a-thon is an event where you can showcase your talent,
                    while everybody will get to see your talent and appreciate
                    it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section3;
