
import { StylesProvider } from '@material-ui/core'
import React from 'react'
import Styles from "./Section2.module.css"

function Section2() {
  return (
    <div className={Styles.footer}>
      <div>
        <p>Made With ❤️ for RECians</p>
        <p>By Team <span className={Styles.udaan}>Udaan</span></p>
      </div>
      
    </div>
  )
}

export default Section2
