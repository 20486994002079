import React from "react";
import Card from "../Cards/Card";
import Styles from "./Content.module.css";

export default function Content(props) {
  var datas = [];
  datas = props.arr;

  return (
    <div className={Styles.bg}>
      <div className="container-fluid" id={Styles.div}>
        <hr className={Styles.hr}></hr>
        <h1 className={Styles.head}>{props.title}</h1>
        <br></br>
        <div className={Styles.Card}>
          <br></br>
          {datas.map((data, i) => (
            <div className="">
              <Card
                Name={data.name}
                Year={data.year}
                Branch={data.branch}
                Time={data.time}
                Rank={data.rank}
                Point={data.point}
                Round={data.round}
                Total={props.total}
                Level={props.level}
                Photo={data.photo}
                Session={data.session}
                title={data.title}
                contest={data.contest}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
