import React from "react";
import "./homepage.css";
import qs from "qs";
import Layout from "../Layout/Layout";
import Corousal from "../../containers/Corousel/Corousel";
import Features from "../Features/Features";
import AppFeatures from "../AppFeatures/AppFeatures";
import SuccessStories from "../SuccessStories/SuccessStory";
import TeamInfo from "../TeamInfo/TeamInfo";
import CoFounders from "../Cofounders/Cofounders";
import TeamMembers from "../../containers/TeamMembers/TeamMember";
import { useEffect } from "react";
import { useLocation } from "wouter";
import AboutUS from "../AboutUs/AboutUs";

const HomePage = (props) => {
    const [location, setLocation] = useLocation();
    useEffect(() => {
        let rdr = qs.parse(window.location.search, { ignoreQueryPrefix: true })
            .rdr;
        if (undefined === rdr || rdr.length === 0 || rdr === null) {
        } else {
            setLocation(rdr);
            if (rdr === "adminpanel") {
                let loginCheckInterval = setInterval(() => {
                    if (props.isLoggedIn()) {
                        if (props.store.state.userType === "admin") {
                            setLocation("/adminpanel");
                        }
                        clearInterval(loginCheckInterval);
                    }
                }, 100);
            }
            if (rdr === "login") {
                let loginCheckInterval = setInterval(() => {
                    if (props.isLoggedIn()) {
                        setLocation("/");
                    } else {
                        setLocation("/login");
                    }
                    clearInterval(loginCheckInterval);
                }, 100);
            }
            if (rdr === "notice") {
                let loginCheckInterval = setInterval(() => {
                    setLocation("/notice");
                    clearInterval(loginCheckInterval);
                }, 100);
            }
        }
    }, []);

    return (
        <div>
            <Layout>
                <Corousal />
                <Features />
                <AppFeatures />
                <SuccessStories />
                {/* <hr  className="hr1"/> */}
                <TeamInfo />
                {/* <hr /> */}
                <CoFounders />
                <TeamMembers />
                <AboutUS />
            </Layout>
        </div>
    );
};

export default HomePage;
