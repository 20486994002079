import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider, Subscribe } from 'unstated';
import recspContainer from './Store/recspContainer';

const container = document.getElementById('app');

ReactDOM.render(
  <Provider>
    <Subscribe to={[recspContainer]}>
      {recspContainer => <App mainContainer={recspContainer} />}
    </Subscribe>
  </Provider>,
  container
);
