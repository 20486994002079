import React, { useState } from "react";
import Styles from "./Invalid.module.css";
import Animation4 from "../Animation/Animation4/Animation";
import { useLocation } from "wouter";
var time = true;
function Invalid() {
  const [location, setLocation] = useLocation();
  const [timer, setTimer] = useState();
  const [count, setCount] = useState();

  const run = () => {
    var key = document.getElementById("timer");
    if (key) {
      key = key.innerText;
      document.getElementById("timer").innerText = key - 1;
      setCount(key);
    }
  };
  const start = () => {
    setTimer(setInterval(run, 1000));
  };
  if (count <= 0) {
    clearInterval(timer);
    setLocation("/");
  }
  if (time) {
    time = false;
    start();
  }
  return (
    <div className={Styles.invalid}>
      <Animation4 />
      <div>
        <h1>404 Error Page.. </h1>
        <p> Sorry , This page doesn't exist !!! </p>
        <p>
          Redirect to Main Page in <span id="timer"> 5</span>s
        </p>
      </div>
    </div>
  );
}

export default Invalid;
