import React, { Component } from "react";
import {
  Paper,
  Typography,
  FormLabel,
  Input,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  // TableContainer,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackBar from "../../../components/SnackBar/SnackBar";
import axios from "axios";
import NoticeTile from "./NoticeTile";
import $ from "jquery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import NoticeCss from "./NoticePanel.module.css";

class NoticePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: 0,
      toEdit: null,
      editing: false,
      message: "",
      loading: false,
      uploading: false,
      ButtonDisabled: false,
      tBody: null,
      editModalOpen: false,
      modalOpen: false,
      deleting: false,
      config: props.container.config,
      fiveNotice: null,
      superState:props.container
    };
    this.handleNoticeUploadSubmit = this.handleNoticeUploadSubmit.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleYesDelete = this.handleYesDelete.bind(this);
    this.handleNoticeEdit = this.handleNoticeEdit.bind(this);
    this.handleYesEdit = this.handleYesEdit.bind(this);
  }

  populateTable(data) {
    data = JSON.parse(data);
    console.log(data);
    let tBody = [];
    for (var i = 0; i < data.length; i++) {
      let d = data[i];
      tBody.push(
        <NoticeTile
          key={d.id}
          id={d.id}
          name={d.topic}
          date={d.date}
          delete={() => this.deleteHandler(d.id)}
          edit={() => this.handleNoticeEdit(d.id)}
        />
      );
    }
    this.setState({
      tBody: tBody,
    });
  }

  getFiveNotices() {
    axios
      .post(
        this.state.superState.apiUrl+"getFiveNotices.php",
        { key: this.state.superState.publicKey },
        this.state.config
      )
      .then((response) => {
        console.log(response);
        this.populateTable(response.data);
        this.setState({
          fiveNotice: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getFiveNotices();
  }

  handleClose() {
    this.setState({
      message: "",
    });
  }

  clearForm() {
    $("#noticePDF").val("");
    $("#noticeName").val("");
  }

  handleNoticeEdit(id) {
    console.log(id)
    this.setState({
      editId: id,
      
    });

    let i = 0;
    let editObect = JSON.parse(this.state.fiveNotice)
    for (i = 0; i < 5; i++) {
      console.log(editObect[i].id+"=>"+id)
      if (editObect[i].id === id) {
        console.log("Matched")
        this.setState({
          toEdit: editObect[i],
        });
      }
    }
    this.setState({
      editModalOpen:true
    })
  }

  handleYesEdit() {
    let newName=$('#noticeNameEdit').val();
    if(newName.length<1){
      this.setState({
        message: (
          <SnackBar
            key={Math.random()}
            msg="New notice title too short"
            status={"failed"}
          />
        ),
      });
      return
    }
    const config = {
      withCredentials: true,
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.props.container.accessToken}`,
      },};
    const formData={newEditname: newName, id: this.state.editId,key:this.state.superState.publicKey}
    this.setState({
      editing:true
    })
    axios
      .post(
        this.state.superState.apiUrl+"editNotice.php",
        formData,
        config
      )
      .then((response) => {
        // console.log(response)
        console.log(response);
        this.setState({
          editing:false,
          editModalOpen:false,
          editId:null,
          toEdit:null
        });

        let d = JSON.parse(response.data);
        if(d.message==="Edit Success"){
          this.setState({
            message: (
              <SnackBar
                key={Math.random()}
                msg="Notice Edit: "
                status={"Success"}
              />
            ),
          });
          this.getFiveNotices()
        }
        else{
          this.setState({
            message: (
              <SnackBar
                key={Math.random()}
                msg="Notice Edit: "
                status={"Failed"}
              />
            ),
          });
        }
        
      })
      .catch((error) => {
        this.setState({
          editing:false
        });
        console.log(error);
      });
  }

  handleNoticeUploadSubmit(e) {
    console.log(e);
    e.preventDefault();
    this.setState({
      uploading: true,
      ButtonDisabled: true,
    });

    let frm = $(".noticeUploadForm");
    let formData = new FormData(frm[0]);
    formData.append("key", this.state.superState.publicKey);
    formData.append("noticeDate", $("#noticeDate").val());
    console.log(formData);
    const config = {
      withCredentials: true,
      processData: false,
      contentType: false,
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.props.container.accessToken}`,
      },
    };

    axios
      .post(
        this.state.superState.apiUrl+"uploadNotice.php",
        formData,
        config
      )
      .then((response) => {
        // console.log(response)
        console.log(response.data.message);
        this.setState({
          uploading: false,
          ButtonDisabled: false,
        });
        if (response.data.message === "Notice Uploaded") {
          this.setState({
            message: (
              <SnackBar
                key={Math.random()}
                msg="Notice Upload: "
                status={"success"}
              />
            ),
          });
          this.clearForm();
          this.getFiveNotices();
        } else {
          this.setState({
            message: (
              <SnackBar
                key={Math.random()}
                msg="Notice Upload: "
                status={response.data.message}
              />
            ),
          });
        }
      })
      .catch((error) => {
        this.setState({
          uploading: false,
          ButtonDisabled: false,
        });
        console.log(error);
      });
  }

  deleteHandler(id) {
    console.log("DELETE:" + id);
    this.setState({
      deleteId: id,
      modalOpen: true,
    });
  }

  handleModalClose() {
    this.setState({
      modalOpen: false,
      editModalOpen:false
    });
  }

  handleYesDelete() {
    console.log("Deleting");
    this.setState({
      deleting: true,
    });

    axios
      .post(
        this.state.superState.apiUrl+"deleteNotice.php",
        { key: this.state.superState.publicKey, id: this.state.deleteId },
        this.state.config
      )
      .then((response) => {
        console.log(response.data);
        let data = JSON.parse(response.data);
        if (data.message === "Notice Deleted") {
          this.setState({
            deleting: false,
            modalOpen: false,
            message: (
              <SnackBar
                key={Math.random()}
                msg={"Notice delete:"}
                status={"Success"}
              />
            ),
          });
          this.getFiveNotices();
        } else {
          this.setState({
            deleting: false,
            modalOpen: false,
            message: (
              <SnackBar
                key={Math.random()}
                msg={"Notice delete:"}
                status={"Failed, Retry"}
              />
            ),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <Paper style={{ marginTop: "30px", padding: "10px" }}>
          <Typography variant="h5">
            <u>Upload Notice:</u>
          </Typography>
          <br />
          <form
            encType="multipart/form-data"
            onSubmit={(e) => this.handleNoticeUploadSubmit(e)}
            className="noticeUploadForm"
          >
            <FormLabel>PDF File:</FormLabel>
            <input
              id="noticePDF"
              required
              style={{ marginLeft: "2px" }}
              accept="application/pdf"
              type="file"
              name="noticePDF"
            />
            <br />
            <br />
            <FormLabel>Notice Title:</FormLabel>

            <Input
              id="noticeName"
              required
              style={{ marginLeft: "10px" }}
              name="noticeName"
              type="text"
            />
            <br />
            <br />
            <FormLabel>Notice Date:</FormLabel>

            <Input
              id="noticeDate"
              name="noticeDate"
              required
              disabled
              style={{ marginLeft: "10px" }}
              accept=".pdf"
              type="text"
              value={moment().format("Do MMM YYYY")}
            />
            <br />
            <br />
            <Button
              disabled={this.state.ButtonDisabled}
              style={{ background: "#000" }}
              name="submit"
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
            {this.state.uploading ? (
              <span style={{ paddingLeft: "20px" }}>
                Uploading...
                <CircularProgress style={{ color: "#000" }} />
              </span>
            ) : (
              <span></span>
            )}
            <br />
            <span>{this.state.message}</span>
            <br />
          </form>
        </Paper>

        <Paper className={NoticeCss.NoticePanel}>
          <Typography
            style={{ paddingLeft: "8px", paddingTop: "8px" }}
            variant="h6"
          >
            Last Five Notices:
          </Typography>
          <Table className=".noticesTable" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Title</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.tBody}
              {/* <NoticeTile id={1024} name="Fee Notice" date={"06th Aug 2020"} delete={this.deleteHandler} /> */}
            </TableBody>
          </Table>
        </Paper>
        <Dialog
          // style={{position: 'absolute',top: 50,left: 50, background: '#fff',height:'200px',width:'300px',padding:'20px'}}
          open={this.state.modalOpen}
          // onClose={this.handleModalClose}
          // aria-labelledby="simple-modal-title"
          // aria-describedby="simple-modal-description"
        >
          <DialogTitle>Delete Notice</DialogTitle>
          <DialogContent>
            <p>
              Do you really want to delete notice with id :{this.state.deleteId}
            </p>
            <Button color="secondary" onClick={this.handleYesDelete}>
              Yes
            </Button>
            <Button onClick={this.handleModalClose}>No</Button>
            <span style={{ marginLeft: "5px" }}>
              {this.state.deleting ? "Please wait..." : ""}
            </span>
          </DialogContent>
        </Dialog>
        <Dialog
          // style={{position: 'absolute',top: 50,left: 50, background: '#fff',height:'200px',width:'300px',padding:'20px'}}
          open={this.state.editModalOpen}
          // onClose={this.handleModalClose}
          // aria-labelledby="simple-modal-title"
          // aria-describedby="simple-modal-description"
        >
          <DialogTitle>Edit Notice</DialogTitle>
          <DialogContent>
            <p>You can only edit it's name :</p>
            {this.state.toEdit?
            <div>
              <span>ID: {this.state.toEdit.id}</span>
              <br/>
              <br/>
              <span>Name: 
                <Input
                style={{marginLeft:'5px'}}
                  id="noticeNameEdit"
                  required
                  name="noticeNameEdit"
                  type="text"
                  defaultValue={this.state.toEdit.topic}
                />
              </span>
              <br/>
              <br/>
              <span>Date: {this.state.toEdit.date}</span>
            </div>:""}
            <br/>
            <Button color="secondary" onClick={this.handleYesEdit}>
              Save
            </Button>
            <Button onClick={this.handleModalClose}>Cancel</Button>
            <span style={{ marginLeft: "5px" }}>
              {this.state.editing ? "Please wait..." : ""}
            </span>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default NoticePanel;
