import React, { useEffect } from "react";

import {
  Snackbar,
  SnackbarContent,
  IconButton,
  CheckCircleIcon,
  CloseIcon,
  ErrorIcon,
  InfoWrapper
} from "./style";

function SimpleSnackbar({ status, msg }) {
  const [open, setOpen] = React.useState(true);
  useEffect(
    () => {
      setOpen(true);
    },
    [status]
  );

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      open={open}
      autoHideDuration={3500}
      onClose={handleClose}
    >
      <SnackbarContent
        status={status}
        contentprops={{
          "aria-describedby": "message-id"
        }}
        // prettier-ignore
        message={(
          <InfoWrapper id='message-id'>
            {status === 'success' || status==="Success" ?
              <CheckCircleIcon /> :
              <ErrorIcon />
            }
            {msg+" "+status || `Notice upload status: ${status}`}
          </InfoWrapper>
        )}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
}
export default SimpleSnackbar;
