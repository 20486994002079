import React, { useEffect,useState } from 'react'
import Animation5 from './Animation/Animation5/Animation'
import axios from "axios"
import { TextFields } from '@material-ui/icons'
import Question from './Question/Question'
import { Button } from '@material-ui/core'
import ConfettiGenerator from "confetti-js";
import { useSetState } from 'react-use'
import TextField from "@material-ui/core/TextField";
function App() {
  const [ans,setAns] = useState(null);
  const [load,setLoad] = useState(false);
  useEffect(()=>{
    
    // 
  
  })
  // if(!load){
  //   setInterval(()=>{
  //     axios
  //       .get(
  //         "https://cdn-api.co-vin.in/api/v2/appointment/sessions/public/findByPin?pincode=828306&date=19-07-2021"
  //       )
  //       .then((res) => {
  //         if(res.data.length!=0){
  //           // setLoad(true);

  //         }
  //         console.log("Hello");
  //       });
  //   },5000)

  // }



  // useEffect(()=>{
  //   const canvas = document.getElementById("my-canvas")
  //   const confettiSettings = { target: canvas };
  //   const confetti = new ConfettiGenerator(confettiSettings);
  //   confetti.render({
  //     max: 100,
  //     rotate: true,
  //     clock:200
  //   });
  //   return () => confetti.clear();
  // })
  // var curTime = new Date();
  // var todayDate = new Date().getDate();
  // var datee = "jul" + todayDate + ",2021 20:30:00";
  // var nowTime = new Date(datee).getTime();
  // var d = nowTime-curTime.getTime();
  // console.log(d);

  const handleSubmit = ()=>{
    // console.log("press");
    var val1 = document.getElementById("val1").value.toLowerCase();
    var val2 = document.getElementById("val2").value.toLowerCase();
    console.log(val1,val2);
    if(val1.includes(val2) || val2.includes(val1)){
      setAns("true");
    }
    else{
      setAns("false");
    }
  }
  return (
    <div id="Hello">
      <TextField
        id="val1"
        label="Answer"
        variant="outlined"
        required
        placeholder="4"
      />
      <TextField
        id="val2"
        label="Answer"
        variant="outlined"
        required
        placeholder="4"
      />
      <Button
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        // type="submit"
      >
        Check
      </Button>
      <p>{ans}</p>

      {/* <Animation5 />
      <Button
        variant="contained"
        color="primary"
        // disabled={dis.quit}
        onClick={handleSubmit}
      >
        Click
      </Button> */}
      {/* <canvas className="my-canvas" id="my-canvas"></canvas> */}
    </div>
  );
}

export default App

