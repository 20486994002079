import React from "react";
import { Paper, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import CSS from "./NewsfeedTile.module.css";

const NewsFeedTite = (props) => {
  return (
    <Paper className={CSS.NewsFeedTite}>
      <div>
        <div>
          <div>
            Post ID: <span className={CSS.colorBlue}>{props.id}</span>
          </div>
          <div>
            Posted By: <span className={CSS.colorBlue}>{props.regid}</span>
          </div>
          <div>
            Posted On: <span className={CSS.colorBlue}>{props.date}</span>
          </div>
        </div>
        <br />
        <div>
          {props.topicImage == null ? (
            ""
          ) : (
            <img
              className={CSS.tileImg}
              alt="newsfeedPOst"
              src={"https://recstudentportal.com/" + props.topicImage}
            />
          )}
        </div>
        <div style={{ height: "auto" }}>
          <p>{props.question}</p>
        </div>
        <br />
      </div>
      <div className={CSS.stickyButton}>
        <Divider />
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ marginBottom: "4px", marginTop: "4px" }}
            onClick={props.delete}
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default NewsFeedTite;
