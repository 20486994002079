import React from "react";
import CSS from "./Member.module.css";
import icFb from "../../assets/Images/ic_fb.png";
import icGit from "../../assets/Images/ic_git.png";
import icLd from "../../assets/Images/ic_ld.png";

const Member = (props) => {
  return (
    <div className={CSS.Member}>
      <img className={CSS.img}
        alt=""
        src={"https://recstudentportal.com/udaanmembers/" + props.profile}
      />
      <h5>{props.name}</h5>
      <h6>{props.designation}</h6>
      <h6>{props.sub_designation}</h6>
      <div className={CSS.icoImg}>
        <span>
          <a href={"mailto:" + props.email}>
            <i className="material-icons">email</i>
          </a>
        </span>
        <span>
          <a href={"tel:+91" + props.mobile}>
            <i className="material-icons">phone</i>
          </a>
        </span>
        <span>
          <a href={"https://fb.com/" + props.fb_link}>
            <img style={{ width: "24px", height: "24px" }} alt="" src={icFb} />
          </a>
        </span>
        <span>
          <a href={"https://github.com/" + props.github}>
            <img style={{ width: "24px", height: "24px" }} alt="" src={icGit} />
          </a>
        </span>
        <span>
          <a href={"https://www.linkedin.com/" + props.ld_link}>
            <img style={{ width: "24px", height: "24px" }} alt="" src={icLd} />
          </a>
        </span>
      </div>
    </div>
  );
};

export default Member;
