import React from "react"
import css from "./NaviItem.module.css"

const navigationItem = (props) => (
    <li className={css.NavigationItem}>
        <a href={props.href} className={props.active ? css.active : null}>
            {props.children}
        </a>
    </li>
)

export default navigationItem