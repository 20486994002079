import React,{Component} from 'react'
import axios from 'axios';

import Member from './Member'
import CSS from './TeamMembers.module.css'

class TeamMember extends Component{

    constructor(){
        super()
        this.state={
            members: [
            ]
        }
        this.updateStateData=this.updateStateData.bind(this)
    }


    componentDidMount(){
        //let newSate=null;
        axios.get("https://recstudentportal.com/api/v1/getTeamMemberDetails.php?key=cmVhY3RBcHBQb3J0YWwyNjc=")
        .then((data)=>{
//             console.log(data)
            this.updateStateData(data.data)
        })

    }

    updateStateData(data){
        let newSate=null;
        newSate=data;
        this.setState({
            members: newSate
        })
        //console.log(this.state.members)
    }

    render(){
       
//        console.log(this.state.members)
        return(
            <div className={CSS.TeamMember+" row"}>
                {   
                    this.state.members.map((member)=>{
                       // console.log(member)
                        return(
                            <Member 
                                key={member.regid} 
                                name={member.name} 
                                designation={member.designation} 
                                profile={member.profile}
                                sub_designation={member.sub_designation}
                                session={member.session}
                                stream={member.stream}
                                mobile={member.mobile}
                                fb_link={member.fb_link}
                                ld_link={member.ld_link}
                                github={member.github}
                                email={member.email}
                                />
                        )
                    })
                }
            </div>
        )
    }
}

export default TeamMember;
