import React from "react";
import { TableRow, TableCell, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const NoticeTile = (props) => {
  return (
    <TableRow key={props.id}>
      <TableCell align="left">{props.id}</TableCell>
      <TableCell align="left">{props.name}</TableCell>
      <TableCell align="right">{props.date}</TableCell>
      <TableCell align="center">
        <Button
          style={{marginRight:'10px',marginBottom:'10px'}}
          onClick={props.edit}
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
        > 
          Edit
        </Button>
        {/* <span style={{paddingLeft:'10px'}}></span> */}
        <Button
        style={{marginBottom:'10px'}}
          onClick={props.delete}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default NoticeTile;
