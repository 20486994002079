import React from "react";
import { TableRow, TableCell, Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const ResultTile = (props) => {
  return (
    <TableRow key={props.id}>
      <TableCell align="left">{props.id}</TableCell>
      <TableCell align="left">{props.branch}</TableCell>
      <TableCell align="right">{props.sem}</TableCell>
      <TableCell align="right">{props.date}</TableCell>
      <TableCell align="center">
        <Button
        style={{marginBottom:'10px'}}
          onClick={props.delete}
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ResultTile;