import React, { useState, useEffect } from "react";
import axios from "axios";
import Content from "./Content/Content";
import Styles from "./Winner.module.css";
import Loading from "../Loading/Loading";
import Animation from "./Animation/Animation";
export default function Winner() {
  const [Data, setData] = useState([]);
  const [Done, setDone] = useState(undefined);
  useEffect(() => {
    axios
      .get(
        "https://recstudentportal.com/api/v1/QuizathonRank.php?key=cmVhY3RBcHBQb3J0YWwyNjc="
      )
      .then((res) => {
        // console.log(res)
        if (res.data.status === 400) {
          alert("");
        } else {
          setData(res.data);
          setDone(true);
        }
      });
  }, []);
  var getData = (value) => {
    return Data.filter((da) => {
      //   console.log(da.contest);
      return da.contest.includes(value);
    });
  };
  return (
    <div>
      {!Done ? (
        <Loading />
      ) : (
        <div className={Styles.bg}>
          <h1 className={Styles.win}>Event Winner</h1>
          <Animation />
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Content
            arr={getData("quizathon4")}
            title="Quiz-a-thon (2021)"
            level="2"
            total="200"
          />
          <Content
            arr={getData("quizahive1")}
            title="Quiz-a-Hive (2021)"
            level="2"
            total="90"
          />
          <Content
            arr={getData("quizathon3")}
            title="Quiz-a-thon (2020)"
            level="2"
            total="150"
          />
          <Content
            arr={getData("2ndcodewar1")}
            title="Code-a-thon (2019)"
            level="3"
            total="7"
          />
          <Content
            arr={getData("1stcodewar1")}
            title="Code-a-thon (2019) 1st year"
            level="3"
            total="5"
          />
          <Content
            arr={getData("gusto2")}
            title="Gusto (2017)"
            level="2"
            total="500"
          />
        </div>
      )}
    </div>
  );
}
