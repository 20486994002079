import React, { useState } from "react";
import { useLocation } from "wouter";
import Section1 from "./Section/Section1/Section1";
import Section2 from "./Section/Section2/Section2";
import Section3 from "./Section/Section3/Section3";
import Section4 from "./Section/Section4/Section4";

function Landing(props) {
  // console.log(props);

  const [data, setData] = useState(props.check.state);
  const [load, setLoad] = useState(false)
  const [location, setLocation] = useLocation();
  // setLocation("/quiz/rank");
  // if (load && data.isLoggedIn){
  //   if(data.year!="1"){
  //     setLoad(false)
  //   }
  //   else{
  //     setLoad(false);
  //     setLocation("/error")
  //   }
  // }
  // setTimeout(()=>{

  //   if(props.check.state.isLoggedIn==false){
  //     setLocation("/login")
  //   }
  // },2000)
  return (
    <>
      {load ? "" :
        (<><Section1 state={props.check.state}></Section1>
          <Section3></Section3>
          <Section4></Section4>
          <Section2></Section2></>)
      }
    </>
  );
}

export default Landing;
