import React from "react";
import clas from "./NaviItems.module.css"
import NavigationItem from "./NavigationItem/NavigationItem"
import { Subscribe } from 'unstated'
import recspContainer from '../../../Store/recspContainer'

import {
  Link
} from "wouter";

const navigationItems = (props) => {


  return (
    <ul className={clas.NavigationItems}>

      <Link href="/" ><NavigationItem >Home</NavigationItem></Link>
      <Link href="/notice" ><NavigationItem>Notice</NavigationItem></Link>
      <Link href="/ex-members" ><NavigationItem>Ex-Members</NavigationItem></Link>
      <Link href="/winner" ><NavigationItem>Winner</NavigationItem></Link>
      <Subscribe to={[recspContainer]}>{
        recspContainer => recspContainer.state.isLoggedIn ? <Link href="/profile" ><NavigationItem link="/">Profile</NavigationItem></Link> : <Link href="/login" ><NavigationItem link="/">Login</NavigationItem></Link>
      }</Subscribe>
      <Subscribe to={[recspContainer]}>{
        recspContainer => recspContainer.state.isLoggedIn ? <Link href="/quiz" ><NavigationItem link="/">Quiz</NavigationItem></Link> : <div></div>
      }</Subscribe>
      <Subscribe to={[recspContainer]}>{
        recspContainer => recspContainer.checkAdmin() ? <Link href="/adminpanel" ><NavigationItem link="/">Admin</NavigationItem></Link> : <div></div>
      }</Subscribe>
    </ul>
  )
}

export default (navigationItems)
