import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  FormLabel,
  Input,
  Button,
  FormControl,
  Select,
  InputLabel,
  // TableContainer,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackBar from "../../../components/SnackBar/SnackBar";
import axios from "axios";
import $ from "jquery";
import CSS from "./GalleryPanel.module.css";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const GalleryPanel = (props) => {
  const [albumsOptions, setAlbumsOptions] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [uploadDisable, disableUploadBtn] = useState(false);
  const [creating, setCreating] = useState(false);
  const [createDisable, disableCreateBtn] = useState(false);
  const [deletingPhoto, setDelPhoto] = useState(false);
  const [delPhotoDisable, setDelDisablePhoto] = useState(false);
  const [config] = useState({
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${props.container.accessToken}`,
    },
  });

  const getAlbums = () => {
    axios
      .post(
        props.container.apiUrl+"getAlbums.php",
        { key: props.container.publicKey },
        config
      )
      .then((response) => {
        // console.log(response);
        populateTable(response.data);
      })
      .catch((error) => {});
  };

  const populateTable = (data) => {
    let array = [<option key={0}></option>];
    for (let i = 0; i < data.length; i++) {
      let d = data[i];
      array.push(
        <option key={d.id} value={d.category}>
          {d.category}
        </option>
      );
    }
    setAlbumsOptions(array);
  };

  const clearForm = (e) => {
    $("#album").prop("selectedIndex", -1);
    $("#galleryImage").replaceWith($("#galleryImage").val("").clone(true));
    $("#albumImage").replaceWith($("#albumImage").val("").clone(true));
    $("#newAlbum").val("");
  };

  const handleUploadPhoto = (e) => {
    e.preventDefault();
    disableUploadBtn(true);
    setUploading(true);
    let frm = $("#imageUploadForm");
    let formData = new FormData(frm[0]);
    formData.append("key", props.container.publicKey);
    const config = {
      withCredentials: true,
      processData: false,
      contentType: false,
      headers: {
        Authorization: `Bearer ${props.container.accessToken}`,
      },
    };

    axios
      .post(
        props.container.apiUrl+"uploadGalleryImage.php",
        formData,
        config
      )
      .then((response) => {
        // console.log(response)
        // console.log(response.data.message);
        setUploading(false);
        disableUploadBtn(false);
        if (response.data.message === "Image Uploaded") {
          setMessage(
            <SnackBar
              key={Math.random()}
              msg="Image Upload: "
              status={"Success"}
            />
          );
          clearForm(e);
          getAlbums();
        } else if (response.data.message === null) {
          setMessage(
            <SnackBar
              key={Math.random()}
              msg="Image Upload: "
              status={"Something went wrong, call Team"}
            />
          );
        } else {
          setMessage(
            <SnackBar
              key={Math.random()}
              msg="Image Upload: "
              status={response.data.message}
            />
          );
        }
      })
      .catch((error) => {
        setUploading(false);
        disableUploadBtn(false);
        console.log(error);
        setMessage(
          <SnackBar
            key={Math.random()}
            msg="Image Upload: "
            status={"Something went wrong, call Team"}
          />
        );
      });
  };

  const handleCreateAlbum = (e) => {
    e.preventDefault();
    disableCreateBtn(true);
    setCreating(true);
    let frm = $("#newAlbumForm");
    let formData = new FormData(frm[0]);
    const config = {
      withCredentials: true,
      processData: false,
      contentType: false,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${props.container.accessToken}`,
      },
    };
    formData.append("key", props.container.publicKey);
    axios
      .post(
        props.container.apiUrl+"createAlbum.php",
        formData,
        config
      )
      .then((response) => {
        setCreating(false);
        disableCreateBtn(false);
        if (response.data.message === "Album Created") {
          setMessage(
            <SnackBar
              key={Math.random()}
              msg="Album Create: "
              status={"Success"}
            />
          );
          clearForm(e);
          getAlbums();
        } else if (response.data.message === null) {
          setMessage(
            <SnackBar
              key={Math.random()}
              msg="Album Create: "
              status={"Something went wrong, call Team"}
            />
          );
        } else {
          setMessage(
            <SnackBar
              key={Math.random()}
              msg="Album Create: "
              status={response.data.message}
            />
          );
        }
      })
      .catch((error) => {
        setCreating(false);
        disableCreateBtn(false);
        console.log(error);
        setMessage(
          <SnackBar
            key={Math.random()}
            msg="Album Create: "
            status={"Something went wrong, call Team"}
          />
        );
      });
    getAlbums();
  };

  const handleDeletePhoto = (e) => {
    e.preventDefault();
    setDelDisablePhoto(true);
    setDelPhoto(true);
    let delId = $("#photoId").val();
    axios
      .post(
        props.container.apiUrl+"delPhoto.php",
        { key: props.container.publicKey, photoId: delId },
        config
      )
      .then((response) => {
        let data = response.data;
        if (data.message === "Photo Deleted") {
          setDelDisablePhoto(false);
          setDelPhoto(false);
          setMessage(
            <SnackBar
              key={Math.random()}
              msg={"Photo delete:"}
              status={"Success"}
            />
          );
        } else {
          setDelDisablePhoto(false);
          setDelPhoto(false);
          setMessage(
            <SnackBar
              key={Math.random()}
              msg={"Photo delete:"}
              status={"Faled " + data.message}
            />
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
    $("#photoId").val("");
  };

  const handleDelAlbum = (e) => {
    e.preventDefault();
    setDelDisablePhoto(true);
    setDelPhoto(true);
    let delId = $("#delAlbum").val();
    axios
      .post(
        props.container.apiUrl+"delAlbum.php",
        { key: props.container.publicKey, album: delId },
        config
      )
      .then((response) => {
        let data = response.data;
        if (data.message === "Album Deleted") {
          setDelDisablePhoto(false);
          setDelPhoto(false);
          setMessage(
            <SnackBar
              key={Math.random()}
              msg={"Album delete:"}
              status={"Success"}
            />
          );
        } else {
          setDelDisablePhoto(false);
          setDelPhoto(false);
          setMessage(
            <SnackBar
              key={Math.random()}
              msg={"Album delete:"}
              status={"Faled " + data.message}
            />
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
      $("#delAlbum").prop("selectedIndex", -1);
      getAlbums();
  };

  useEffect(() => {
    const getAlbums1 = () => {
      getAlbums();
    };
    getAlbums1();
  }, []);

  return (
    <div>
      <Paper className={CSS.uploadPhoto}>
        <Typography variant="h5">
          <u>Upload Photo:</u>
        </Typography>
        <form
          id="imageUploadForm"
          onSubmit={(e) => handleUploadPhoto(e)}
          encType="multipart/form-data"
        >
          <br />
          <div>
            <FormControl style={{ maxWidth: "250px" }}>
              <InputLabel htmlFor="album">Select Album</InputLabel>
              <Select
                required
                native
                inputProps={{
                  name: "album",
                  id: "album",
                }}
              >
                {albumsOptions}
              </Select>
            </FormControl>
          </div>

          <br />
          <div>
            <FormLabel>Image File:</FormLabel>
            <input
              id="galleryImage"
              required
              style={{ marginLeft: "2px" }}
              accept="image/*"
              type="file"
              name="galleryImage"
            />
          </div>
          <br />
          <Button
            disabled={uploadDisable}
            style={{ background: "#000" }}
            name="submit"
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<CloudUploadIcon />}
          >
            Upload
          </Button>
          {uploading ? (
            <span style={{ paddingLeft: "20px" }}>
              Uploading...
              <CircularProgress style={{ color: "#000" }} />
            </span>
          ) : (
            <span></span>
          )}
          <br />
          <span>{message}</span>
        </form>
      </Paper>

      <Paper className={CSS.uploadPhoto}>
        <Typography variant="h5">
          <u>Delete Photo:</u>
        </Typography>
        <form id="delPhotoForm" onSubmit={(e) => handleDeletePhoto(e)}>
          <br />
          <div>
            <FormControl>
              <InputLabel htmlFor="newAlbum">Photo ID</InputLabel>
              <Input
                id="photoId"
                required
                style={{ marginLeft: "2px" }}
                type="number"
                name="photoId"
              />
            </FormControl>
          </div>
          <br />
          <span style={{ color: "red" }}>
            Open photo on galley in app to see photo Id.
          </span>
          <br />
          <br />
          <Button
            disabled={delPhotoDisable}
            style={{ background: "#000" }}
            name="submit"
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
          {deletingPhoto ? (
            <span style={{ paddingLeft: "20px" }}>
              Deleting...
              <CircularProgress style={{ color: "#000" }} />
            </span>
          ) : (
            <span></span>
          )}
          <br />
          <span>{message}</span>
        </form>
      </Paper>

      <Paper className={CSS.uploadPhoto}>
        <Typography variant="h5">
          <u>Create New Album:</u>
        </Typography>
        <form
          id="newAlbumForm"
          onSubmit={(e) => handleCreateAlbum(e)}
          encType="multipart/form-data"
        >
          <br />

          <div>
            <FormControl>
              <InputLabel htmlFor="newAlbum">New Album Name:</InputLabel>
              <Input
                id="newAlbum"
                required
                style={{ marginLeft: "2px" }}
                type="text"
                name="newAlbum"
              />
            </FormControl>
          </div>
          <br />
          <div>
            <FormLabel>Image File:</FormLabel>
            <input
              id="AlbumImage"
              required
              style={{ marginLeft: "2px" }}
              accept="image/*"
              type="file"
              name="albumImage"
            />
          </div>
          <br />
          <Button
            disabled={createDisable}
            style={{ background: "#000" }}
            name="submit"
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
          >
            Create
          </Button>
          {creating ? (
            <span style={{ paddingLeft: "20px" }}>
              Creating...
              <CircularProgress style={{ color: "#000" }} />
            </span>
          ) : (
            <span></span>
          )}
          <br />
          <span>{message}</span>
        </form>
      </Paper>

      <Paper className={CSS.uploadPhoto}>
        <Typography variant="h5">
          <u>Delete Album</u>
        </Typography>
        <form id="delAlbumForm" onSubmit={(e) => handleDelAlbum(e)}>
          <br />
          <div>
            <FormControl style={{ maxWidth: "250px" }}>
              <InputLabel htmlFor="delAlbum">Select Album</InputLabel>
              <Select
                required
                native
                inputProps={{
                  name: "delAlbum",
                  id: "delAlbum",
                }}
              >
                {albumsOptions}
              </Select>
            </FormControl>
          </div>

          <br />

          <Button
            disabled={delPhotoDisable}
            style={{ background: "#000" }}
            name="submit"
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
          {deletingPhoto ? (
            <span style={{ paddingLeft: "20px" }}>
              Deleting...
              <CircularProgress style={{ color: "#000" }} />
            </span>
          ) : (
            <span></span>
          )}
          <br />
          <span>{message}</span>
        </form>
      </Paper>
    </div>
  );
};

export default GalleryPanel;
