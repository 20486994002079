import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  FormLabel,
  Input,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  InputLabel,
  // TableContainer,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackBar from "../../../components/SnackBar/SnackBar";
import axios from "axios";
import $ from "jquery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CSS from "./NotesPanel.module.css";
import NotesTile from './NotesTile'

const NotesPanel = (props) => {
  const [uploadDisable, disableUploadBtn] = useState(false);
  const [tBody,setTBody]=useState(null);
  const [uploading,setUploading]=useState(false);
  const [message,setMessage]=useState('');
  const [delModal,delModalOpen]=useState(false);
  const [delResId,setDelResId]=useState(0);
  const [deleting,setDeleting]=useState(false);

  const clearForm=(e)=> {
    $('#branch').prop('selectedIndex',-1);
    $('#semester').prop('selectedIndex',-1);
    $('#type').prop('selectedIndex',-1);
    $('#resPdf').replaceWith($('#resPdf').val('').clone(true));
      
  }

  

  const handleUploadNotes = (e) => {
    e.preventDefault();
    // console.log("UR");
    disableUploadBtn(true)
    setUploading(true);
    let frm = $("#notesUploadFrom");
    let formData = new FormData(frm[0]);
    formData.append("key", props.container.publicKey);
    formData.append("year",moment().format("Do MMM YYYY"))
    // console.log(...formData);
    const config = {
      withCredentials: true,
      processData: false,
      contentType: false,
      headers: {
        Authorization: `Bearer ${props.container.accessToken}`,
      },
    };

    axios
      .post(
        props.container.apiUrl+"uploadNotes.php",
        formData,
        config
      )
      .then((response) => {
        console.log(response)
        // console.log(response.data.message);
        setUploading(false);
        disableUploadBtn(false)
        if (response.data.message === "Notes Uploaded") {
          setMessage(<SnackBar
            key={Math.random()}
            msg="Notes Upload: "
            status={"Success"}
          />)
          clearForm(e)
          getTenResults();
        } else if(response.data.message===null) {
          setMessage(<SnackBar
            key={Math.random()}
            msg="Notes Upload: "
            status={'Something went wrong, call Team'}
          />)
        }
        else{
          setMessage(<SnackBar
            key={Math.random()}
            msg="Notes Upload: "
            status={response.data.message}
          />)
        }
      })
      .catch((error) => {
        setUploading(false);
        disableUploadBtn(false)
        console.log(error);
        setMessage(<SnackBar
          key={Math.random()}
          msg="Notes Upload: "
          status={'Something went wrong, call Team'}
        />)
      });

  };

  const handleYesDelete =()=>{
    setDeleting(true)
    axios
      .post(
        props.container.apiUrl+"deleteNotes.php",
        { key: props.container.publicKey, id: delResId },
        props.container.config
      )
      .then((response) => {
        console.log(response.data.message);
        let d = response.data;
        if (d.message === "Notes Deleted") {
            setDeleting(false)
            delModalOpen(false)
            setMessage(<SnackBar
                key={Math.random()}
                msg={"Notes delete:"}
                status={"Success"}
              />)
              getTenResults()
        } else {
          setDeleting(false)
            delModalOpen(false)
            setMessage(<SnackBar
                key={Math.random()}
                msg={"Notes delete:"}
                status={"Failed"}
              />)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const resDeleteHandler=(id)=>{
    setDelResId(id)
    delModalOpen(true)
    // setDeleting(true)
  }

  const getSem=(sem)=>{
    switch (sem){
      case '1':
        return '1st Sem';
      case '2':
        return '2nd Sem'; 
      case '3':
        return '3rd Sem';
      default:
        return sem+'th Sem'; 
    }
  }

  const populateTable=(data)=>{
    // data = JSON.parse(data);
    // console.log(data);
    let tBody = [];
    for (var i = 0; i < data.length; i++) {
      let d = data[i];
      tBody.push(<NotesTile key={d.id} id={d.id} sub={d.sub} date={d.year} branch={d.branch.toUpperCase()} sem={getSem(d.sem)} delete={()=>resDeleteHandler(d.id)}/>);
    }
    setTBody(tBody)
  }

  const getTenResults=() =>{
    axios
      .post(
        "https://recstudentportal.com/api/v1/getFiveNotes.php",
        { key: props.container.publicKey },
        props.container.config
      )
      .then((response) => {
        // console.log(response);
        populateTable(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(()=>{
    // setMain(<NotesTile id='1' date='1st Aug 2020' branch='CSE' sem='2nd Sem' delete={resDeleteHandler}/>)
    const getTenResults1=()=>{
      getTenResults()
    }

    getTenResults1()
    
  },[])

  return (
    <div style={{ marginTop: "30px" }}>
      <Paper className={CSS.uploadRes}>
        <Typography variant="h5">
          <u>Upload Notes:</u>
        </Typography>
        <form id='notesUploadFrom' onSubmit={(e)=>handleUploadNotes(e)} encType="multipart/form-data">
          <FormControl className={CSS.formControl}>
            <InputLabel htmlFor="branch">Branch</InputLabel>
            <Select
              required
              native
              inputProps={{
                name: "branch",
                id: "branch",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"cse"}>Computer Science & Engineering</option>
              <option value={"ece"}>
                Electronics & Communication Engineering
              </option>
              <option value={"ee"}>Electrical Engineering</option>
              <option value={"ce"}>Civil Engineering</option>
              <option value={"me"}>Mechanical Engineering</option>
            </Select>
          </FormControl> 
          <br/>
          <br/>
          <FormControl className={CSS.formControl}>
            <InputLabel htmlFor="semester">Semester</InputLabel>
            <Select
              required
              native
              inputProps={{
                name: "sem",
                id: "semester",
              }}
            >
              <option aria-label="None" value="" />
              <option value={1}>1st</option>
              <option value={2}>2nd</option>
              <option value={3}>3rd</option>
              <option value={4}>4th</option>
              <option value={5}>5th</option>
              <option value={6}>6th</option>
              <option value={7}>7th</option>
              <option value={8}>8th</option>
            </Select>
          </FormControl>
          <br/><br/>
          
            <FormLabel>Subject Name:</FormLabel>

            <Input
              id = "NotesName"
              required
              style={{ marginLeft: "10px" }}
              name = "sub"
              type="text"
            />
            <br></br>
            <br></br>
            <FormLabel>Date:</FormLabel>
            
            <Input
            disabled
            value={moment().format("Do MMM YYYY")}
              id = "NotesYear"
              required
              style={{ marginLeft: "10px" }}
              name="NotesYear"
              type="text"
            />


        
          <br></br>
          <br></br>
          <div style={{maginLeft:'20px'}}>
            <FormLabel style={{paddingLeft:'10px'}}>PDF File:</FormLabel>
            <input
              id="notesPDF"
              required
              style={{ marginLeft: "2px" }}
              accept="application/pdf"
              type="file"
              name="notesPDF"
            />
          </div>
          <br/>
            <Button
              disabled={uploadDisable}
              style={{ background: "#000" }}
              name="submit"
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
            >Upload</Button>
             {uploading ? (
              <span style={{ paddingLeft: "20px" }}>
                Uploading...
                <CircularProgress style={{ color: "#000" }} />
              </span>
            ) : (
              <span></span>
            )}
            <br />
            <span>{message}</span>
        </form>
      </Paper>
      <Paper className={CSS.lastFiveRes}>
        <Typography variant='h5'><u>Last Five Notes</u></Typography>
        <Table  size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Branch</TableCell>
                <TableCell align="left">Semester</TableCell>
                <TableCell align="left">Subject</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tBody===null?<NotesTile key={0} sub={''} id={0} date={''} branch={'Loading...'} sem={'0'} delete={()=>resDeleteHandler(0)}/>:tBody}</TableBody>
          </Table>
      </Paper>
      <Dialog
          open={delModal}
        >
          <DialogTitle>Delete Notice</DialogTitle>
          <DialogContent>
            <p>
              Do you really want to delete note with id :{delResId} ?
            </p>
            <Button color="secondary" onClick={()=>handleYesDelete()}>
              Yes
            </Button>
            <Button onClick={()=>delModalOpen(false)}>No</Button>
            <span style={{ marginLeft: "5px" }}>
              {deleting ? "Please wait..." : ""}
            </span>
          </DialogContent>
        </Dialog>
    </div>
  );
};

export default NotesPanel;
