import React, { useState, useEffect } from "react";
import Styles from "./Post.module.css";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { yellow } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Invalid from "../../Invalid/Invalid";
import Loading from "../../../components/Loading/Loading";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  ques: {
    "& .MuiTextField-root": {
      marginTop: "20px",
      width: "100%",
    },
  },
  option: {
    "& > *": {
      width: "15ch",
      marginTop: "20px;",
      color: yellow,
    },
  },
}));

export default function Post(props) {
  const [type,setType] = useState("");
  const [data, setData] = useState(props.check.state);
  const [ques, setQues] = useState(null);
  const [load1, setLoad1] = useState(true);
  var url =
    props.check.state.apiUrl +
    "quizQuestion.php?key=" +
    props.check.state.publicKey;
  const classes = useStyles();

  const [dis, setDis] = useState(false);
  const [sub, setSub] = useState("Submit");
  const [load, setLoad] = useState(true);
  const [count, setCount] = useState({ 0: 0, 1: 0, 2: 0 });
  const [set, setSet] = useState({ 0: false, 1: false, 2: false });
  useEffect(() => {
    var formData = {
      step: "getQuestions",
      id: "0",
    };
    if (load1) {
      axios
        .post(data.apiUrl + "getQuizQues.php?key=" + data.publicKey, formData, {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          // console.log(res.data);
          setLoad1(false);
          setQues(res.data);
        });
    }
  });

  if (load) {
    axios
      .get(
        props.check.state.apiUrl +
          "countQues.php?key=" +
          props.check.state.publicKey
      )
      .then((res) => {
        // console.log(res.data[0]);
        setLoad(false);
        setCount({
          0: 10 - res.data[0],
          1: 10 - res.data[1],
          2: 10 - res.data[2],
        });
      });
  }
  const getQuestions = () => {
    setLoad1(true);
    var formData = {
      step: "getQuestions",
      id: "0",
    };
    if (load1) {
      axios
        .post(data.apiUrl + "getQuizQues.php?key=" + data.publicKey, formData, {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          setLoad1(false);
          setQues(res.data);
        });
    }
  };
  const deleteQues = (e, id) => {
    var formData = {
      step: "deleteQuestions",
      id: id,
    };
    axios
      .post(data.apiUrl + "getQuizQues.php?key=" + data.publicKey, formData, {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setQues(res.data);
      });
  };
  const quesNumber = () => {
    axios
      .get(
        props.check.state.apiUrl +
          "countQues.php?key=" +
          props.check.state.publicKey
      )
      .then((res) => {
        console.log(res.data[0]);
        setLoad(false);
        setCount({
          0: 10 - res.data[0],
          1: 10 - res.data[1],
          2: 10 - res.data[2],
        });
      });
  };
  const handleSet = (e, num) => {
    if (num == "set1") {
      var x = set[0];
      setSet({ ...set, 0: !x });
    }
    if (num == "set2") {
      var x = set[1];
      setSet({ ...set, 1: !x });
    }
    if (num == "set3") {
      var x = set[2];
      setSet({ ...set, 2: !x });
    }
  };
  var handleSubmit = async (e) => {
    e.preventDefault();
    var result = false;
    var ques = document.getElementById("ques").value;
    
    var ans = document.getElementById("ans").value;
    // console.log(("Set-->", set));
    // if (type && type == "Word or Sentence") {
    //   ans = ans.
    // }
    if (
      ques.length &&
      type.length &&
      ans.length &&
      (set[0] || set[1] || set[2])
    ) {
      setDis(true);

      var formData = {
        ques: ques,
        // op1: op1,
        // op2: op2,
        // op3: op3,
        // op4: op4,
        ans: ans,
        type:type,
      };
      if (count[0] && set[0]) {
        formData = { ...formData, set: "1" };
        await axios
          .post(url, formData, props.check.state.config)
          .then((res) => {
            result = result || true;
            // console.log("Sucess", res);
          })
          .catch((err) => {
            // console.log("Error", err);
          });
      }
      if (count[1] && set[1]) {
        formData = { ...formData, set: "2" };
        await axios
          .post(url, formData, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            result = result || true;
            // console.log("Sucess", res);
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
      if (count[2] && set[2]) {
        formData = { ...formData, set: "3" };
        await axios
          .post(url, formData, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            result = result || true;
            // console.log("Sucess", res);
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
      if (result) {
        setDis(false);
        getQuestions();
        // var x1 = count[0],
        //   x2 = count[1],
        //   x3 = count[2];
        // if (set[0] && count[0]) x1 = count[0] - 1;
        // if (set[1] && count[1]) x2 = count[1] - 1;
        // if (set[2] && count[2]) x3 = count[2] - 1;
        // setCount({ 0: x1, 1: x2, 2: x3 });

        quesNumber();
        setSub("Done");
        setTimeout(() => {
          setSub("Submit");
        }, 3000);
        handleReset();
      } else {
        setDis(false);
        alert("Please Check");
      }
    } else {
      alert("Please Check");
    }
  };
  var handleReset = () => {
    var ques = (document.getElementById("ques").value = "");
    // var ques = (document.getElementById("ques").value = "");
    // var op1 = (document.getElementById("op1").value = "");
    // var op2 = (document.getElementById("op2").value = "");
    // var op3 = (document.getElementById("op3").value = "");
    // var op4 = (document.getElementById("op4").value = "");
    var ans = (document.getElementById("ans").value = "");
  };
 const handleChange = (e)=>{
   setType(e.target.value)
 }
  return (
    <>
      {data.regid == "189011005024" || data.regid == "189011001008" ? (
        load ? (
          <Loading></Loading>
        ) : (
          <div className="container-fluid">
            <h1>Question Enter Panel</h1>
            <form autoComplete="off" action="">
              <div className="row">
                <div className={`col-lg-8`}>
                  <div className={classes.ques}>
                    <TextField
                      id="ques"
                      placeholder="2+2"
                      label="Question"
                      multiline
                      variant="outlined"
                      required
                    />
                  </div>
                  <div className={`${Styles.option} ${classes.option}`}>
                    {/* <TextField
                      id="op1"
                      label="Option1"
                      variant="outlined"
                      required
                      placeholder="3"
                    />
                    <TextField
                      id="op2"
                      label="Option2"
                      variant="outlined"
                      required
                      placeholder="4"
                    />
                    <TextField
                      id="op3"
                      label="Option3"
                      variant="outlined"
                      required
                      placeholder="5"
                    />
                    <TextField
                      id="op4"
                      label="Option4"
                      variant="outlined"
                      required
                      placeholder="6"
                    /> */}
                    <TextField
                      id="ans"
                      label="Answer"
                      variant="outlined"
                      required
                      placeholder="4"
                    />
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={type}
                        onChange={handleChange}
                        label="Type"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"One Word"}>One Word</MenuItem>
                        <MenuItem value={"More Than One Word"}>More Than One Word</MenuItem>
                        <MenuItem value={"Decimal Number"}>Decimal</MenuItem>
                        <MenuItem value={"None Decimal Number"}>
                          None Decimal
                        </MenuItem>
                        <MenuItem value={"Fraction"}>Fraction</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={`col-lg-4`}>
                  <div className={Styles.checkbox}>
                    <FormControlLabel
                      id="set1"
                      control={
                        <Checkbox
                          disabled={count[0] ? false : true}
                          name="checkedB"
                          color="primary"
                          onChange={(e) => handleSet(e, "set1")}
                        />
                      }
                      label={`Set1(${count[0]})`}
                    />
                    <FormControlLabel
                      id="set2"
                      control={
                        <Checkbox
                          disabled={count[1] ? false : true}
                          name="checkedB"
                          color="primary"
                          onChange={(e) => handleSet(e, "set2")}
                        />
                      }
                      label={`Set2(${count[1]})`}
                    />
                    <FormControlLabel
                      id="set3"
                      control={
                        <Checkbox
                          disabled={count[2] ? false : true}
                          name="checkedB"
                          color="primary"
                          onChange={(e) => handleSet(e, "set3")}
                        />
                      }
                      label={`Set3(${count[2]})`}
                    />
                    <Button
                      disabled={dis}
                      onClick={handleSubmit}
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.btn}
                    >
                      {" "}
                      {sub}{" "}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            <div className={Styles.sec}>
              {ques?.map((data) => (
                <>
                  {!data.question ? (
                    ""
                  ) : (
                    <>
                      <div className={Styles.ques}>
                        <div>{data.qset}</div>
                        <div>{data.question}</div>
                        {/* <div>{data.op1}</div>
                        <div>{data.op2}</div>
                        <div>{data.op3}</div>
                        <div>{data.op4}</div> */}
                        <div>{data.ansOption}</div>
                        <div>{data.type}</div>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={(e) => deleteQues(e, data.id)}
                        >
                          Delete
                        </Button>
                      </div>
                      <hr></hr>
                    </>
                  )}{" "}
                </>
              ))}
            </div>
          </div>
        )
      ) : (
        <Invalid></Invalid>
      )}
    </>
  );
}

// import React, { useState,useEffect } from "react";
// import Styles from "./Post.module.css";
// import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import { yellow } from "@material-ui/core/colors";
// import Button from "@material-ui/core/Button";
// import axios from "axios";
// import Invalid from "../../Invalid/Invalid";
// import Loading from "../../../components/Loading/Loading";

// const useStyles = makeStyles((theme) => ({
//   ques: {
//     "& .MuiTextField-root": {
//       marginTop: "20px",
//       width: "100%",
//     },
//   },
//   option: {
//     "& > *": {
//       width: "15ch",
//       marginTop: "20px;",
//       color: yellow,
//     },
//   },
// }));

// export default function Post(props) {
//   const [data, setData] = useState(props.check.state);
//   const [ques, setQues] = useState(null);
//   const [load1, setLoad1] = useState(true);
//   var url =
//     props.check.state.apiUrl +
//     "quizQuestion.php?key=" +
//     props.check.state.publicKey;
//   const classes = useStyles();

//   const [dis, setDis] = useState(false);
//   const [sub, setSub] = useState("Submit");
//   const [load, setLoad] = useState(true);
//   const [count, setCount] = useState({ 0: 0, 1: 0, 2: 0 });
//   const [set, setSet] = useState({ 0: false, 1: false, 2: false });
//   useEffect(() => {
//     var formData = {
//       step: "getQuestions",
//       id:"0"
//     };
//     if (load1) {
//       axios.post(
//         data.apiUrl + "getQuizQues.php?key=" + data.publicKey,
//         formData,
//         {
//           withCredentials: true,
//           headers: { "Content-Type": "application/json" },
//         }
//       ).then((res)=>{
//         setLoad1(false)
//         setQues(res.data)
//       });
//     }
//   });

//   if (load) {
//     axios
//       .get(
//         props.check.state.apiUrl +
//           "countQues.php?key=" +
//           props.check.state.publicKey
//       )
//       .then((res) => {
//         // console.log(res.data[0]);
//         setLoad(false);
//         setCount({
//           0: 10 - res.data[0],
//           1: 10 - res.data[1],
//           2: 10 - res.data[2],
//         });
//       });
//   }
//   const getQuestions = ()=>{
//     setLoad1(true)
//     var formData = {
//       step: "getQuestions",
//       id: "0",
//     };
//     if (load1) {
//       axios
//         .post(data.apiUrl + "getQuizQues.php?key=" + data.publicKey, formData, {
//           withCredentials: true,
//           headers: { "Content-Type": "application/json" },
//         })
//         .then((res) => {
//           setLoad1(false);
//           setQues(res.data);
//         });
//     }
//   }
//   const deleteQues = (e,id) => {

//     var formData = {
//       step: "deleteQuestions",
//       id: id,
//     };
//     axios
//       .post(data.apiUrl + "getQuizQues.php?key=" + data.publicKey, formData, {
//         withCredentials: true,
//         headers: { "Content-Type": "application/json" },
//       })
//       .then((res) => {
//         setQues(res.data);
//       });
//   };
//   const quesNumber = () => {
//     axios
//       .get(
//         props.check.state.apiUrl +
//           "countQues.php?key=" +
//           props.check.state.publicKey
//       )
//       .then((res) => {
//         console.log(res.data[0]);
//         setLoad(false);
//         setCount({
//           0: 10 - res.data[0],
//           1: 10 - res.data[1],
//           2: 10 - res.data[2],
//         });
//       });
//   };
//   const handleSet = (e, num) => {
//     if (num == "set1") {
//       var x = set[0];
//       setSet({ ...set, 0: !x });
//     }
//     if (num == "set2") {
//       var x = set[1];
//       setSet({ ...set, 1: !x });
//     }
//     if (num == "set3") {
//       var x = set[2];
//       setSet({ ...set, 2: !x });
//     }
//   };
//   var handleSubmit = async (e) => {
//     e.preventDefault();
//     var result = false;
//     var ques = document.getElementById("ques").value;
//     var op1 = document.getElementById("op1").value;
//     var op2 = document.getElementById("op2").value;
//     var op3 = document.getElementById("op3").value;
//     var op4 = document.getElementById("op4").value;
//     var ans = document.getElementById("ans").value;
//     // console.log(("Set-->", set));
//     if (
//       ques.length &&
//       op1.length &&
//       op2.length &&
//       op3.length &&
//       op4.length &&
//       ans.length &&
//       (set[0] || set[1] || set[2])
//     ) {
//       setDis(true);

//       var formData = {
//         ques: ques,
//         op1: op1,
//         op2: op2,
//         op3: op3,
//         op4: op4,
//         ans: ans,
//       };
//       if (count[0] && set[0]) {
//         formData = { ...formData, set: "1" };
//         await axios
//           .post(url, formData, {
//             withCredentials: true,
//             headers: { "Content-Type": "application/json" },
//           })
//           .then((res) => {
//             result = result || true;
//             // console.log("Sucess", res);
//           })
//           .catch((err) => {
//             // console.log("Error", err);
//           });
//       }
//       if (count[1] && set[1]) {
//         formData = { ...formData, set: "2" };
//         await axios
//           .post(url, formData, {
//             withCredentials: true,
//             headers: { "Content-Type": "application/json" },
//           })
//           .then((res) => {
//             result = result || true;
//             // console.log("Sucess", res);
//           })
//           .catch((err) => {
//             console.log("Error", err);
//           });
//       }
//       if (count[2] && set[2]) {
//         formData = { ...formData, set: "3" };
//         await axios
//           .post(url, formData, {
//             withCredentials: true,
//             headers: { "Content-Type": "application/json" },
//           })
//           .then((res) => {
//             result = result || true;
//             // console.log("Sucess", res);
//           })
//           .catch((err) => {
//             console.log("Error", err);
//           });
//       }
//       if (result) {
//         setDis(false);
//         getQuestions();
//         // var x1 = count[0],
//         //   x2 = count[1],
//         //   x3 = count[2];
//         // if (set[0] && count[0]) x1 = count[0] - 1;
//         // if (set[1] && count[1]) x2 = count[1] - 1;
//         // if (set[2] && count[2]) x3 = count[2] - 1;
//         // setCount({ 0: x1, 1: x2, 2: x3 });

//         quesNumber();
//         setSub("Done");
//         setTimeout(() => {
//           setSub("Submit");
//         }, 3000);
//         handleReset();
//       } else {
//         setDis(false);
//         alert("Please Check");
//       }
//     } else {
//       alert("Please Check");
//     }
//   };
//   var handleReset = () => {
//     var ques = (document.getElementById("ques").value = "");
//     var ques = (document.getElementById("ques").value = "");
//     var op1 = (document.getElementById("op1").value = "");
//     var op2 = (document.getElementById("op2").value = "");
//     var op3 = (document.getElementById("op3").value = "");
//     var op4 = (document.getElementById("op4").value = "");
//     var ans = (document.getElementById("ans").value = "");
//   };

//   return (
//     <>
//       {data.regid == "189011005024" || data.regid=="189011001008" ? (
//         load ? (
//           <Loading></Loading>
//         ) : (
//           <div className="container-fluid">
//             <h1>Question Enter Panel</h1>
//             <form autoComplete="off" action="">
//               <div className="row">
//                 <div className={`col-lg-8`}>
//                   <div className={classes.ques}>
//                     <TextField
//                       id="ques"
//                       placeholder="2+2"
//                       label="Question"
//                       multiline
//                       variant="outlined"
//                       required
//                     />
//                   </div>
//                   <div className={`${Styles.option} ${classes.option}`}>
//                     <TextField
//                       id="op1"
//                       label="Option1"
//                       variant="outlined"
//                       required
//                       placeholder="3"
//                     />
//                     <TextField
//                       id="op2"
//                       label="Option2"
//                       variant="outlined"
//                       required
//                       placeholder="4"
//                     />
//                     <TextField
//                       id="op3"
//                       label="Option3"
//                       variant="outlined"
//                       required
//                       placeholder="5"
//                     />
//                     <TextField
//                       id="op4"
//                       label="Option4"
//                       variant="outlined"
//                       required
//                       placeholder="6"
//                     />
//                     <TextField
//                       id="ans"
//                       label="Answer"
//                       variant="outlined"
//                       required
//                       placeholder="4"
//                     />
//                   </div>
//                 </div>
//                 <div className={`col-lg-4`}>
//                   <div className={Styles.checkbox}>
//                     <FormControlLabel
//                       id="set1"
//                       control={
//                         <Checkbox
//                           disabled={count[0] ? false : true}
//                           name="checkedB"
//                           color="primary"
//                           onChange={(e) => handleSet(e, "set1")}
//                         />
//                       }
//                       label={`Set1(${count[0]})`}
//                     />
//                     <FormControlLabel
//                       id="set2"
//                       control={
//                         <Checkbox
//                           disabled={count[1] ? false : true}
//                           name="checkedB"
//                           color="primary"
//                           onChange={(e) => handleSet(e, "set2")}
//                         />
//                       }
//                       label={`Set2(${count[1]})`}
//                     />
//                     <FormControlLabel
//                       id="set3"
//                       control={
//                         <Checkbox
//                           disabled={count[2] ? false : true}
//                           name="checkedB"
//                           color="primary"
//                           onChange={(e) => handleSet(e, "set3")}
//                         />
//                       }
//                       label={`Set3(${count[2]})`}
//                     />
//                     <Button
//                       disabled={dis}
//                       onClick={handleSubmit}
//                       variant="contained"
//                       color="primary"
//                       type="submit"
//                       className={classes.btn}
//                     >
//                       {" "}
//                       {sub}{" "}
//                     </Button>
//                   </div>
//                 </div>
//               </div>
//             </form>
//             <div className={Styles.sec}>
//               {ques?.map((data) => (
//                 <>
//                   {!data.question? (
//                     ""
//                   ) : (
//                     <>
//                       <div className={Styles.ques}>
//                         <div>{data.qset}</div>
//                         <div>{data.question}</div>
//                         <div>{data.op1}</div>
//                         <div>{data.op2}</div>
//                         <div>{data.op3}</div>
//                         <div>{data.op4}</div>
//                         <div>{data.ansOption}</div>
//                         <Button
//                           color="secondary"
//                           variant="contained"
//                           onClick={(e) => deleteQues(e, data.id)}
//                         >
//                           Delete
//                         </Button>
//                       </div>
//                       <hr></hr>
//                     </>
//                   )}{" "}
//                 </>
//               ))}
//             </div>
//           </div>
//         )
//       ) : (
//         <Invalid></Invalid>
//       )}
//     </>
//   );
// }
