import React from "react"
import css from "./Corousel.module.css"
const cImage=(props)=>{
    return(
        <div className={css.CImageBlock} >
            <img className={css.CImage} alt={props.alt} src={props.source}/>
        </div>
        
    )
}

export default cImage