import React,{useRef} from "react";
import {useIntersection} from "react-use";
import gsap from "gsap";
import "./Feature.css"
import applogo from "../../assets/Images/app-logo.png"
import worklogo from "../../assets/Images/training-logo.png"
import quizlogo from "../../assets/Images/quiz-logo.png"


const Features=()=>{
    const sectionRef = useRef(null);
    const intersection = useIntersection(sectionRef,{
      root:null,
      rootMargin:"0px",
      threshold:0.5
    })
    const fadeIn1= (element)=>{
      gsap.to(element,1,{
        opacity:1,
        y:0,
        ease:"power4.out",
        stagger:{
          amount:0.3
        }
      })
    }

    const fadeOut = (element)=>{
      gsap.to(element,1,{
        opacity:0,
        y:40,
        ease:"power4.out",
        stagger:{
          amount:0.3
        }
      })
    }

    intersection&&intersection.intersectionRatio<0.4?fadeOut(".fadeIn1"):fadeIn1(".fadeIn1")
    return(
        <div ref={sectionRef}>
             <div className="Features-title fadeIn1">
                <p>Features you'll love</p>
            </div>
            <div>
                <div ref={sectionRef} className="FeatureBox-box fadeIn1">
                <img alt="" src={applogo}/>
                <p>ANDROID APP</p>
                <p id="sPara-p">The only offical app of Ramgarh Egineering College to keep you updated, on Play Store.</p>
                </div>
                <div  ref={sectionRef} className="FeatureBox-box fadeIn1">
                <img alt="" src={worklogo}/>
                <p>WORKSHOPS</p>
                <p id="sPara-p">Technical Workshops by our team to keep you updated of the industry knowledge.</p>
                </div>
                <div ref={sectionRef} className="FeatureBox-box fadeIn1">
                <img alt="" src={quizlogo}/>
                <p>EVENTS</p>
                <p id="sPara-p">Mind blowing events for college students like Gusto, Quizathon, Coding competitions.</p>
                </div>
            </div>
        </div>
       
    )
}

export default Features