import React, { Component } from "react";

import css from "./Corousel.module.css";
import CImage from "./CImage";

const base = "https://recstudentportal.com/jumbotron/";
const Image1 = base + "quizathon5.0_winner1_2.jpg";
const Image2 = base + "recr.png";
const Image3 = base + "quizathon5.0_winner3_4.jpg";
const Image4 = base + "recr.png";

class Slider extends Component {
  state = {
    intervalId: 0,
    show1: Image1,
    show2: Image2,
    show3: Image3,
    even: true,
    count: 1,
  };

  constructor() {
    super();
    this.state = {
      intervalId: 0,
      show1: Image1,
      show2: Image2,
      show3: Image3,
      even: true,
      count: 1,
    };

    // console.log("state set");
    this.silderChangerHandler = this.silderChangerHandler.bind(this);
  }

  componentDidMount() {
    this.setSliderTimer();
  }

  setSliderTimer() {
    let intervalId = setInterval(this.silderChangerHandler, 4000);
    this.setState({ intervalId: intervalId });
  }

  silderChangerHandler() {
    //console.log(this.state)
    if (this.state.even) {
      this.setState({
        show1: Image3,
        show2: Image4,
        even: false,
      });
    } else {
      this.setState({
        show1: Image1,
        show2: Image2,
        even: true,
      });
    }

    let m = this.state.count % 4;
    //console.log(m)
    if (m === 1) {
      this.setState({
        show3: Image1,
      });
    } else if (m === 2) {
      this.setState({
        show3: Image2,
      });
    } else if (m === 3) {
      this.setState({
        show3: Image3,
      });
    } else {
      this.setState({
        show3: Image4,
      });
    }
    this.setState({
      count: this.state.count+1,
    });
  }

  render() {
    return (
      <div className={["App", css.theDiv, css.fadeInClass].join(" ")}>
        <div className={css.largeSlider}>
          <CImage alt="" source={this.state.show1} />
          <CImage ali="" source={this.state.show2} />
        </div>
        <div className={css.smallSlider}>
          <CImage alt="" source={this.state.show3} />
        </div>
      </div>
    );
  }
}

export default Slider;
