import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useIntersection } from "react-use";
import gsap from "gsap";
import Styles from "./Card.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Button from "@material-ui/core/Button";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {Link} from "wouter";
export default function Card(props) {
  const [persons, setPersons] = useState([]);
  const [load, setLoad] = useState(true);
  useEffect(() => {
    Aos.init({
      offset: 100,
      duration: 1000,
      delay: 500,
    });
  });

  if (props.value!="-1" && load) {
    if (props.value === "1") {
      setPersons("Computer Science");
    }
    if (props.value === "2") {
      setPersons("Electronics and Communication");
    }
    if (props.value === "3") {
      setPersons("Civil Engineering");
    }
    if (props.value === "4") {
      setPersons("Mechnical Engineering");
    }
    if (props.value === "5") {
      setPersons("Electrical Engineering");
    }
    setLoad(false);
  }
  return (
    <div>
      <div className={Styles.card} data-aos="fade-up">
        <div>
          <img
            className={Styles.img}
            src={"https://recstudentportal.com/" + props.image}
            alt=""
          />
        </div>
        {props.circle && (
          <div className={Styles.circle}>
            <Link to={`resp/${props.regid}`}>
              <OpenInNewIcon />
            </Link>{" "}
          </div>
        )}
        <div
          className={Styles.content}
          style={{
            background: "" + props.backcolor,
          }}
        >
          <h2>
            <span style={{ color: "" + props.color }}>{props.name}</span>
            <br />
            <span className={Styles.pos}>{"Rank- " + props.rank}</span>
          </h2>
          <h2 style={{ fontSize: "16px", color: "" + props.color }}>
            {"Points - " + props.points}
            <br />
            <span
              style={{
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {persons}
            </span>
          </h2>
          {/* {props.regid} */}
          {/* <Button color="primary">Primary</Button> */}
        </div>
      </div>
    </div>
  );
}
