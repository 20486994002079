import { useEffect } from "react";
import React, { useState } from "react";
import axios from "axios";
import Styles from "./Profile.module.css";
import Layout from "../Layout/Layout";
import Loading from "../Loading/Loading";
import Card from "./Card/Card";
import { Subscribe } from "unstated";
import RECSPContainer from "../../Store/recspContainer";
import { useLocation } from "wouter";
import { Button } from "@material-ui/core";
export default function Profile(props) {
  // console.log(props.check.state);

  //   console.log(props.check.state);
  const [Data, setData] = useState({});
  const [load, setLoad] = useState(true);
  const [location, setLocation] = useLocation();

  useEffect(() => {
    mLoad();
  },[]);

  function mLoad() {
    if (load) {
       axios
        .get(
          props.check.state.apiUrl +
          "getProfile.php?key=" +
          props.check.state.publicKey,
          props.check.state.config
        )
        .then((res) => {
          if (res) {

            setData(res.data.data);
            setLoad(false);
            console.log(res.data.data);
          }
        });
    }
  }

  const checkBranch = (value) => {
    if (value === "1") {
      return "Computer Science";
    }
    if (value === "2") {
      return "Electronics and Communication";
    }
    if (value === "3") {
      return "Civil Engineering";
    }
    if (value === "4") {
      return "Mechnical Engineering";
    }
    if (value === "5") {
      return "Electrical Engineering";
    }
  };
  const checkGender = (data) => {
    if (data === "m") {
      return "Male";
    } else {
      return "Female";
    }
  };
  const checkType = (data) => {
    if (data === "admin") {
      return "Admin";
    } else if (data === "student") {
      return "Student";
    }
  };
  var LogoutPoratl = (doLogout) => {
    doLogout()
    setLocation("/")
  }
  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <Layout>
            <div className="container-fluid">
              <div className="row">
                <div className={`col-lg-12 ${Styles.img}`}>
                  <div className={` ${Styles.circle} ${Styles.circle1}`}></div>
                  <div className={` ${Styles.circle} ${Styles.circle2}`}></div>
                </div>
                <div className="col-lg-1"></div>
                <div className={`col-lg-10 ${Styles.box}`}>
                  <div className="row">
                    <div className={`col-lg-4 ${Styles.sec1}`}>
                      <div classNam={`${Styles.imgdiv}`}>
                        <img
                          src={"https://recstudentportal.com/" + Data.image}
                          alt="profile photo"
                        ></img>
                        <Card
                          topic="Name"
                          data={Data.name === "" ? "Not Given" : Data.name}
                        />
                        <Card
                          topic="Reg Id"
                          data={Data.regid === "" ? "Not Given" : Data.regid}
                        />
                      </div>
                    </div>
                    <div className={`col-lg-4 ${Styles.sec2}`}>
                      <Card
                        topic="Email"
                        data={Data.email === "" ? "Not Given" : Data.email}
                      />
                      <Card
                        topic="Session"
                        data={
                          Data.session === null ? "Not Given" : Data.session
                        }
                      />
                      <Card
                        topic="Branch"
                        data={
                          Data.branch === ""
                            ? "Not Given"
                            : checkBranch(Data.branch)
                        }
                      />

                      <Card
                        topic="Gender"
                        data={
                          Data.gender === ""
                            ? "Not Given"
                            : checkGender(Data.gender)
                        }
                      />

                      <Card
                        topic="Type"
                        data={
                          Data.type === "" ? "Not Given" : checkType(Data.type)
                        }
                      />
                    </div>

                    <div className={`col-lg-4 ${Styles.sec2}`}>
                      <Card
                        topic="Year"
                        data={Data.year === "" ? "Not Given" : Data.year}
                      />
                      <Card
                        topic="Branch"
                        data={
                          Data.branch === ""
                            ? "Not Given"
                            : checkBranch(Data.branch)
                        }
                      />

                      <Card
                        topic="Birth"
                        data={Data.birth === "" ? "Not Given" : Data.birth}
                      />
                      <Card
                        topic="Blood"
                        data={Data.blood === "" ? "Not Given" : Data.blood}
                      />
                      <Subscribe to={[RECSPContainer]}>
                        {(recspContainer) => (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              LogoutPoratl(recspContainer.doLogout)
                            }
                            className={`btn ${Styles.logBtn}`}
                          >
                            LogOut
                          </Button>
                        )}
                      </Subscribe>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1"></div>
              </div>
            </div>
          </Layout>
          {/* <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-12"
              style={{ background: "black", width: "100%", height: "100vh" }}
            ></div>
            <div className="col-lg-1"></div>
            <div className={`col-lg-10 ${Styles.content} `}></div>
            <div className="col-lg-1"></div>
            <div className="col-lg-1"></div>
            <div className={`col-lg-10 ${Styles.content1} `}>
              <div className="row">
                <div className={`col-lg-4 ${Styles.sec1}`}>
                  <img
                    src={
                      "https://recstudentportal.com/"+Data.image
                    }
                  ></img>
                  <Card
                    topic="Name"
                    data={Data.name == "" ? "Not Given" : Data.name}
                  />
                  <Card
                    topic="Reg Id"
                    data={Data.regid == "" ? "Not Given" : Data.regid}
                  />
                  {/* {For Moblie View Only} 
                  <div className={Styles.mobliediv}>
                    {/* <Card
                    topic="Reg Id"
                    data={Data.regid == "" ? "Not Given" : Data.regid}
                  /> 
                  <Card
                    topic="Email"
                    data={Data.email == "" ? "Not Given" : Data.email}
                  />
                  <Card
                    topic="Year"
                    data={Data.year == "" ? "Not Given" : Data.year}
                  />
                  <Card
                    topic="Session"
                    data={Data.session == null ? "Not Given" : Data.session}
                  />
                  <Card
                    topic="Birth"
                    data={Data.birth == "" ? "Not Given" : Data.birth}
                  />
                  <Card
                    topic="Gender"
                    data={
                      Data.gender == "" ? "Not Given" : checkGender(Data.gender)
                    }
                  />
                  <Subscribe to={[RECSPContainer]}>
                      {(recspContainer) => (
                        <button
                          
                          onClick={() =>
                            LogoutPoratl(recspContainer.doLogout)
                          }
                          className={`btn ${Styles.logBtn}`}
                        >
                          LogOut
                        </button>
                      )}
                    </Subscribe>

                  </div>
                  {/* {Moblie View End Here} 
                </div>
                <div className={`col-lg-4 ${Styles.sec2}`}>
                  

                  <Card
                    topic="Email"
                    data={Data.email == "" ? "Not Given" : Data.email}
                  />
                  <Card
                    topic="Session"
                    data={Data.session == null ? "Not Given" : Data.session}
                  />
                  <Card
                    topic="Branch"
                    data={
                      Data.branch == "" ? "Not Given" : checkBranch(Data.branch)
                    }
                  />

                  <Card
                    topic="Gender"
                    data={
                      Data.gender == "" ? "Not Given" : checkGender(Data.gender)
                    }
                  />

                  <Card
                    topic="Type"
                    data={Data.type == "" ? "Not Given" : checkType(Data.type)}
                  />
                </div>

                <div className={`col-lg-4 ${Styles.sec2}`}>
                  
                  <Card
                    topic="Year"
                    data={Data.year == "" ? "Not Given" : Data.year}
                  />
                  <Card
                    topic="Branch"
                    data={
                      Data.branch == "" ? "Not Given" : checkBranch(Data.branch)
                    }
                  />

                  <Card
                    topic="Birth"
                    data={Data.birth == "" ? "Not Given" : Data.birth}
                  />
                  <Card
                    topic="Blood"
                    data={Data.blood == "" ? "Not Given" : Data.blood}
                  />
                  <Subscribe to={[RECSPContainer]}>
                      {(recspContainer) => (
                        <button
                          
                          onClick={() =>
                            LogoutPoratl(recspContainer.doLogout)
                          }
                          className={`btn ${Styles.logBtn}`}
                        >
                          LogOut
                        </button>
                      )}
                    </Subscribe>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div> */}
        </>
      )}
    </>
  );
}
