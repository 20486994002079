import React from 'react'
import './InputType.css'



const inputType=(props)=>{
    return(
        <div className="inputType">
            <input {...props}/>
        </div>
    )
}

export default inputType;