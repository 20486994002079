import React, { useState } from "react";
import { useLocation, Link } from "wouter";
import Styles from "./Thank.module.css";
var time = true;
export default function Thank() {

  const [location, setLocation] = useLocation();
  const [timer, setTimer] = useState();
  const [count, setCount] = useState();

  const run = () => {
    var key = document.getElementById("timer");
    if (key) {
      key = key.innerText;
      document.getElementById("timer").innerText = key - 1;
      setCount(key);
    }
  };
  const start = () => {
    setTimer(setInterval(run, 1000));
  };
  if (count <= 0) {
    clearInterval(timer);
    setLocation("/");
  }
  if (time) {
    time = false;
    start();
  }
  return (
    <>
      <div className={Styles.header}>
        <div className={Styles.title}>
          <span>Udaan</span>
          <span>Quizathon</span>
        </div>
        <div className={Styles.quit}>
          {/* <Button
            variant="contained"
            color="secondary"
            disabled={dis.quit}
            onClick={handleSubmit}
          >
            Quit/Submit
          </Button> */}
        </div>
      </div>
      <div className={Styles.mainBg}>
        <div className={Styles.overlay}>
          <div className={Styles.modal}>
            <div className={Styles.tick}>
              <svg
                viewBox="0 0 734 595"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="297.5"
                  cy="297.5"
                  r="275.5"
                  stroke="green"
                  stroke-width="44"
                />
                <path
                  d="M153.5 288.5C179.209 305.639 209.551 356.626 228.285 391.582C237.617 408.993 260.872 413.906 276.064 401.278C440.769 264.368 541.201 203.489 711.5 144"
                  stroke="green"
                  stroke-width="44"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className={Styles.text}>Thank You for Playing!</div>
            <div className={Styles.redirect}>
              <p>
                Redirecting to Main Page in<span id="timer"> 10</span>s or {" "} <Link href="/">click</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


