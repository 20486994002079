import axios from "axios";
import React, { useState } from "react";
import { useLocation } from "wouter";
import Styles from "../Response.module.css";
import Animation1 from "../../Animation/Animation2/Animation";
import Question from "../../Question/Question";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
var ans = [];
var ans_1 = [];
// var ques = []
var qans = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];
var set = 0;
function Response(props) {
  var apiUrl = "https://recstudentportal.com/api/v1/";
  var publicKey = "cmVhY3RBcHBQb3J0YWwyNjc=";
  //   const [data, setData] = useState(props.check.state);
  const [load, setLoad] = useState(true);
  const [played, setPlayed] = useState(false);
  const [ques, setQues] = useState();
  const [location, setLocation] = useLocation();
  const [user, setUser] = useState();
  const [allQues, setAllQues] = useState(null);
  if (!allQues) {
    setAllQues([Question(0), Question(1), Question(2)]);
  }
  //   const [ques,setQues]  = useState([]);

  // Check Player Played or Not
  var formData = {
    regid: props.match.params.id,
  };
  if (load && props.match.params.id) {
    axios
      .post(apiUrl + "getCountPlay.php?key=" + publicKey, formData, {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        //       console.log(res.data);
        if (res.data.length != 0) {
          set = res.data[0].setplay;
          setLoad(false);
          setPlayed(true);
        } else {
          alert("No Played");
          setLoad(false);
          setLocation("/quiz");
        }
      });
  }

  // Getting Player Response if he Played
  if (played) {

    axios
      .post(
        apiUrl + "getQueryData.php?key=" + publicKey,
        formData, {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }
      )
      .then((res) => {
        // console.log(res.data);
        if (res.data) {
          var a = res.data[0];
          setUser(res.data);
          ans = [
            a.ans1,
            a.ans2,
            a.ans3,
            a.ans4,
            a.ans5,
            a.ans6,
            a.ans7,
            a.ans8,
            a.ans9,
            a.ans10,
          ];
          //   console.log(ans);
          ans_1 = [
            a.ans1.trim().toLowerCase(),
            a.ans2.trim().toLowerCase(),
            a.ans3.trim().toLowerCase(),
            a.ans4.trim().toLowerCase(),
            a.ans5.trim().toLowerCase(),
            a.ans6.trim().toLowerCase(),
            a.ans7.trim().toLowerCase(),
            a.ans8.trim().toLowerCase(),
            a.ans9.trim().toLowerCase(),
            a.ans10.trim().toLowerCase(),
          ];
          //   console.log(set);
          setQues(allQues[set * 1 - 1]);
        }
        // console.log(ans);
      });
    setPlayed(false);
  }
  if (ques && ques.length > 0) {
    for (var i = 0; i < ques.length; i++) {
      var q = ques[i].ansOption.trim().toLowerCase();
      qans[i] = ans_1[i].includes(q) || q.includes(ans_1[i]);
    }
  }
  //   console.log(ques);
  return (
    <>
      {load || played ? (
        <Animation1 />
      ) : (
        <>
          <div className={Styles.header}>
            <div className={Styles.title}>
              <span>Udaan</span>
              <span>Quizathon</span>
            </div>
            <div className={Styles.quit}></div>
          </div>
          <div className={Styles.tab}>
            <table>
              <tr>
                <th>Answer</th>
                <th>Response</th>
                <th>Status</th>
              </tr>
              {ques?.map((data, i) => (
                <tr
                  style={{
                    color:
                      ans[i] == "-1" ? "grey" : qans[i] ? "#01ef01" : "#f34a4a",
                  }}
                >
                  <th>{ques[i].ansOption}</th>
                  <th>{ans[i]}</th>
                  <th>
                    {ans[i] == "-1" ? (
                      <IndeterminateCheckBoxIcon />
                    ) : qans[i] ? (
                      <CheckCircleIcon />
                    ) : (
                      <CancelIcon />
                    )}
                  </th>
                </tr>
              ))}
            </table>
          </div>
        </>
      )}
    </>
  );
}

export default Response;
