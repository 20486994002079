import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./notice.css";
import Layout from "../Layout/Layout";
import recspContainer from "../../Store/recspContainer";
import Loading from "../Loading/Loading";
import Styles from "./notice.module.css";
function Notice(props) {
  
  const [notices, setnotices] = useState(null);
  const [load, setLoad] = useState(true);
  const [err, setErr] = useState(null);
  const [req, setReq] = useState("0");
  const [dateArr, setDate] = useState([]);

  useEffect(() => {
    if (load) {
      axios
        .get(
          props.check.state.apiUrl +
            "getNotices.php?key=" +
            props.check.state.publicKey
        )
        .then((res) => {
          if (res.data.status === 400) {
            alert("Failed to get notices.");
          } else {
            setnotices(res.data);
            setLoad(false);
          }
        });

      axios
        .get(
          props.check.state.apiUrl +
            "getNoticeDate.php?key=" +
            props.check.state.publicKey
        )
        .then((res) => {
          
          if (res.data.status === 400) {
            alert("Failed to get notices.");
          } else {
            setDate(res.data);
          }
        });
    }
  }, []);
  const DataValid = (start,end)=>{
    if (start.length != 10 || end.length != 10) {
      setErr("Choose Date");
      return false
    }
    else if (parseInt(start.substring(6, 10)) > parseInt(end.substring(6, 10))) {
      setErr("Invalid Year");
      return false
    } else if (
      parseInt(start.substring(6, 10)) == parseInt(end.substring(6, 10)) &&
      parseInt(start.substring(3, 5)) > parseInt(end.substring(3, 5))
    ) {
      setErr("Invaild Month");
      return false
      
    } else if (
      parseInt(start.substring(3, 5)) == parseInt(end.substring(3, 5)) &&
      parseInt(start.substring(0, 2)) > parseInt(end.substring(0, 2))
    ) {
      setErr("Invalid Day");
      return false
    }
    else{
      setErr(true);
    }
    return true;
  }
  const getData = async (es) => {
    es.preventDefault();
    var start = document.getElementById("date1").value;
    var end = document.getElementById("date2").value;

    // Date Validation

    var valid = await DataValid(start,end);
    
    
 
    // Call the range Notice
    if (valid==true) {
      // console.log(err);
      setReq("1");
      var formData = {
        date1: start,
        date2: end
      };
      axios
        .post(
          props.check.state.apiUrl +
            "getrangenotice.php?key=" +
            props.check.state.publicKey,
          formData
        )
        .then((res) => {
          if (res.data.status === 400) {
            alert("Failed to get notices.");
          } else {
            setReq("2");
            setnotices(res.data);
            setTimeout(() => {
              setReq("0");
            }, 3000);

          }
        });
    }
  };
  
  if(req==0){
    var x = document.getElementById("btnn");
    if(x!=null){
        x.style.background="#3199f7";
        // console.log(x);
    }
    
  }
  if (req == 1) {
    document.getElementById("btnn").style.background = "Yellow";
  }
  if (req == 2) {
    document.getElementById("btnn").style.background = "Green";
  }
  
  return (
    <Layout>
      {load ? (
        <Loading />
      ) : (
        <>
          <div className={`container-fluid ${Styles.haha}`}>
            <div className="row">
              <div className="col-lg-1"></div>
              <div className={`col-lg-6 ${Styles.sec1}` }>
                <div className={`row ${Styles.date}`}>
                  <div className={`col-lg-6 ${Styles.from}`}>
                    <label>From</label>
                    <br></br>
                    {/* <span><input type="date" name="date1" id = "date1"></input></span> */}
                    <select id="date1" >
                    <option value="Choose Date">Choose Date</option>
                      {dateArr.map((data) => (
                        <option value={data.date}>{data.date}</option>
                      ))}
                    </select>
                  </div>
                  <div className={`col-lg-6 col-sm-4 ${Styles.to}`}>
                    <label>To</label>
                    <br></br>
                    <select id="date2" >
                    <option value="Choose Date">Choose Date</option>
                      {dateArr.map((data) => (
                        <option value={data.date}>{data.date}</option>
                      ))}
                    </select>
                    <br></br>
                    <button
                      id="btnn"
                      className={`${Styles.btn}`}
                      onClick={(e) => getData(e)}
                    >
                      {req == "0"
                        ? "Search"
                        : req == "1"
                        ? "Searching.."
                        : "Done"}
                    </button>
                    <p style={{ color: "Red" }}>{err == null ? "" : err}</p>
                  </div>
                </div>
              </div>
              <div className={`col-lg-5`}>
                <div className={`${Styles.notice}`}>
                  <h1
                    style={{ textAlign: "center" }}
                    className={`${Styles.title2}`}
                  >
                    NOTICE
                  </h1>
                  <div className={`${Styles.scroll}`}>
                    {notices.length == 0
                      ? ""
                      : notices.map((notice) => (
                          <>
                            <div className={Styles.notices}>
                              <span className={Styles.tp}>{notice.topic}</span>
                              <span className={Styles.openbtn}>
                                <a
                                  href={
                                    "https://recstudentportal.com/" +
                                    notice.address
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Open
                                </a>
                              </span>
                            </div>
                          </>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}

export default Notice;
