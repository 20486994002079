import React, { useState, useEffect } from "react";
import Styles from "./Update.module.css";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { yellow } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Invalid from "../../Invalid/Invalid";
import Loading from "../../../components/Loading/Loading";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  ques: {
    "& .MuiTextField-root": {
      marginTop: "20px",
      width: "100%",
    },
  },
  formControl: {
    //
    width: "100%",
  },
  option: {
    "& > *": {
      width: "25ch",
      marginTop: "20px;",
      color: yellow,
    },
  },
}));
var ans = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1];
export default function Update(props) {
  const [data, setData] = useState(props.check.state);
  const [query, setQuerys] = useState([]);
  const [ques, setQues] = useState(null);
  const [load1, setLoad1] = useState(true);
  const [idx, setIdx] = useState(0);
  const [idx1, setIdx1] = useState(0);
  const [values,setValues] = useState();
  const [regid,setRegid] = useState("")
  // const [ans, setAns] = useState([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);

  const classes = useStyles();

  const [dis, setDis] = useState(false);
  const [user, setUser] = useState(null);
  const [played, setPlayed] = useState(false);

  if (load1) {
    axios
      .get(data.apiUrl + "getQuery.php?key=" + data.publicKey)
      .then((res) => {
        if (res) {
        //   console.log(res.data);
          setQuerys(res.data);
          setLoad1(false);
        }
      });
  };
  if (played) {
    var formData = {
      regid: regid,
    };
    axios
      .post(data.apiUrl + "getQueryData.php?key=" + data.publicKey, formData, {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        // console.log("UserData-->", res.data[0]);
        if (res.data) {
          var a = res.data[0];
          setUser(res.data);
          var pointToday = (document.getElementById("op2").value =
            a.pointToday);
          var pointOverall = (document.getElementById("op3").value =
            a.pointOverall);
          var wrongToday = (document.getElementById("op4").value =
            a.wrongToday);
          var wrongOverall = (document.getElementById("ans").value =
            a.wrongOverall);
          var ansToday = (document.getElementById("op5").value = a.ansToday);
          var ansOverall = (document.getElementById("op6").value =
            a.ansOverall);
          var timeToday = (document.getElementById("op7").value = a.timeToday);
          var timeOverall = (document.getElementById("op8").value =
            a.timeOverall);
          ans = [
            a.ans1,
            a.ans2,
            a.ans3,
            a.ans4,
            a.ans5,
            a.ans6,
            a.ans7,
            a.ans8,
            a.ans9,
            a.ans10,
          ];
        }
      });
    axios
      .post(data.apiUrl + "getQueryQues.php?key=" + data.publicKey, formData, {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        console.log(res);
        if (res.data) {
          setQues(res.data);
        } else {
          alert("Not Played");
        }
      });
      setPlayed(false)
  }
  const FixProblem = (e, regid, idx) => {
    var formData = {
      regid: regid,
    };
    console.log(formData);
    e.preventDefault();
    setIdx(idx)
    setRegid(regid)
    axios
      .post(data.apiUrl + "getCountPlay.php?key=" + data.publicKey, formData, {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.data.length != 0) {
          setPlayed(true)
        }else{
          var pointToday = (document.getElementById("op2").value = "");
          var pointOverall = (document.getElementById("op3").value = "");
          var wrongToday = (document.getElementById("op4").value = "");
          var wrongOverall = (document.getElementById("ans").value = "");
          var ansToday = (document.getElementById("op5").value = "");
          var ansOverall = (document.getElementById("op6").value = "");
          var timeToday = (document.getElementById("op7").value = "");
          var timeOverall = (document.getElementById("op8").value = ""); 
          alert("No Played");
        }
      });
  };
  const handleChange = (event) => {
    document.getElementById("op1").value = ans[idx1]; 
	  setValues(ques[event.target.value].question);
    setIdx1(event.target.value);
  };
  const handleMark = ()=>{
    setDis(true);
    var pointToday = document.getElementById("op2")?.value; 
    var pointOverall = document.getElementById("op3")?.value; 
    var wrongToday = document.getElementById("op4")?.value; 
    var wrongOverall = document.getElementById("ans")?.value; 
    var ansToday = document.getElementById("op5")?.value; 
    var ansOverall = document.getElementById("op6")?.value; 
    var timeToday = document.getElementById("op7")?.value; 
    var timeOverall = document.getElementById("op8")?.value; 

    var formData = {
      ans1:ans[0],
      ans2:ans[1],
      ans3:ans[2],
      ans4:ans[3],
      ans5:ans[4],
      ans6:ans[5],
      ans7:ans[6],
      ans8:ans[7],
      ans9:ans[8],
      ans10:ans[9],
      pointToday:pointToday,
      pointOverall:pointOverall,
      wrongToday:wrongToday,
      wrongOverall:wrongOverall,
      ansToday:ansToday,
      ansOverall:ansOverall,
      timeToday:timeToday,
      timeOverall:timeOverall,
      regid:regid,
    }
    axios.post(
      data.apiUrl + "fixQuizComplain.php?key=" + data.publicKey,
      formData,
      {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }
    ).then((res)=>{
      if(res.data){
        var pointToday = (document.getElementById("op2").value = "");
          var pointOverall = document.getElementById("op3").value= "";
          var wrongToday = document.getElementById("op4").value = "";
          var wrongOverall = document.getElementById("ans").value = "";
          var ansToday = document.getElementById("op5").value = "";
          var ansOverall = document.getElementById("op6").value = "";
          var timeToday = document.getElementById("op7").value = "";
          var timeOverall = document.getElementById("op8").value = ""; 
        setDis(false)
        setQuerys(res.data);
      }
    });
  }
  const handleUpdate = ()=>{
    var val = document.getElementById("op1")?.value 
    ans[idx1]=val;
  }

  return (
    <>
      {data.userType == "admin" && data.year == 3 ? (
        load1 ? (
          <Loading></Loading>
        ) : (
          <div className="container-fluid">
            <h1>Problem Resolve Panel</h1>
            <form autoComplete="off" action="">
              <div className="row">
                <div className={`col-lg-8`}>
                  <div className={classes.ques}>
                    <h5>{query.length == 0 ? "" : query[idx]?.problem}</h5>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Select Question
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={values}
                        onChange={handleChange}
                        label="Select Question"
                      >
                        {ques?.map((data, idx) => (
                          <MenuItem value={idx}>{data.question}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={`${Styles.option} ${classes.option}`}>
                    <TextField
                      id="op1"
                      label="Answer"
                      variant="outlined"
                      required
                      aria-selected
                      defaultValue={ans[idx1]}
                      placeholder="3"
                    />
                    <TextField
                      id="op2"
                      label="Point Today"
                      variant="outlined"
                      aria-selected
                      required
                      defaultValue={"0"}
                      placeholder="4"
                    />
                    <TextField
                      id="op3"
                      label="Total Point"
                      variant="outlined"
                      aria-selected
                      required
                      defaultValue={"0"}
                      placeholder="5"
                    />
                    <TextField
                      id="op4"
                      label="Wrong Today"
                      variant="outlined"
                      aria-selected
                      required
                      defaultValue={"0"}
                      // value={!user ? "" : user[0].wrongToday}
                      placeholder="6"
                    />
                    <TextField
                      id="ans"
                      label="Total Wrong"
                      variant="outlined"
                      required
                      aria-selected
                      defaultValue={"0"}
                      // value={!user ? "" : user[0].wrongOverall}
                      placeholder="4"
                    />
                    <TextField
                      id="op5"
                      label="Ans Today"
                      variant="outlined"
                      required
                      aria-selected
                      defaultValue={"0"}
                      // value={!user ? "" : user[0].ansToday}
                      placeholder="6"
                    />
                    <TextField
                      id="op6"
                      label="Total Ans"
                      variant="outlined"
                      required
                      aria-selected
                      defaultValue={"0"}
                      // value={!user ? "" : user[0].ansOverall}
                      placeholder="4"
                    />
                    <TextField
                      id="op7"
                      label="Time Today"
                      variant="outlined"
                      required
                      aria-selected
                      defaultValue={"0"}
                      // value={!user ? "" : user[0].timeToday}
                      placeholder="6"
                    />
                    <TextField
                      id="op8"
                      label="Total Time"
                      variant="outlined"
                      required
                      aria-selected
                      defaultValue={"0"}
                      // value={!user ? "" : user[0].timeOverall}
                      placeholder="4"
                    />
                  </div>
                </div>
                <div className={`col-lg-4`}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={dis}
                    onClick={handleMark}
                  >
                    {dis ? "Marking..." : "Mark as Done"}
                  </Button>
                </div>
              </div>
            </form>
            <div className={Styles.sec}>
              {query?.map((data, i) => (
                <>
                  {query.regid && true ? (
                    ""
                  ) : (
                    <>
                      <div className={Styles.ques}>
                        <div>{data.id}</div>
                        <div>{data.regid}</div>
                        <div>{data.problem}</div>

                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={(e) => FixProblem(e, data.regid, i)}
                        >
                          Fix It
                        </Button>
                      </div>
                    </>
                  )}{" "}
                </>
              ))}
            </div>
          </div>
        )
      ) : (
        <Invalid></Invalid>
      )}
    </>
  );
}
