// For MCQ Answer -->

import React, { useEffect, useState } from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Styles from "./Quiz.module.css";
import { useLocation } from "wouter";
import axios from "axios";
import Animation1 from "./Animation/Animation1/Animation";
import Animation2 from "./Animation/Animation2/Animation";
import Animation3 from "./Animation/Animation3/Animation";
import Question from "./Question/Question";
import recspContainer from "../Store/recspContainer";

// import { Loop, SignalCellularNullTwoTone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  question: {
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
  formControl: {
    margin: "0px",
    // padding: "30px",
    width: "100%",
  },
}));



// Get Variable from store
const container = new recspContainer();
const Variable = container.quizVariable()

// unpacking the requied variable
var gameStart = Variable.gameStart;
var gameEnd = Variable.gameEnd;
var quizTime = Variable.quizTime;
var day = Variable.day;
var round = Variable.round;


// Donot Remove this Variable
var totalPoint = 0,
  totalTime = 0,
  totalSkip = 0,
  totalAttempt = 0,
  totalWrong = 0,
  totalCorrect = 0;
var curTime = new Date();

var check = true,
  alt = true,
  m = 2,
  idx = 0,
  cnt = 0,
  keeper = true,skipbool = false,regid=null,ttt=true;
  var changeState = false,tabs = false;
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState == "hidden") {
      cnt = document.getElementById("tabSwitch")
      changeState = true
      if(cnt){
        var x = cnt.innerText;
        x = (x*1)+1;
        document.getElementById("tabSwitch").innerText = x;
        // console.log(document.getElementById("tabSwitch").innerText);
      }
    }
  });
export default function Quiz(props) {
const classes = useStyles();
  // const [change, setChange] = useState(true);

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });

    var tab = document.getElementById("tabSwitch");
    if (tabs && tab) {
      var x = tab.innerHTML;
      if (changeState && x == 1) {
        alert("Tab Switch May Lead you Disqualified");
        changeState = false;
      } else if (changeState && x != 0 && x < 4) {
        alert("Tab Switch May Lead you Disqualified");
        changeState = false;
        handleSkip();
      } else if (changeState && x != 0) {
        changeState = false;
        alert("You are using Unfair Means. Thank You for play");
        thenSubmit();
      }
    }

  });
  document.addEventListener("cut copy paste", (e) => {
    e.preventDefault();
  });

  //Decleare State
  const [location, setLocation] = useLocation();
  const [error, setError] = useState(false);
  const [Inrve, setInrve] = useState();
  const [opvalue, setOpvalue] = useState(null);
  const [helper, setHelper] = useState(null);
  const [count, setCount] = useState(90);
  // const [change,setChange] = useState(changeState);
  const [load, setLoad] = useState({
    ques: true,
    details: true,
    play: true,
    insert: false,
  });
  const [set, setSet] = useState(Math.floor(Math.random() * 3));
  // const [set, setSet] = useState(1);
  const [ans, setAns] = useState([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
  const [dis, setDis] = useState({ next: true, skip: true, quit: true });
  const [ques1,setQues1] = useState()
  const [change, setChange] = useState(0);
  const [detech,setDetech] = useState(false);
  const [allQues,setAllQues] = useState(null);

  const [ques,setQuestion] = useState([
    {
      id: "123",
      qset: "1",
      question: "Don't be Over Smart ",
      op1: "Ok",
      op2: "Hope You UnderStand",
      op3: "Other Wise You may DisQuilified",
      op4: "So Click on start",
      ansOption: "18",
    },
  ]);

  if(!allQues){
    setAllQues([Question(0),Question(1),Question(2)])
  }

  // Getting Backend Data

  var formData = {
    day: day,
    round: round,
    secret: "DontbeOverSmartQues",
    set: set + 1,
    regid: props.check.state.regid,
    year: props.check.state.year,
  };
  if (!load.details && load.ques) {
    setQues1(allQues[set]);
    setLoad({ ...load, ques: false });
    // axios
    //   .post(
    //     props.check.state.apiUrl +
    //       "getQuizQuestion.php?key=" +
    //       props.check.state.publicKey,
    //     formData,
    //     {
    //       withCredentials: true,
    //       headers: { "Content-Type": "application/json" },
    //     }
    //   )
    //   .then((res) => {
    //     if(res.data){

    //       setLoad({ ...load, ques: false });
    //       setQues1(res.data);
    //     }
    //     // console.log("ques-->",res);
    //   });
  }
  formData = {
    day: day,
    round: round,
    secret: "DontbeOverSmartDetails",
    set: set + 1,
    regid: props.check.state.regid,
    year: props.check.state.year,
  };
  if (load.details) {
    // console.log(set);
    setTimeout(() => {
      if (alt && !props.check.state.isLoggedIn) {
        alert("Please Login with Your Account");
        setLocation("/login");
      }
      if (props.check.state.isLoggedIn && !props.check.state.isAdmin) {
        alert("Not For You");
        setLocation("/error");
      }
    }, 5000);
    if (props.check.state.isLoggedIn) {
      regid = props.check.state.regid
      axios
        .post(
          props.check.state.apiUrl +
            "getQuizQuestion.php?key=" +
            props.check.state.publicKey,
          formData,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.length === 0) {
            setLoad({
              ...load,
              details: false,
            });
          } else {
            console.log("details-->",res);
            alert("You had already played");
            setLocation("/thank");
          }
        });
    }
  }

  // Check Time
  // console.log(cnt);

 if (check && curTime.getHours() === gameStart) {
    check = false;
    if (curTime.getMinutes() >= gameEnd + 1) {
      alert("Time Over")
      alt = false
      setLocation("/quiz")
    }
  }
  else if(check){
    check = false
    alt = false
    alert("Time Over");
    setLocation("/quiz");
  }

  const detechMeans = () => {
    var formData = {
      regid:props.check.state.regid
    }
    axios.post(
      props.check.state.apiUrl +
        "getCountPlay.php?key=" +
        props.check.state.publicKey,
      formData,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res)=>{
      if(res.data && res.data.length!=1){
        alert("You are Detected using Unfair Means so you are disqualified from today game");
        setLocation("/thank")
      }
    });
  }

  // Handling Functions
  const handleChange = (e) => {
    e.preventDefault();
  };
  const handleUserData = (value, quit) => {
    // console.log("Data-->",data)

    // console.log(`Point-->${totalPoint}`, `Time-->${totalTime}`, `Skip-->${totalSkip}`, `Wrong-->${totalWrong}`, `Correct-->${totalCorrect}`, `Attempt-->${totalAttempt}`);
    // console.log("Answer-->",ans);

    var url =
      props.check.state.apiUrl +
      "insertUserData" +
      value +
      ".php?key=" +
      props.check.state.publicKey;
    if (quit) {
      setDis({ next: true, skip: true, quit: true });
    }
    if (!quit) {
      resetTimer();
    }

    // console.log("Time -->",totalTime)
    var userData = {
      ans1: ans[0],
      ans2: ans[1],
      ans3: ans[2],
      ans4: ans[3],
      ans5: ans[4],
      ans6: ans[5],
      ans7: ans[6],
      ans8: ans[7],
      ans9: ans[8],
      ans10: ans[9],
      wrong: totalWrong,
      correct: totalAttempt,
      time: totalTime,
      point: totalPoint,
      idx: idx,
      regid: regid,
    };
    // console.log(props.check.state.config);
    axios
      .post(url,userData, props.check.state.config )
      .then((res) => {
        if (res.data && quit) {
          setLocation("/thank");
        }
        // console.log("UserData-->",res);
      });
  };
  const handleRadioChange = (event) => {
    setOpvalue(event.target.value);
    setError(false);
    setHelper(null);
  };
  var resetAnimation = () => {
    var right = document.getElementById("right__progress");
    var left = document.getElementById("left__progress");
    var circle = document.getElementById("circle__progress");
    var dot = document.getElementById("dot__progress");
    var num = document.getElementById("time__progress");
    var num1 = document.getElementById("timer__progress");
    setInterval(() => {
      if (m === 2) {
        m = 3;
        right.style.animation = `right ${quizTime/2}s linear`;
        left.style.animation = `left ${quizTime/2}s linear both`;
        left.style.animationDelay = `${quizTime/2}s`;
        circle.style.animation = `animate_colour ${quizTime}s linear`;
        dot.style.animation = `dot ${quizTime}s linear both`;
        num.style.animation = `animate_text_colour ${quizTime}s linear`;
        num1.style.animation = `animate_text_colour ${quizTime}s linear`;
      } else {
        right.style.animation = null;
        left.style.animation = null;
        circle.style.animation = null;
        dot.style.animation = null;
        num.style.animation = null;
        num1.style.animation = null;

        clearInterval();
      }
    }, 500);
  };
  var run = () => {
    var key = document.getElementById("timer__progress");
    var keys = document.getElementById("time__progress");

    if (key && keys) {
      key = key.innerHTML;
      keys = keys.innerHTML;
      document.getElementById("timer__progress").innerText = key - 1;
      document.getElementById("time__progress").innerText = keys - 1;
      setCount(key);
      // console.log("count->",count);
    }
  };
  const resetTimer = () => {
    m = 2;
    resetAnimation();
    document.getElementById("timer__progress").innerHTML = quizTime;
    document.getElementById("time__progress").innerHTML = quizTime;
    clearInterval(Inrve);
    setInrve(setInterval(run, 1000));
  };
  const changeEveryThing = (a, s) => {
    detechMeans()
    totalAttempt += a;
    totalSkip += s;
    totalTime += quizTime - document.getElementById("timer__progress").innerHTML;
    if (a) {
      ans[idx] = opvalue;
      if (opvalue === ques[idx].ansOption) {
        totalPoint += 4;
        totalCorrect += 1;
      } else {
        totalPoint -= 1;
        totalWrong += 1;
      }
    }
    document.getElementById("attempt").innerText = totalAttempt;
    document.getElementById("skip").innerText = totalSkip;

    var i = idx + 1;
    if (i == 9) {
      setDis({ skip: true, next: true, quit: false });
    }
    if (i < 10) {
      idx += 1;
    }
    setError(false);
    setHelper("");
    setOpvalue("");
    handleUserData(set + 1, false);
  };

  // Handling Button Click
  const handleNextClick = async () => {
    changeEveryThing(1, 0);
    // handleUserData(set+1)
  };
  const handleSkip = async () => {
    // console.log("Skip Press");
    if (keeper && !(idx < 9)) {
      keeper=false;
      skipbool = true
      handleSubmit();
    } else if(idx<9){
      changeEveryThing(0, 1);
    }
  };
  const handleUnselect = () => {
    setOpvalue("");
  };
  const handleError = () => {
    setError(true);
    setHelper("Please Choose Option");
  };
  const handleOverlay = (e) => {
    tabs = true
    document.getElementById("tabSwitch").innerText = 0;
    e.preventDefault();
    setQuestion(ques1)
    setDis({ next: false, skip: false, quit: false });
    document.getElementById("overlay").style.top = "-200%";
    if (load.play) {
      formData = {
        day: day,
        round: round,
        secret: "DontbeOverSmartPlay",
        set: set + 1,
        regid: props.check.state.regid,
        year: props.check.state.year,
      };

      axios
        .post(
          props.check.state.apiUrl +
            "getQuizQuestion.php?key=" +
            props.check.state.publicKey,
          formData,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log(res);
          // console.log(res.data.length)
          if (res.data) {
            setLoad({
              ...load,
              play: false,
            });

            // console.log(res);
          }
        });
      resetTimer();
    }
  };
  const thenSubmit = ()=>{
    setDis({ ...dis, next: true, quit: true });
    keeper = false;
    document.getElementById("overlay1").style.top = "0%";
    if (ttt) {
      ttt = false;
      var attemp = 0,
        skip = 1;
      if (!skipbool) {
        attemp = opvalue ? 1 : 0;
        skip = opvalue ? 0 : 1;
      }

      totalAttempt += attemp;
      totalSkip += skip;
      totalTime += quizTime - document.getElementById("timer__progress").innerHTML;
      if (!skipbool && opvalue) {
        ans[idx] = opvalue;
        if (opvalue === ques[idx].ansOption) {
          totalPoint += 4;
          totalCorrect += 1;
        } else {
          totalPoint -= 1;
          totalWrong += 1;
        }
      }
    }
    document.getElementById("attempt").innerText = totalAttempt;
    document.getElementById("skip").innerText = totalSkip;
    var dta = document.getElementById("setNumber").innerText;
    setTimeout(() => {
      // console.log("submit");
      handleUserData(dta, true);
    }, 2000);
  }
  const handleSubmit = () => {
    if(idx<9){
      if (window.confirm("Do You Want to Quit")) {
        thenSubmit();
      }
      else{
       changeEveryThing(0,1)
      }
    }
    else{
      thenSubmit();
    }
  };

  //Conditions
  if (count <= 0) {
    // console.log("Hello");
    setCount(100);
    idx < 9 ? handleSkip() : handleSkip();
  }
  if (dis.next && idx === 10) {
    console.log("Last Line");
    setDis({ next: true, skip: true, quit: false });
    handleUserData(set + 1, true);
  }

  return (
    <>
      <div className={Styles.overlay} id="overlay">
        <p id="setNumber" style={{ color: "white", top: "-200%" }}>
          {set + 1}
        </p>
        <p id="tabSwitch" style={{ color: "white", marginTop: "-60px" }}>
          {change + 0}
        </p>
        <div className={Styles.header1}>
          <div className={Styles.title}>
            <span>Udaan</span>
            <span>Quizathon</span>
          </div>
        </div>
        <Animation1 />
        <div className={Styles.overlay__div}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOverlay}
            disabled={load.details || load.ques}
          >
            {load.details
              ? "Checking Details"
              : load.ques
              ? "SetUp Things"
              : "Start"}
          </Button>
        </div>
      </div>

      <div className={Styles.overlay1} id="overlay1">
        <div className={Styles.header1}>
          <div className={Styles.title}>
            <span>Udaan</span>
            <span>Quizathon</span>
          </div>
        </div>
        {!dis.quit ? <Animation2 /> : <Animation3 />}
        <br></br>
        <h1>Quiz is Over</h1>
        <br></br>
        <div className={Styles.overlay1__div}>
          <Button
            variant="contained"
            color="secondary"
            disabled={dis.quit}
            onClick={handleSubmit}
          >
            {dis.quit ? "Submiting..." : "Quit/Submit"}
          </Button>
        </div>
      </div>

      <div className="container-fluid">
        <div className={Styles.header}>
          <div className={Styles.title}>
            <span>Udaan</span>
            <span>Quizathon</span>
          </div>
          <div className={Styles.quit}>
            <Button
              variant="contained"
              color="secondary"
              disabled={dis.quit}
              onClick={handleSubmit}
            >
              Quit
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className={Styles.details}>
              <div className={Styles.qno}>
                <h6>Qno-{idx + 1}</h6>
              </div>
              <div className={Styles.timer}>
                <p>
                  Time Left -{" "}
                  <p className={Styles.time__progress} id="timer__progress">
                    90
                  </p>
                </p>
              </div>
            </div>

            <div className={`${Styles.question} ${classes.question}`}>
              <TextField
                disabled
                id="outlined-read-only-textarea"
                label="Question"
                placeholder={""}
                multiline
                variant="outlined"
                onCut={handleChange}
                onCopy={handleChange}
                onPaste={handleChange}
                value={ques ? ques[idx].question : ""}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <div className={Styles.option}>
              <form
                // onSubmit={handleSubmit}
                className={Styles.width}
              >
                <FormControl
                  component="fieldset"
                  error={error}
                  className={classes.formControl}
                  onChange={handleRadioChange}
                >
                  <div className={Styles.option__div}>
                    <div>
                      <FormLabel component="legend">
                        Your Answer is...
                      </FormLabel>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        disabled={idx < 9 ? dis.skip : opvalue ? false : true}
                        onClick={opvalue ? handleUnselect : handleSkip}
                      >
                        {opvalue ? "UnSelect" : "Skip"}
                      </Button>
                    </div>
                  </div>
                  <RadioGroup
                    aria-label="quiz"
                    name="quiz"
                    value={opvalue}
                    // onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value={ques ? ques[idx].op1 : ""}
                      control={<Radio color="primary" />}
                      label={ques ? ques[idx].op1 : ""}
                    />
                    <FormControlLabel
                      value={ques ? ques[idx].op2 : ""}
                      control={<Radio color="primary" />}
                      label={ques ? ques[idx].op2 : ""}
                    />
                    <FormControlLabel
                      value={ques ? ques[idx].op3 : ""}
                      control={<Radio color="primary" />}
                      label={ques ? ques[idx].op3 : ""}
                    />
                    <div className={Styles.option__div}>
                      <div>
                        <FormControlLabel
                          value={ques ? ques[idx].op4 : ""}
                          control={<Radio color="primary" />}
                          label={ques ? ques[idx].op4 : ""}
                        />
                      </div>
                      <div>
                        <Button
                          // disabled = {error}
                          variant="contained"
                          color="primary"
                          disabled={dis.next && dis.quit}
                          onClick={
                            dis.next
                              ? handleSubmit
                              : opvalue
                              ? handleNextClick
                              : handleError
                          }
                        >
                          {dis.next ? "Submit" : "Next"}
                        </Button>
                      </div>
                    </div>
                  </RadioGroup>
                  <FormHelperText>{helper}</FormHelperText>
                </FormControl>
              </form>
            </div>
          </div>
          <div className="col-lg-4">
            <div className={Styles.circle__content}>
              <div className={Styles.circular}>
                <div className={Styles.inner}></div>
                <div className={Styles.outer}></div>
                <div className={Styles.numb} id="time">
                  <p id="time__progress">90</p>
                </div>
                <div className={Styles.circle} id={"circle__progress"}>
                  <div className={Styles.dot} id={"dot__progress"}>
                    <span></span>
                  </div>
                  <div className={`${Styles.bar} ${Styles.left}`}>
                    <div
                      className={Styles.progress}
                      id={"left__progress"}
                    ></div>
                  </div>
                  <div className={`${Styles.bar} ${Styles.right}`}>
                    <div
                      className={Styles.progress}
                      id={"right__progress"}
                    ></div>
                  </div>
                </div>
              </div>
              <div className={`${Styles.circles} ${Styles.circle2}`}>
                <h3>Days</h3>
                <h1>{day}</h1>
              </div>
              <div className={`${Styles.circles} ${Styles.circle3}`}>
                <h3>Skip</h3>
                <h1 id="skip">0</h1>
              </div>
              <div className={`${Styles.circles} ${Styles.circle4}`}>
                <h3>Attempt</h3>
                <h1 id="attempt">0</h1>
              </div>
            </div>
            <br></br>
            <div className={Styles.quit1}>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  className={Styles.btn}
                  disabled={dis.quit}
                  onClick={handleSubmit}
                >
                  Quit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}





// For One Word Answer -->

// import React, { useEffect, useState } from "react";

// import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
// import { makeStyles } from "@material-ui/core/styles";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import FormHelperText from "@material-ui/core/FormHelperText";
// import FormLabel from "@material-ui/core/FormLabel";
// import Styles from "./Quiz.module.css";
// import { useLocation } from "wouter";
// import axios from "axios";
// import Animation1 from "./Animation/Animation1/Animation";
// import Animation2 from "./Animation/Animation2/Animation";
// import Animation3 from "./Animation/Animation3/Animation";
// import Question from "./Question/Question";
// import InputLabel from "@material-ui/core/InputLabel";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import recspContainer from "../Store/recspContainer";
// // import { Loop, SignalCellularNullTwoTone } from "@material-ui/icons";

// const useStyles = makeStyles((theme) => ({
//   question: {
//     "& .MuiTextField-root": {
//       width: "100%",
//     },
//   },
//   formControl: {
//     margin: "0px",
//     // padding: "30px",
//     width: "100%",
//   },
// }));

// // Get Variable from store
// const container = new recspContainer();
// const Variable = container.quizVariable()

// // unpacking the requied variable
// var gameStart = Variable.gameStart;
// var gameEnd = Variable.gameEnd;
// var quizTime = Variable.quizTime;
// var day = Variable.day;
// var round = Variable.round;

// // Donot Remove this Variable
// var totalPoint = 0,
//   totalTime = 0,
//   totalSkip = 0,
//   totalAttempt = 0,
//   totalWrong = 0,
//   totalCorrect = 0;
// var curTime = new Date();
// var todayDate = new Date().getDate() * 1 - 21 + 1;

// var check = true,
//   alt = true,
//   m = 2,
//   idx = 0,
//   cnt = 0,
//   keeper = true,
//   skipbool = false,
//   regid = null,
//   ttt = true;
// var changeState = false,
//   tabs = false;
// document.addEventListener("visibilitychange", () => {
//   if (document.visibilityState == "hidden") {
//     cnt = document.getElementById("tabSwitch");
//     changeState = true;
//     if (cnt) {
//       var x = cnt.innerText;
//       x = (x * 1) + 1;
//       document.getElementById("tabSwitch").innerText = x;
//       // console.log(document.getElementById("tabSwitch").innerText);
//     }
//   }
// });
// export default function Quiz(props) {
//   const classes = useStyles();
//   // const [change, setChange] = useState(true);

//   useEffect(() => {
//     document.addEventListener("contextmenu", (e) => {
//       e.preventDefault();
//     });

//     var tab = document.getElementById("tabSwitch");
//     if (tabs && tab) {
//       var x = tab.innerHTML;
//       if (changeState && x == 1) {
//         alert("Tab Switch May Lead you Disqualified");
//         changeState = false;
//       }
//       else if (changeState && x != 0 && x < 3) {
//         alert("Tab Switch May Lead you Disqualified");
//         changeState = false;
//         handleSkip();
//       }
//       else if (changeState && x != 0) {
//         changeState = false;
//         alert("You are using Unfair Means. Thank You for play");
//         thenSubmit();
//       }
//     }
//   });
//   document.addEventListener("cut copy paste", (e) => {
//     e.preventDefault();
//   });

//   //Decleare State
//   const [location, setLocation] = useLocation();
//   const [error, setError] = useState(false);
//   const [Inrve, setInrve] = useState();
//   const [opvalue, setOpvalue] = useState(null);
//   const [helper, setHelper] = useState(null);
//   const [count, setCount] = useState(90);
//   const [type, setType] = useState(null);
//   // const [change,setChange] = useState(changeState);
//   const [load, setLoad] = useState({
//     ques: true,
//     details: true,
//     play: true,
//     insert: false,
//   });
//   const [set, setSet] = useState(Math.floor(Math.random() * 3));
//   // const [set, setSet] = useState(1);
//   const [ans, setAns] = useState([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
//   const [dis, setDis] = useState({ next: true, skip: true, quit: true });
//   const [ques1, setQues1] = useState();
//   const [change, setChange] = useState(0);
//   const [detech, setDetech] = useState(false);
//   const [allQues, setAllQues] = useState(null);

//   const [ques, setQuestion] = useState([
//     {
//       id: "123",
//       qset: "1",
//       question: "Don't be Over Smart ",
//       op1: "Ok",
//       op2: "Hope You UnderStand",
//       op3: "Other Wise You may DisQuilified",
//       op4: "So Click on start",
//       ansOption: "18",
//       type: "Word"
//     },
//   ]);

//   if (!allQues) {
//     setAllQues([Question(0), Question(1), Question(2)]);
//   }

//   // Getting Backend Data
//   var formData = {
//     day: day,
//     round: round,
//     secret: "DontbeOverSmartQues",
//     set: set + 1,
//     regid: props.check.state.regid,
//     year: props.check.state.year,
//   };
//   if (!load.details && load.ques) {
//     setQues1(allQues[set]);
//     setLoad({ ...load, ques: false });
//     // axios
//     //   .post(
//     //     props.check.state.apiUrl +
//     //       "getQuizQuestion.php?key=" +
//     //       props.check.state.publicKey,
//     //     formData,
//     //     {
//     //       withCredentials: true,
//     //       headers: { "Content-Type": "application/json" },
//     //     }
//     //   )
//     //   .then((res) => {
//     //     if(res.data){

//     //       setLoad({ ...load, ques: false });
//     //       setQues1(res.data);
//     //     }
//     //     // console.log("ques-->",res);
//     //   });
//   }
//   formData = {
//     day: day,
//     round: round,
//     secret: "DontbeOverSmartDetails",
//     set: set + 1,
//     regid: props.check.state.regid,
//     year: props.check.state.year,
//   };
//   console.log("Form Data --> ", formData);
//   if (load.details) {
//     // console.log(set);
//     setTimeout(() => {
//       if (alt && !props.check.state.isLoggedIn) {
//         alert("Please Login with Your Account");
//         setLocation("/login");
//       }
//       if (props.check.state.isLoggedIn && !props.check.state.isAdmin) {
//         alert("Not For You");
//         setLocation("/error");
//       }
//     }, 5000);
//     if (props.check.state.isLoggedIn) {
//       regid = props.check.state.regid;
//       axios
//         .post(
//           props.check.state.apiUrl +
//           "getQuizQuestion.php?key=" +
//           props.check.state.publicKey,
//           formData,
//           {
//             withCredentials: true,
//             headers: {
//               "Content-Type": "application/json",
//             },
//           }
//         )
//         .then((res) => {
//           if (res.data.length === 0) {
//             setLoad({
//               ...load,
//               details: false,
//             });
//           } else {
//             // console.log("details-->",res);
//             alert("You had already played");
//             setLocation("/thank");
//           }
//         });
//     }
//   }

//   // Check Time
//   // console.log(cnt);

//   if (check && curTime.getHours() == gameStart) {
//     check = false;
//     if (curTime.getMinutes() >= gameEnd + 1) {
//       alert("Time Over");
//       alt = false;
//       setLocation("/quiz");
//     }
//   } else if (check) {
//     check = false;
//     alt = false;
//     alert("Time Over");
//     setLocation("/quiz");
//   }

//   const detechMeans = () => {
//     var formData = {
//       regid: props.check.state.regid,
//     };
//     axios
//       .post(
//         props.check.state.apiUrl +
//         "getCountPlay.php?key=" +
//         props.check.state.publicKey,
//         formData,
//         {
//           withCredentials: true,
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       )
//       .then((res) => {
//         if (res.data && res.data.length != 1) {
//           alert(
//             "You are Detected using Unfair Means so you are disqualified from today game"
//           );
//           setLocation("/thank");
//         }
//       });
//   };

//   // Handling Functions
//   const handleChange = (e) => {
//     e.preventDefault();
//   };
//   const handleTextChange = (event) => {
//     console.log(event.target.value);
//     setOpvalue(event.target.value);
//     setError(false);
//     setHelper(null);
//   }
//   const handleUserData = (value, quit) => {
//     // console.log("Data-->",data)

//     // console.log(Point-->${totalPoint}, Time-->${totalTime}, Skip-->${totalSkip}, Wrong-->${totalWrong}, Correct-->${totalCorrect}, Attempt-->${totalAttempt});
//     // console.log("Answer-->",ans);

//     var url =
//       props.check.state.apiUrl +
//       "insertUserData" +
//       value +
//       ".php?key=" +
//       props.check.state.publicKey;
//     if (quit) {
//       setDis({ next: true, skip: true, quit: true });
//     }
//     if (!quit) {
//       resetTimer();
//     }

//     // console.log("Time -->",totalTime)
//     var userData = {
//       ans1: ans[0],
//       ans2: ans[1],
//       ans3: ans[2],
//       ans4: ans[3],
//       ans5: ans[4],
//       ans6: ans[5],
//       ans7: ans[6],
//       ans8: ans[7],
//       ans9: ans[8],
//       ans10: ans[9],
//       wrong: totalWrong,
//       correct: totalAttempt,
//       time: totalTime,
//       point: totalPoint,
//       idx: idx,
//       regid: regid,
//     };
//     // console.log(props.check.state.config);
//     axios.post(url, userData, props.check.state.config).then((res) => {
//       if (res.data && quit) {
//         setLocation("/thank");
//       }
//       // console.log("UserData-->",res);
//     });
//   };
//   const handleRadioChange = (event) => {

//     setOpvalue(event.target.value);
//     setError(false);
//     setHelper(null);
//   };
//   var resetAnimation = () => {
//     var right = document.getElementById("right__progress");
//     var left = document.getElementById("left__progress");
//     var circle = document.getElementById("circle__progress");
//     var dot = document.getElementById("dot__progress");
//     var num = document.getElementById("time__progress");
//     var num1 = document.getElementById("timer__progress");
//     setInterval(() => {
//       if (m === 2) {
//         m = 3;
//         right.style.animation = `right ${quizTime/2}s linear`;
//         left.style.animation = `left ${quizTime/2}s linear both`;
//         left.style.animationDelay = `${quizTime / 2}s`;
//         circle.style.animation = `animate_colour ${quizTime/2}s linear`;
//         dot.style.animation = `dot ${quizTime/2}s linear both`;
//         num.style.animation = `animate_text_colour ${quizTime/2}s linear`;
//         num1.style.animation = `animate_text_colour ${quizTime/2}s linear`;
//       } else {
//         right.style.animation = null;
//         left.style.animation = null;
//         circle.style.animation = null;
//         dot.style.animation = null;
//         num.style.animation = null;
//         num1.style.animation = null;

//         clearInterval();
//       }
//     }, 500);
//   };
//   var run = () => {
//     var key = document.getElementById("timer__progress");
//     var keys = document.getElementById("time__progress");

//     if (key && keys) {
//       key = key.innerHTML;
//       keys = keys.innerHTML;
//       document.getElementById("timer__progress").innerText = key - 1;
//       document.getElementById("time__progress").innerText = keys - 1;
//       setCount(key);
//       // console.log("count->",count);
//     }
//   };
//   const resetTimer = () => {
//     m = 2;
//     resetAnimation();
//     document.getElementById("timer__progress").innerHTML = quizTime;
//     document.getElementById("time__progress").innerHTML = quizTime;
//     clearInterval(Inrve);
//     setInrve(setInterval(run, 1000));
//   };
//   const changeEveryThing = (a, s) => {
//     detechMeans();
//     totalAttempt += a;
//     totalSkip += s;
//     totalTime += quizTime - document.getElementById("timer__progress").innerHTML;
//     if (a) {
//       var stt = opvalue.trim().toLowerCase();
//       var anss = ques[idx].ansOption.trim().toLowerCase();
//       ans[idx] = opvalue;
//       if (stt.includes(anss) || anss.includes(stt)) {
//         totalPoint += 4;
//         totalCorrect += 1;
//       } else {
//         totalPoint -= 1;
//         totalWrong += 1;
//       }
//     }
//     document.getElementById("attempt").innerText = totalAttempt;
//     document.getElementById("skip").innerText = totalSkip;

//     var i = idx + 1;
//     if (i == 9) {
//       setDis({ skip: true, next: true, quit: false });
//     }
//     if (i < 10) {
//       idx += 1;
//     }
//     setError(false);
//     setHelper("");
//     setOpvalue("");
//     handleUserData(set + 1, false);

//   };

//   // Handling Button Click
//   const handleNextClick = async () => {
//     changeEveryThing(1, 0);
//     // handleUserData(set+1)
//   };
//   const handleSkip = async () => {
//     // console.log("Skip Press");
//     if (keeper && !(idx < 9)) {
//       keeper = false;
//       skipbool = true;
//       handleSubmit();
//     } else if (idx < 9) {
//       changeEveryThing(0, 1);
//     }
//   };
//   const handleUnselect = () => {
//     setOpvalue("");
//   };
//   const handleError = () => {
//     setError(true);
//     setHelper("Please Write Answer");
//   };
//   const handleOverlay = (e) => {
//     tabs = true;
//     document.getElementById("tabSwitch").innerText = 0;
//     e.preventDefault();
//     setQuestion(ques1);
//     setDis({ next: false, skip: false, quit: false });
//     document.getElementById("overlay").style.top = "-200%";
//     if (load.play) {
//       formData = {
//         day: day,
//         round: round,
//         secret: "DontbeOverSmartPlay",
//         set: set + 1,
//         regid: props.check.state.regid,
//         year: props.check.state.year,
//       };

//       axios
//         .post(
//           props.check.state.apiUrl +
//           "getQuizQuestion.php?key=" +
//           props.check.state.publicKey,
//           formData,
//           {
//             withCredentials: true,
//             headers: {
//               "Content-Type": "application/json",
//             },
//           }
//         )
//         .then((res) => {
//           // console.log(res);
//           // console.log(res.data.length)
//           if (res.data) {
//             setLoad({
//               ...load,
//               play: false,
//             });

//             // console.log(res);
//           }
//         });
//       resetTimer();
//     }
//   };
//   const thenSubmit = () => {
//     setDis({ ...dis, next: true, quit: true });
//     keeper = false;
//     document.getElementById("overlay1").style.top = "0%";
//     if (ttt) {
//       ttt = false;
//       var attemp = 0,
//         skip = 1;
//       if (!skipbool) {
//         attemp = opvalue ? 1 : 0;
//         skip = opvalue ? 0 : 1;
//       }

//       totalAttempt += attemp;
//       totalSkip += skip;
//       totalTime += quizTime - document.getElementById("timer__progress").innerHTML;
//       if (!skipbool && opvalue) {
//         var stt = opvalue.trim().toLowerCase();
//         var anss = ques[idx].ansOption.trim().toLowerCase();
//         ans[idx] = opvalue;
//         if (stt.includes(anss) || anss.includes(stt)) {
//           totalPoint += 4;
//           totalCorrect += 1;
//         } else {
//           totalPoint -= 1;
//           totalWrong += 1;
//         }
//       }
//     }
//     document.getElementById("attempt").innerText = totalAttempt;
//     document.getElementById("skip").innerText = totalSkip;
//     var dta = document.getElementById("setNumber").innerText;
//     setTimeout(() => {
//       // console.log("submit");
//       handleUserData(dta, true);
//     }, 2000);
//   };
//   const handleSubmit = () => {
//     if (idx < 9) {
//       if (window.confirm("Do You Want to Quit")) {
//         thenSubmit();
//       } else {
//         changeEveryThing(0, 1);
//       }
//     } else {
//       thenSubmit();
//     }
//   };

//   //Conditions
//   if (count <= 0) {
//     // console.log("Hello");
//     setCount(100);
//     idx < 9 ? handleSkip() : handleSkip();
//   }
//   if (dis.next && idx === 10) {
//     console.log("Last Line");
//     setDis({ next: true, skip: true, quit: false });
//     handleUserData(set + 1, true);
//   }

//   return (
//     <>
//       <div className={Styles.overlay} id="overlay">
//         <p id="setNumber" style={{ color: "white", top: "-200%" }}>
//           {set + 1}
//         </p>
//         <p id="tabSwitch" style={{ color: "white", marginTop: "-60px" }}>
//           {change + 0}
//         </p>
//         <div className={Styles.header1}>
//           <div className={Styles.title}>
//             <span>Udaan</span>
//             <span>Quizathon</span>
//           </div>
//         </div>
//         <Animation1 />
//         <div className={Styles.overlay__div}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleOverlay}
//             disabled={load.details || load.ques}
//           >
//             {load.details
//               ? "Checking Details"
//               : load.ques
//                 ? "SetUp Things"
//                 : "Start"}
//           </Button>
//         </div>
//       </div>

//       <div className={Styles.overlay1} id="overlay1">
//         <div className={Styles.header1}>
//           <div className={Styles.title}>
//             <span>Udaan</span>
//             <span>Quizathon</span>
//           </div>
//         </div>
//         {!dis.quit ? <Animation2 /> : <Animation3 />}
//         <br></br>
//         <h1>Quiz is Over</h1>
//         <br></br>
//         <div className={Styles.overlay1__div}>
//           <Button
//             variant="contained"
//             color="secondary"
//             disabled={dis.quit}
//             onClick={handleSubmit}
//           >
//             {dis.quit ? "Submiting..." : "Quit/Submit"}
//           </Button>
//         </div>
//       </div>

//       <div className="container-fluid">
//         <div className={Styles.header}>
//           <div className={Styles.title}>
//             <span>Udaan</span>
//             <span>Quizathon</span>
//           </div>
//           <div className={Styles.quit}>
//             <Button
//               variant="contained"
//               color="secondary"
//               disabled={dis.quit}
//               onClick={handleSubmit}
//             >
//               Quit
//             </Button>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-lg-8">
//             <div className={Styles.details}>
//               <div className={Styles.qno}>
//                 <h6>Qno-{idx + 1}</h6>
//               </div>
//               <div className={Styles.timer}>
//                 <p>
//                   Time Left -{" "}
//                   <p className={Styles.time_progress} id="timer_progress">
//                     90
//                   </p>
//                 </p>
//               </div>
//             </div>

//             <div className={`${Styles.question} ${classes.question}`}>
//               <TextField
//                 disabled
//                 id="outlined-read-only-textarea"
//                 label="Question"
//                 placeholder={""}
//                 multiline
//                 variant="outlined"
//                 onCut={handleChange}
//                 onCopy={handleChange}
//                 onPaste={handleChange}
//                 value={ques ? ques[idx].question : ""}
//                 InputProps={{
//                   readOnly: true,
//                 }}
//               />
//             </div>
//             <div className={Styles.option}>
//               <form
//                 // onSubmit={handleSubmit}
//                 className={Styles.width}
//               >
//                 <FormControl
//                   component="fieldset"
//                   error={error}
//                   className={classes.formControl}
//                   onChange={handleRadioChange}
//                 >
//                   <div className={Styles.option__div}>
//                     <div>
//                       <FormLabel component="legend">
//                         Your Answer is...
//                       </FormLabel>
//                     </div>
//                     <div>
//                       <Button
//                         variant="contained"
//                         disabled={idx < 9 ? dis.skip : opvalue ? true : true}
//                         onClick={opvalue ? handleSkip : handleSkip}
//                       >
//                         {opvalue ? "Skip" : "Skip"}
//                       </Button>
//                     </div>
//                   </div>
//                   <RadioGroup
//                     aria-label="quiz"
//                     name="quiz"
//                     value={opvalue}
//                   // onChange={handleRadioChange}
//                   >
//                     {/* <FormControlLabel
//                       value={ques ? ques[idx].op1 : ""}
//                       control={<Radio color="primary" />}
//                       label={ques ? ques[idx].op1 : ""}
//                     />
//                     <FormControlLabel
//                       value={ques ? ques[idx].op2 : ""}
//                       control={<Radio color="primary" />}
//                       label={ques ? ques[idx].op2 : ""}
//                     />
//                     <FormControlLabel
//                       value={ques ? ques[idx].op3 : ""}
//                       control={<Radio color="primary" />}
//                       label={ques ? ques[idx].op3 : ""}
//                     /> */}
//                     <div className={Styles.option__div}>
//                       <div>
//                         {/* <FormControlLabel
//                           value={ques ? ques[idx].op4 : ""}
//                           control={<Radio color="primary" />}
//                           label={ques ? ques[idx].op4 : ""}
//                         /> */}
//                         <FormControl variant="outlined">
//                           <InputLabel htmlFor="component-outlined">
//                             Your Answer
//                           </InputLabel>
//                           <OutlinedInput
//                             id="component-outlined"
//                             value={opvalue}
//                             onChange={handleTextChange}
//                             label="Your Answer"
//                           />
//                           <FormHelperText id="component-helper-text">
//                             {ques[idx].type}
//                           </FormHelperText>
//                         </FormControl>
//                       </div>
//                       <div>
//                         <Button
//                           // disabled = {error}
//                           variant="contained"
//                           color="primary"
//                           disabled={dis.next && dis.quit}
//                           onClick={
//                             dis.next
//                               ? handleSubmit
//                               : opvalue
//                                 ? handleNextClick
//                                 : handleError
//                           }
//                         >
//                           {dis.next ? "Submit" : "Next"}
//                         </Button>
//                       </div>
//                     </div>
//                   </RadioGroup>
//                   <FormHelperText>{helper}</FormHelperText>
//                 </FormControl>
//               </form>
//             </div>
//           </div>
//           <div className="col-lg-4">
//             <div className={Styles.circle__content}>
//               <div className={Styles.circular}>
//                 <div className={Styles.inner}></div>
//                 <div className={Styles.outer}></div>
//                 <div className={Styles.numb} id="time">
//                   <p id="time__progress">90</p>
//                 </div>
//                 <div className={Styles.circle} id={"circle__progress"}>
//                   <div className={Styles.dot} id={"dot__progress"}>
//                     <span></span>
//                   </div>
//                   <div className={`${Styles.bar} ${Styles.left}`}>
//                     <div
//                       className={Styles.progress}
//                       id={"left__progress"}
//                     ></div>
//                   </div>
//                   <div className={`${Styles.bar} ${Styles.right}`}>
//                     <div
//                       className={Styles.progress}
//                       id={"right__progress"}
//                     ></div>
//                   </div>
//                 </div>
//               </div>
//               <div className={`${Styles.circles} ${Styles.circle2}`}>
//                 <h3>Days</h3>
//                 <h1>{day}</h1>
//               </div>
//               <div className={`${Styles.circles} ${Styles.circle3}`}>
//                 <h3>Skip</h3>
//                 <h1 id="skip">0</h1>
//               </div>
//               <div className={`${Styles.circles} ${Styles.circle4}`}>
//                 <h3>Attempt</h3>
//                 <h1 id="attempt">0</h1>
//               </div>
//             </div>
//             <br></br>
//             <div className={Styles.quit1}>
//               <div>
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   className={Styles.btn}
//                   disabled={dis.quit}
//                   onClick={handleSubmit}
//                 >
//                   Quit
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//    );
//  }