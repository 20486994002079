import { StylesProvider } from "@material-ui/core";
import React, { useState } from "react";
import { useLocation } from "wouter";
import Styles from "./Query.module.css";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Animation1 from "../../Animation/Animation2/Animation";
import axios from "axios";
import { SentimentDissatisfied } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      //       margin: theme.spacing(1),
      width: "100%",
    },
  },
  complain: {
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
}));
function Query(props) {
  const classes = useStyles();
  const [data, setData] = useState(props.check.state);
  const [load, setLoad] = useState(true);
  const [dis, setDis] = useState(false)
  const [location, setLocation] = useLocation();

  if (load && data.isLoggedIn) {
    if (data.userType == "admin" || data.year != "1") {
      setLoad(false);
    } else {
      setLoad(false);
      alert("Only for 1st Year");
      setLocation("/error");
    }
  }
  setTimeout(() => {
    if (props.check.state.isLoggedIn == false) {
      setLocation("/login");
    }
  }, 4000);
  const handleChange = () => { };
  const handleSubmit = () => {
    setDis(true)
    var query = document.getElementById("complain").value;
    var formData = {
      query: query,
      regid: data.regid,
    };
    axios.post(
      data.apiUrl + "submitQuery.php?key=" + data.publicKey,
      formData,
      {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }
    ).then((res) => {
      if (res.data) {
        alert("Submitted")
        setLocation("/thank")
      }
    });
  };
  return (
    <>
      {false ? (
        <Animation1 />
      ) : (
        <div className="container-fluid">
          <div className={Styles.header}>
            <div className={Styles.title}>
              <span>Udaan</span>
              <span>Quizhive</span>
            </div>
            <div className={Styles.quit}></div>
          </div>
          {load ? (
            <Animation1 />
          ) : (<div className="row">
            <div className={`col-lg-2`}></div>
            <div className={"col-lg-8"}>
              <div className={`${Styles.ans} ${classes.complain} `}>
                <TextField
                  id="outlined-multiline-static"
                  label="Enter Problem"
                  multiline
                  id="complain"
                  rows={4}
                  placeholder="Enter Your Problem"
                  variant="outlined"
                  required
                  onChange={handleChange}
                />
              </div>
              <div className={`${Styles.sub}`}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={dis}
                  onClick={handleSubmit}
                >
                  {dis ? "Submiting..." : "Submit"}
                </Button>
              </div>
            </div>
          </div>)}
        </div>
      )}
    </>
  );
}

export default Query;
