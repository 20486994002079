import React from 'react'; 
import { withStyles } from "@material-ui/core/styles";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Icon from '@material-ui/core/Icon';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

function ActionRow(props){
    return(
        
            <StyledTableRow >
                <StyledTableCell align="center">{props.number}</StyledTableCell>
                <StyledTableCell align="center">{props.actionName}</StyledTableCell>
                <StyledTableCell align="center"><Icon>{props.icon}</Icon></StyledTableCell>
            </StyledTableRow>
        
    )
}

export default ActionRow;