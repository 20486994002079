
import React,{useRef} from "react";

import {useIntersection} from "react-use";
import gsap from "gsap";
import CSS from "./Cofounders.module.css";
import CoFounder from "./CoFounder";
import sunilImage from "../../assets/Images/sunil.jpg";
import abhishekImage from "../../assets/Images/abhishek.jpg";
import niteshImage from "../../assets/Images/nitesh.jpg";
import saquibImage from "../../assets/Images/saquibali.jpg";

const Cofounders = () => {
  const sectionRef = useRef(null);
    const intersection = useIntersection(sectionRef,{
      root:null,
      rootMargin:"0px",
      threshold:0.4
    })
    const fadeIn = (element)=>{
      gsap.to(element,1,{
        opacity:1,
        y:0,
        ease:"power4.out",
        stagger:{
          amount:0.3
        }
      })
    }

    const fadeOut = (element)=>{
      gsap.to(element,1,{
        opacity:0,
        y:40,
        ease:"power4.out",
        stagger:{
          amount:0.3
        }
      })
    }

    intersection&&intersection.intersectionRatio<0.4?fadeOut(".fadeIn5"):fadeIn(".fadeIn5")
  return (
    <div ref={sectionRef} className="fadeIn5">
        <div className={CSS.TheFounder+"fadeIn5"}><h3>The Founders</h3></div>
      <div className={CSS.Cofounders + " row fadeIn5"}>
        
        <CoFounder
          post="Ex-President"
          imgSrc={sunilImage}
          fName="Sunil Kumar"
          session="2015-2019"
          branch="Computer Science & Engg"
        />
        <CoFounder
          post="Ex-General Manager"
          imgSrc={abhishekImage}
          fName="Kumar Abhisek"
          session="2015-2019"
          branch="Computer Science & Engg"
        />
        <CoFounder
          post="Ex-Controller"
          imgSrc={niteshImage}
          fName="Nitesh Kumar"
          session="2015-2019"
          branch="Computer Science & Engg"
        />
        <CoFounder
          post="Ex-Departmental Head"
          imgSrc={saquibImage}
          fName="Md. Hamza Ali"
          session="2015-2019"
          branch="Civil Engineering"
        />
      </div>
    </div>
  );
};

export default Cofounders;
