import React from "react";
import { TableRow, TableCell } from "@material-ui/core";

const getBranch=(b)=>{
    if(b==="1")return "CSE";
    if(b==="2")return "ECE";
    if(b==="3")return "CE";
    if(b==="4")return  "ME";
    if(b==="5")return "EE";
    if(b==="6")return "BSH"
    else return "Other";
}

const MemberTile = (props) => {
  return (
    <TableRow key={props.id}>
      <TableCell align="left">{props.regid}</TableCell>
      <TableCell align="left">{getBranch(props.branch)}</TableCell>
      <TableCell align="left">{props.name}</TableCell>
      <TableCell align="left">{props.username}</TableCell>
      <TableCell align="left">{props.deviceID}</TableCell>
      <TableCell align="left">{props.year}</TableCell>
      <TableCell align="left">{props.type}</TableCell>
    </TableRow>
  );
};

export default MemberTile;
