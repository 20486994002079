import Styles from "./Section1.module.css";
import { TweenMax, Expo, TimelineLite, Power3 } from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { useLocation } from "wouter";
import recspContainer from "../../../../Store/recspContainer";

// Get Variable from store
const container = new recspContainer();
const Variable = container.quizVariable();
// unpacking the requied variable
var todayDate = Variable.todayDate;
var gameMonth = Variable.gameMonth;
var gameStart = Variable.gameStart;
var gameEnd = Variable.gameEnd;

var curTime = new Date();
var datee = gameMonth + todayDate + ",2024 20:30:00";
var nowTime = new Date(datee).getTime();
datee = gameMonth + todayDate + ",2024 20:00:00";
var dm = nowTime - curTime.getTime();
// console.log(dm<=0);
if (nowTime - curTime.getTime() <= 0) {
  // console.log("Condition1");
  datee = gameMonth + (todayDate * 1 + 1) + ",2024 20:00:00";
  var key = document.getElementById("head");
  if (key) {
    // var msg = "Quizathon Round 1 Day " + (todayDate * 1 - 21 + 2) + " on";
    var msg = "Quiz is Over";
    key.innerHTML = msg;
  }
  // key = document.getElementById("msg");
  // if (key) {
  //   var msg = todayDate * 1 + 1 + "JULY 2021 AT 8 PM";
  //   key.innerHTML = msg;
  // }
}
var count = new Date(datee).getTime();
var xyz = true,
  xyz1 = true;
setInterval(function () {
  var now = new Date();
  var d = count - now.getTime();

  var days = Math.floor(d / (1000 * 60 * 60 * 24));
  var hours = Math.floor((d % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((d % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((d % (1000 * 60)) / 1000);

  // var day = document.getElementById("days");
  // if (day) day.innerHTML = days;

  // var hr = document.getElementById("hours");
  // if (hr) hr.innerHTML = hours;

  // var min = document.getElementById("minutes");
  // if (min) min.innerHTML = minutes;

  // var sec = document.getElementById("second");
  // if (sec) sec.innerHTML = seconds;

  if (xyz1 && nowTime - curTime.getTime() <= 0) {
    //  console.log("Condition2");

    datee = "jul" + (todayDate * 1 + 1) + ",2021 20:00:00";
    count = new Date(datee).getTime();
    var key = document.getElementById("head");
    if (key) {
      //  xyz1 = false;
      // var msg = "Quizathon Round 2 Day " + (todayDate * 1 - 21 + 2) + " on";
      var msg = "\"Quiz-a-thon 5.0\" is Over </br> 23rd May - 26th May 2024";
      key.innerHTML = msg;
    }
    // key = document.getElementById("msg");
    // if (key) {
    //   xyz1 = false;
    //   var msg = todayDate * 1 + 1 + "JULY 2021 AT 8 PM";
    //   key.innerHTML = msg;
    // }
  }
  if (xyz && days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
    xyz = false;
    var datee = "jul" + todayDate + ",2024 20:30:00";
    count = new Date(datee).getTime();
    // var key = document.getElementById("msg");
    // if (key) {
    //   key.innerHTML = "Today Round Ends in";
    // }
    // key = document.getElementById("head");
    // if (key) {
    //   var msg = "Round 1 Day " + (todayDate * 1 - 21 + 1) + " Starts";
    //   key.innerHTML = msg;
    // }
  }
}, 1000);

var check = true;

var mapp = {
  209011003012: "0",
};

var mapp = {};
export default function Section1(props) {
  const [location, setLocation] = useLocation();
  const [time, setTime] = useState(false);
  const [text, setText] = useState();
  const [data, setData] = useState(props.state);
  const [dis, setDis] = useState(true);
  const [checks, setCheck] = useState(true);

  const handleClick = () => {
    alert("Quiz is Over");
  //   var curT = curTime;
  //   if (
  //     data.userType == "admin" ||
  //     (data.userType == "user" && data.year !== "1" && data.year !== "2")
  //   ) {
  //     if (curT.getHours() == gameStart) {
  //       if (
  //         data.userType == "admin" ||
  //         (data.userType == "user" && data.year !== "1" && data.year !== "2") ||
  //         curT.getMinutes() <= gameEnd
  //       ) {
  //         if (
  //           window.confirm("Do you accept our below mentioned Instructions")
  //         ) {
  //           setLocation("/quiz/play");
  //         }
  //       } else {
  //         alert("Sorry You are not Eligiable");
  //       }
  //     } else {
  //       var msg =
  //         "Quiz Starts on " + todayDate + "th " + gameMonth + " at 8:00 PM";
  //       alert(msg);
  //     }
  //   } else {
  //     var msg =
  //       "Quiz Starts on " + todayDate + "th " + gameMonth + " at 8:00 PM";
  //     alert(msg);
  //   }
  };

  const handleClick1 = () => {
    var curT = curTime;
    if (curT.getHours() === gameStart) {
      if (
        (data.userType == "admin" ||
          (data.userType != "admin" && data.year != "1")) &&
        curT.getMinutes() <= gameEnd * 1 + 29
      ) {
        setLocation("/quiz/complain");
      } else {
        alert(`Quiz Starts on ${todayDate} ${gameMonth}`);
      }
    } else if (true) {
      setLocation("/quiz/rank");
    } else {
      alert(`Quiz Starts on ${todayDate} ${gameMonth}`);
    }
  };

  const handleClick2 = () => {
    var curT = curTime;
    if (curT.getHours() == gameStart - 1 || curT.getHours() === gameStart) {
      if (true) {
        setLocation("/quiz/complain");
      }
    } else {
      setLocation("/quiz/response");
    }
  };

  if (
    !time &&
    curTime.getHours() == gameStart &&
    curTime.getMinutes() <= gameEnd + 29
  ) {
    setText("Any Complain");
    setTime(true);
  } else if (!time) {
    setTime(true);
    check = false;
    setText("Rank and Result");
  }
  if (checks && true) {
    setCheck(false);
    setDis(false);
  }

  let app = useRef(null);
  let details = useRef(null);
  let quiz = useRef(null);

  let tl = new TimelineLite({ delay: 0.8 });
  let tl3 = new TimelineLite({ delay: 0.8 });
  useEffect(() => {
    TweenMax.to(app, 0, { css: { visibility: "visible" } });
    TweenMax.to(details, 0, { css: { visibility: "visible" } });
    TweenMax.to(quiz, 0, { css: { visibility: "visible" } });

    const name = app.children[0];
    const pres = app.children[1];
    tl.to(name, 2, {
      opacity: 0,
      y: -60,
      ease: Expo.easeInOut,
    });
    tl.to(pres, 4, {
      opacity: 0,
      y: -30,
      ease: Expo.easeInOut,
    });
    TweenMax.to(app, 2, {
      delay: 1,
      top: "-100%",
      ease: Expo.easeInOut,
    });

    // Details

    TweenMax.from(details.children[0], 1, {
      delay: 4.2,
      opacity: 0,
      y: 20,
      ease: Expo.easeInOut,
    });
    TweenMax.from([details.children[1]], 1, {
      delay: 4.5,
      opacity: 0,
      y: 20,
      ease: Expo.easeInOut,
    });
    tl3.staggerFrom(
      [
        details.children[2].children[0],
        details.children[2].children[1],
        details.children[2].children[2],
        details.children[2].children[3],
        details.children[3].children[0],
        details.children[3].children[1],
        details.children[3].children[2],
      ],
      1,
      {
        opacity: 0,
        y: 44,
        ease: Power3.easeOut,
        delay: 4.8,
      },
      0.5,
      "Start"
    );
  }, [tl]);

  return (
    <>
      <div className={Styles.overlay} ref={(el) => (app = el)}>
        <h1>Udaan</h1>
        <h2> Present</h2>
      </div>
      <div className="container-fluid">
        <div className={`row ${Styles.sec1}`}>
          <div className={`col-lg-8 ${Styles.quiz}`} ref={(el) => (quiz = el)}>
            <div className={Styles.title}>
              <span>Udaan</span>
              <span>Quizathon</span>
            </div>
            <div className={Styles.center}>
              <div>
                <h1>QUIZ-A-THON</h1>
              </div>
              <div></div>
              <div>
                <h1>5.0</h1>
              </div>
              <div></div>
            </div>
          </div>
          <div className={`col-lg-4 ${Styles.date}`}>
            <div className={Styles.details} ref={(el) => (details = el)}>
              <h2 id="head">
                {/* {"Quizathon Round 2 Day " + (todayDate * 1 - 21 + 1) + " on"} */}
                {/* {"Quizathon Round 2 for Group-II [Final Round]" + " on"} */}
                {"Quiz is Over"}
              </h2>
              <h4 id="msg">
                {/* {todayDate + " " + gameMonth + " 2024 AT 8 PM"} */}
                {/* {"Thank You for Play"} */}
              </h4>
              <div className={Styles.count}>
                <div>
                  <span id="days">00</span>
                  DAYS
                </div>

                <div>
                  <span id="hours">00</span>
                  HOURS
                </div>

                <div>
                  <span id="minutes">00</span>
                  MINUTES
                </div>

                <div>
                  <span id="second">00</span>
                  SECONDS
                </div>
              </div>
              <div className={Styles.btn__div}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  disabled={dis}
                >
                  {" "}
                  Start
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={dis}
                  onClick={handleClick1}
                >
                  {" "}
                  <span id="option">{text}</span>
                </Button>
                <Button
                  variant="outlined"
                  // color="secondary"
                  disabled={dis}
                  onClick={handleClick2}
                >
                  {" "}
                  <span id="optin">Response</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
