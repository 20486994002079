import React, { useState } from "react";
import {
  Paper,
  Typography,
  FormLabel,
  Input,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  InputLabel,
  TextField,
  // TableContainer,
} from "@material-ui/core";
// import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackBar from "../../../components/SnackBar/SnackBar";
import axios from "axios";
import Save from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import $ from "jquery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CSS from "./StudentPanel.module.css";
import MemberTile from "./MemberTile";

const StudentPanel = (props) => {
  // console.log(props);
  const [saveDisable, disableSaveBtn] = useState(false);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState("");
  const [delDisable, setDelDisable] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [delModel, openDelModal] = useState(false);
  const [delRegId, setDelRegId] = useState(false);
  const [tableData,setTableData] = useState([]);
  const [searching,setSearching] = useState(false);
  const handleAddMember = (e) => {
    e.preventDefault();
    let frm = $("#memberAddFrom");
    let formData = new FormData(frm[0]);
    formData.append("key", props.container.publicKey);
    // console.log(...formData);
    disableSaveBtn(true);
    setSaving(true);
    axios
      .post(
        props.container.apiUrl + "addMember.php",
        formData,
        props.container.config
      )
      .then((response) => {
        disableSaveBtn(false);
        setSaving(false);
        if (response.data.message === "Member Added") {
          setMessage(
            <SnackBar
              key={Math.random()}
              msg="Member Add: "
              status={"Success"}
            />
          );
          frm[0].reset();
        } else if (response.data.message === null) {
          setMessage(
            <SnackBar
              key={Math.random()}
              msg="Member Add: "
              status={"Something went wrong, call Team"}
            />
          );
        } else {
          setMessage(
            <SnackBar
              key={Math.random()}
              msg="Member Add: "
              status={response.data.message}
            />
          );
        }
      })
      .catch((error) => {
        disableSaveBtn(false);
        setSaving(false);
        // console.log(error);
        setMessage(
          <SnackBar
            key={Math.random()}
            msg="Member Add: "
            status={"Something went wrong, call Team"}
          />
        );
      });
  };

  const handleDelMember = (e) => {
    e.preventDefault();

    openDelModal(true);
  };

  const handleYesDelete = () => {
    setDelDisable(true);
    setDeleting(true);
    axios
      .post(
        props.container.apiUrl + "deleteMember.php",
        { key: props.container.publicKey, regid: delRegId },
        props.container.config
      )
      .then((response) => {
        let data = response.data;
        if (data.message === "Member Deleted") {
          setMessage(
            <SnackBar
              key={Math.random()}
              msg={"Member Delete:"}
              status={"Success"}
            />
          );

          setDelDisable(false);
          setDeleting(false);
          openDelModal(false);
        } else {
          setDelDisable(false);
          setDeleting(false);
          openDelModal(false);
          setMessage(
            <SnackBar
              key={Math.random()}
              msg={"Member Delete:"}
              status={"Failed " + data.message}
            />
          );
          setDelRegId(null);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const hanldeChange = (e) => {
    setDelRegId(e.target.value);
  };

  // const validate = (data) => {
  //   if (data !== undefined || data.length > 0) {
  //     return true;
  //   }
  //   return false;
  // };

  const handleSearch = (e) => {
    // Search if any of value is present;
    e.preventDefault(e);
    let frm = $("#searchForm");
    let formData = new FormData(frm[0]);
    
    let isValid = false;
    for (var p of formData) {
      // console.log(p)
      if (p[1] !== "") {
        isValid = true;
      }
    }

    // console.log(isValid);

    if (!isValid) {
      setMessage(
        <SnackBar
          key={Math.random()}
          msg={"Please add some parameters."}
          status={""}
        />
      );
      return;
    }
    else{
    formData.append("key",props.container.publicKey);
    setSearching(true);
      axios
      .post(
        props.container.apiUrl + "searchMember.php",
        formData,
        props.container.config
      )
      .then((response) => {
        // console.log(response);
        let data = response.data;
        if (data.status === 201) {
          populateTable(data.data);
        } else {
          setMessage(
            <SnackBar
              key={Math.random()}
              msg={"Cannot get data:"}
              status={"Failed " + data.message}
            />
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    }
    setSearching(false);
  };

  const populateTable=(data)=>{
    let tArr=[];
    for(let i=0;i<data.length;i++){
      let d= data[i];
      tArr.push(
        <MemberTile
          key={d.regid}
          regid={d.regid}
          branch={d.branch}
          name={d.name}
          username={d.username}
          deviceID={d.deviceIMEI}
          year={d.year}
          type={d.type}
        />
      );
    }

    setTableData(tArr);
  }

  return (
    <div style={{ marginTop: "30px" }}>
      {/**
       * Add Div
       */}
      <Paper className={CSS.studentPanel}>
        <Typography variant="h5">
          <u>Add Member:</u>
        </Typography>
        <form id="memberAddFrom" onSubmit={(e) => handleAddMember(e)}>
          <TextField
            type="text"
            required
            id="name"
            name="name"
            label="Full Name"
          />

          <br />
          <FormControl>
            <InputLabel htmlFor="year">Year</InputLabel>
            <Select
              required
              native
              inputProps={{
                name: "year",
                id: "year",
              }}
            >
              <option aria-label="None" value="" />
              <option value={0}>Not Student/Professor/Management</option>
              <option value={1}>1st Year</option>
              <option value={2}>2nd Year/D2D</option>
              <option value={3}>3rd Year</option>
              <option value={4}>4th Year</option>
            </Select>
          </FormControl>
          <br />
          <FormControl className={CSS.formControl}>
            <InputLabel htmlFor="branch">Branch</InputLabel>
            <Select
              required
              native
              inputProps={{
                name: "branch",
                id: "branch",
              }}
            >
              <option aria-label="None" value="" />
              <option value={1}>Computer Science & Engineering</option>
              <option value={2}>Electronics & Communication Engineering</option>
              <option value={5}>Electrical Engineering</option>
              <option value={3}>Civil Engineering</option>
              <option value={4}>Mechanical Engineering</option>
              <option value={6}>BSH</option>
              <option value={7}>Others</option>
            </Select>
          </FormControl>
          <br />
          <FormControl className={CSS.formControl}>
            <InputLabel htmlFor="type">User Type</InputLabel>
            <Select
              required
              native
              inputProps={{
                name: "type",
                id: "type",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"user"}>Student</option>
              <option value={"professor"}>Professor</option>
              <option value={"management"}>Management</option>
              <option value={"hod"}>HOD</option>
            </Select>
          </FormControl>
          <br />
          <TextField
            type="number"
            required
            id="dRegid"
            name="regid"
            label="Registration No"
          />

          <br />
          <br />
          <FormLabel>DOB:</FormLabel>
          <Input
            id="dob"
            required={true}
            style={{ marginLeft: "10px" }}
            name="dob"
            type="date"
          />
          <br />
          <br />
          <Button
            disabled={saveDisable}
            style={{ background: "#000" }}
            name="submit"
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<Save />}
          >
            Save
          </Button>
          {saving ? (
            <span style={{ paddingLeft: "20px" }}>
              Saving...
              <CircularProgress style={{ color: "#000" }} />
            </span>
          ) : (
            <span></span>
          )}
          <br />
          <span>{message}</span>
        </form>
      </Paper>
      <br />
      {/**
       * Delete Div
       */}

      <Paper className={CSS.studentPanel}>
        <Typography variant="h5">
          <u>Delete Member:</u>
        </Typography>
        <form id="delMemberFrom" onSubmit={(e) => handleDelMember(e)}>
          <TextField
            type="number"
            required
            id="regid"
            name="regid"
            label="Registration No"
            value={delRegId}
            onChange={(e) => hanldeChange(e)}
          />
          <br />
          <br />
          <Button
            disabled={delDisable}
            style={{ background: "#000" }}
            name="submit"
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
          {deleting ? (
            <span style={{ paddingLeft: "20px" }}>
              Deleting...
              <CircularProgress style={{ color: "#000" }} />
            </span>
          ) : (
            <span></span>
          )}
        </form>
      </Paper>
      <br />

      {/**
       * Search Div
       */}

      <Paper className={CSS.studentPanel}>
        <Typography variant="h5">
          <u>Search Member:</u>
        </Typography>
        <form id="searchForm" onSubmit={(e) => handleSearch(e)}>
          <FormControl className={CSS.searchControl}>
            <InputLabel htmlFor="branch">Branch</InputLabel>
            <Select
              native
              inputProps={{
                name: "branch",
                id: "branch",
              }}
            >
              <option aria-label="None" value="" />
              <option value={1}>Computer Science & Engineering</option>
              <option value={2}>Electronics & Communication Engineering</option>
              <option value={5}>Electrical Engineering</option>
              <option value={3}>Civil Engineering</option>
              <option value={4}>Mechanical Engineering</option>
              <option value={6}>BSH</option>
              <option value={7}>Others</option>
            </Select>
          </FormControl>
          <FormControl className={CSS.searchControl}>
            <InputLabel htmlFor="branch">Year</InputLabel>
            <Select
              native
              inputProps={{
                name: "year",
                id: "year",
              }}
            >
              <option aria-label="None" value="" />
              <option value={0}>Not Student/Professor/Management</option>
              <option value={1}>1st Year</option>
              <option value={2}>2nd Year/D2D</option>
              <option value={3}>3rd Year</option>
              <option value={4}>4th Year</option>
            </Select>
          </FormControl>
          <TextField
            className={CSS.searchControl}
            type="email"
            id="email"
            name="email"
            label="Email"
          />
          <TextField
            className={CSS.searchControl}
            type="text"
            id="sname"
            name="name"
            label="Name"
          />
          <TextField
            className={CSS.searchControl}
            type="number"
            id="regid"
            name="regid"
            label="Registaration no."
          />
          <br />
          <br />
          <Button
            style={{ background: "#000" }}
            name="search"
            variant="contained"
            color="primary"
            type="submit"
          >
            Search
          </Button>
          
        </form>
        {searching ? (
            <span style={{ paddingLeft: "20px" }}>
              Searching...
              <CircularProgress style={{ color: "#000" }} />
            </span>
          ) : (
            <span></span>
          )}
      </Paper>
      <br />

      {/**
       *    Members Table
       */}
      <Paper className={CSS.memTable}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">Reg. ID</TableCell>
              <TableCell align="left">Branch</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">DeviceID</TableCell>
              <TableCell align="left">Year</TableCell>
              <TableCell align="left">Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              tableData.length===0 ?null:tableData
            }
          </TableBody>
        </Table>
      </Paper>

      {/**
       * Conformation dialogue
       */}
      <Dialog open={delModel}>
        <DialogTitle>Delete Notice</DialogTitle>
        <DialogContent>
          <p>Do you really want to delete member with id :{delRegId} ?</p>
          <Button color="secondary" onClick={() => handleYesDelete()}>
            Yes
          </Button>
          <Button onClick={() => openDelModal(false)}>No</Button>
          <span style={{ marginLeft: "5px" }}>
            {deleting ? "Please wait..." : ""}
          </span>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default StudentPanel;
