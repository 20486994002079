import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";

import SnackBar from "../../../components/SnackBar/SnackBar";
import axios from "axios";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CSS from "./Newsfeed.module.css";

import NewsFeedTite from "./NewsfeedTile";

const Newsfeed = (props) => {
  const [content, setContent] = useState([]);
  const [delId, setDelId] = useState(0);
  const [delModal, delModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [message, setMessage] = useState("");

  const populateTable = (dummyData) => {
    let c = [];
    for (let i = 0; i < dummyData.length; i++) {
      let d = dummyData[i];

      c.push(
        <NewsFeedTite
          key={d.id}
          delete={() => handleFeedDelete(d.id)}
          id={d.id}
          regid={d.regid}
          topicImage={d.topic}
          question={d.question}
          date={d.date}
        />
      );
    }
    setContent(c);
  };

  const getLast10Posts = () => {
    axios
      .post(
        props.container.apiUrl+"getTenNewsfeedPosts.php",
        { key: props.container.publicKey},
        props.container.config
      )
      .then((response) => {
        console.log(response);
        populateTable(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const getPosts = () => {
      getLast10Posts();
    };
    getPosts();
  }, []);

  const handleFeedDelete = (id) => {
    setDelId(id);
    delModalOpen(true);
    // getLast10Posts();
  };

  const handleYesDelete = () => {
    setDeleting(true);
    axios
      .post(
        props.container.apiUrl+"deletePost.php",
        { key: props.container.publicKey, id: delId },
        props.container.config
      )
      .then((response) => {
        console.log(response.data.message);
        let d = response.data;
        if (d.message === "Post Deleted") {
          setDeleting(false);
          delModalOpen(false);
          setMessage(
            <SnackBar
              key={Math.random()}
              msg={"Post delete:"}
              status={"Success"}
            />
          );
          getLast10Posts();
        } else {
          setDeleting(false);
          delModalOpen(false);
          setMessage(
            <SnackBar
              key={Math.random()}
              msg={"Post delete:"}
              status={"Failed"}
            />
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setMessage(
          <SnackBar
            key={Math.random()}
            msg={"Post delete:"}
            status={"Some technical error, Failed"}
          />
        );
      });
  };

  return (
    <div>
      <div className={CSS.Newsfeed}>{content}</div>
      <span>{message}</span>
      <Dialog open={delModal}>
        <DialogTitle>Delete Notice</DialogTitle>
        <DialogContent>
          <p>Do you really want to delete post with id :{delId} ?</p>
          <Button color="secondary" onClick={() => handleYesDelete()}>
            Yes
          </Button>
          <Button onClick={() => delModalOpen(false)}>No</Button>
          <span style={{ marginLeft: "5px" }}>
            {deleting ? "Please wait..." : ""}
          </span>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Newsfeed;
