import React from 'react'

import CSS from './CoFounder.module.css'

const Cofounder=(props)=>{
    return(
        <div className={CSS.Cofounder+" col-md-3"}>
            <img alt="" src={props.imgSrc}/>
            <p>{props.post}</p>
            <p className={CSS.paraName}>{props.fName}</p>
            <p>{props.branch}</p>
            <p>{props.session}</p>
        </div>
    )
}

export default Cofounder