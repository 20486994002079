import React from "react";
// import RECSPContainer from '../../Store/recspContainer'
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import Icon from "@material-ui/core/Icon";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ActionRow from "./ActionRow";
import axios from "axios";
import NoticePanel from "./NoticePanel/NoticePanel";
import ResultPanel from "./ResultPanel/ResultPanel";
import uploadGif from "../../assets/Images/uploading.gif";
import AssignmentPanel from "./Assignment/AssignmentPanel";
import QuestionPanel from "./QuestionPaper/QuestionPanel";
import NotesPanel from "./Notes/NotesPanel";
import Newsfeed from "./Newsfeed/Newsfeed";
import GalleryPanel from "./GalleryPanel/GalleryPanel";
import StudentPanel from "./StudentsPanel/StudentPanel";
import AES from "crypto-js/aes";
import { getCookie } from "../../components/Utils/Cookie";
import CryptoJS from "crypto-js";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#212121",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const drawerWidth = 240;
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        // overflow: "hidden",
        position: "relative",
        display: "inline-flex",
        width: "100%",
    },
    appBar: {
        background: "#212121",
        position: "absolute",
        marginLeft: drawerWidth,
        [theme.breakpoints.up("md")]: {
            zIndex: theme.zIndex.drawer + 1,
        },
    },
    navIconHide: {
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up("md")]: {
            position: "relative",
        },
    },
    content: {
        textAlign: "left",
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: 20,
        marginTop: 30,
    },
    listMar: {
        marginRight: 20,
        position: "relative",
        top: 6,
    },

    listMain: {
        "&:hover": {
            cursor: "pointer",
        },
        textAlign: "left",
        paddingLeft: 20,
    },
});

class ResponsiveDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            showDiv: (
                <img
                    style={{ marginTop: "60px", marginLeft: "42%" }}
                    alt="loading"
                    src={uploadGif}
                />
            ),
            adminData: null,
            classes: this.props.classes,
            theme: this.props.theme,
        };
        this.getAddAdminData = this.getAddAdminData.bind(this);
        this.hanldeNoticeBoard = this.hanldeNoticeBoard.bind(this);
        this.handleResultBoard = this.handleResultBoard.bind(this);
        this.handleAssignment = this.handleAssignment.bind(this);
        this.handleQuestion = this.handleQuestion.bind(this);
        this.handleNotes = this.handleNotes.bind(this);
        this.handleNewsfeed = this.handleNewsfeed.bind(this);
        this.handleGallery = this.handleGallery.bind(this);
        this.handleMembers = this.handleMembers.bind(this);
    }

    componentDidMount() {
        let dec = AES.decrypt(
            getCookie("_RSPTYPE"),
            this.props.check.state.publicKey
        );
        dec = dec.toString(CryptoJS.enc.Utf8);
        if (dec === "admin") {
            this.getAddAdminData();
        } else {
            console.log("NOT ADMIN");
            this.props.history.push("/login?rdr=adminpanel");
        }
    }

    getAddAdminData() {
        (async () => {
            console.log("waiting for Login");
            while (!this.props.check.state.accessToken) {
                // define the condition as you like
                await new Promise((resolve) => setTimeout(resolve, 300));
            }

            const config = {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.props.check.state.accessToken}`,
                },
            };

            const bodyParameters = {
                key: "cmVhY3RBcHBQb3J0YWwyNjc=",
            };

            axios
                .post(
                    "https://recstudentportal.com/api/v1/getAdminDetails.php",
                    bodyParameters,
                    config
                )
                .then((response) => {
                    this.setState({
                        adminData: JSON.parse(response.data),
                    });
                    // console.log(response.data);
                    this.setDashboard(response.data);
                })
                .catch((error) => {
                    console.log("ERROR", error);
                    this.props.history.push("/login?rdr=adminpanel");
                });
        })();
    }

    setDashboard(response) {
        let permi = [
            "notice",
            "result",
            "assignment",
            "previous_questions",
            "delete_post",
            "notes",
            "gallery",
            "show_users",
            "recomBook",
        ];
        let actonNames = [
            "Notice",
            "Results",
            "Assignment",
            "Question Paper",
            "Newsfeed",
            "Notes",
            "Gallery",
            "Members",
            "Books",
        ];
        let tBody = [];

        response = JSON.parse(response);
        // console.log(response[permi[0]])
        for (let i = 0; i < permi.length; i++) {
            if (response[permi[i]] === "1") {
                tBody.push(
                    <ActionRow
                        key={i}
                        number={i + 1}
                        actionName={actonNames[i]}
                        icon="check"
                    />
                );
            } else {
                tBody.push(
                    <ActionRow
                        key={i}
                        number={i + 1}
                        actionName={actonNames[i]}
                        icon="clear"
                    />
                );
            }
        }
        let main = (
            <React.Fragment>
                <div className={this.state.classes.toolbar} />
                <Typography variant="h5">
                    {"Your have the following permissions :"}
                    <div>
                        <br />
                        <TableContainer component={Paper}>
                            <Table
                                className={this.state.classes.table}
                                size="small"
                                aria-label="customized  table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">
                                            #
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            Permission
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            Allowed
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="permiBody">
                                    {tBody}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Typography>
            </React.Fragment>
        );

        this.setState({
            showDiv: main,
        });
    }

    hanldeNoticeBoard() {
        let main = <NoticePanel container={this.props.check.state} />;
        // console.log(this.state.adminData);
        if (this.state.adminData["notice"] === "1") {
            this.setState({
                showDiv: main,
            });
        } else {
            this.setState({
                showDiv: (
                    <h5 style={{ marginTop: "60px" }}>
                        You are not allowed to upload notice.
                    </h5>
                ),
            });
        }
        this.handleDrawerToggle();
    }

    handleResultBoard() {
        let main = <ResultPanel container={this.props.check.state} />;
        // console.log(this.state.adminData);
        if (this.state.adminData["result"] === "1") {
            this.setState({
                showDiv: main,
            });
        } else {
            this.setState({
                showDiv: (
                    <h5 style={{ marginTop: "60px" }}>
                        You are not allowed to upload results.
                    </h5>
                ),
            });
        }
        this.handleDrawerToggle();
    }
    handleAssignment() {
        let main = <AssignmentPanel container={this.props.check.state} />;
        if (this.state.adminData["assignment"] === "1") {
            this.setState({
                showDiv: main,
            });
        } else {
            this.setState({
                showDiv: (
                    <h5 style={{ margin: "60px" }}>You are Not allowed</h5>
                ),
            });
        }
        this.handleDrawerToggle();
    }
    handleQuestion() {
        let main = <QuestionPanel container={this.props.check.state} />;
        if (this.state.adminData["previous_questions"] === "1") {
            this.setState({
                showDiv: main,
            });
        } else {
            this.setState({
                showDiv: (
                    <h5 style={{ margin: "60px" }}>You are Not allowed</h5>
                ),
            });
        }
        this.handleDrawerToggle();
    }

    handleNotes() {
        let main = <NotesPanel container={this.props.check.state} />;
        if (this.state.adminData["notes"] === "1") {
            this.setState({
                showDiv: main,
            });
        } else {
            this.setState({
                showDiv: (
                    <h5 style={{ margin: "60px" }}>You are Not allowed</h5>
                ),
            });
        }
        this.handleDrawerToggle();
    }

    handleNewsfeed() {
        let main = <Newsfeed container={this.props.check.state} />;
        if (this.state.adminData["delete_post"] === "1") {
            this.setState({
                showDiv: main,
            });
        } else {
            this.setState({
                showDiv: (
                    <h5 style={{ margin: "60px" }}>You are Not allowed</h5>
                ),
            });
        }
        this.handleDrawerToggle();
    }

    handleGallery() {
        let main = <GalleryPanel container={this.props.check.state} />;
        if (this.state.adminData["gallery"] === "1") {
            this.setState({
                showDiv: main,
            });
        } else {
            this.setState({
                showDiv: (
                    <h5 style={{ margin: "60px" }}>You are Not allowed</h5>
                ),
            });
        }
        this.handleDrawerToggle();
    }

    handleMembers() {
        let main = <StudentPanel container={this.props.check.state} />;
        if (this.state.adminData["show_users"] === "1") {
            this.setState({
                showDiv: main,
            });
        } else {
            this.setState({
                showDiv: (
                    <h5 style={{ margin: "60px" }}>You are Not allowed</h5>
                ),
            });
        }
        this.handleDrawerToggle();
    }

    handleDrawerToggle = () => {
        this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
    };

    render() {
        const { classes, theme } = this.props;

        const drawer = (
            <div>
                <div className={classes.toolbar} />
                <Divider />
                <List
                    className={classes.listMain}
                    onClick={this.hanldeNoticeBoard}
                >
                    <Icon className={classes.listMar}>announcement</Icon>Notice
                </List>
                <Divider />
                <List
                    className={classes.listMain}
                    onClick={this.handleResultBoard}
                >
                    <Icon className={classes.listMar}>done</Icon>Results
                </List>
                <Divider />
                <List
                    className={classes.listMain}
                    onClick={this.handleAssignment}
                >
                    <Icon className={classes.listMar}>assignment</Icon>
                    Assignment
                </List>
                <Divider />
                <List
                    className={classes.listMain}
                    onClick={this.handleQuestion}
                >
                    <Icon className={classes.listMar}>help</Icon>Question Paper
                </List>
                <Divider />
                <List
                    className={classes.listMain}
                    onClick={this.handleNewsfeed}
                >
                    <Icon className={classes.listMar}>favorite</Icon>Newsfeed
                </List>
                <Divider />
                <List className={classes.listMain} onClick={this.handleNotes}>
                    <Icon className={classes.listMar}>note</Icon>Notes
                </List>
                <Divider />
                <List className={classes.listMain} onClick={this.handleGallery}>
                    <Icon className={classes.listMar}>photolibrary</Icon>Gallery
                </List>
                <Divider />
                <List className={classes.listMain} onClick={this.handleMembers}>
                    <Icon className={classes.listMar}>person</Icon>
                    Members
                </List>
                <Divider />
                <List className={classes.listMain}>
                    <Icon className={classes.listMar}>book</Icon>Books
                </List>
                <Divider />
            </div>
        );

        return (
            <div className={classes.root}>
                <AppBar position="absolute" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerToggle}
                            className={classes.navIconHide}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" noWrap>
                            Admin Panel :: REC Student Portal
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={this.state.mobileOpen}
                        onClose={this.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        variant="permanent"
                        open
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <main className={classes.content}>{this.state.showDiv}</main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(
    ResponsiveDrawer
);
