import React from "react";
import Styles from "./Card.module.css";

export default function Card(props) {
  const checkBranch = (value) => {
    if (value === "1") {
      return "CS Engg";
    }
    if (value === "2") {
      return "ECE Engg";
    }
    if (value === "3") {
      return "Civil Engg";
    }
    if (value === "4") {
      return "Mechnical Engg";
    }
    if (value === "5") {
      return "Electrical Engg";
    }
  };
  const checkYear = (value) => {
    if (value <= 4) {
      return value;
    } else {
      return "Passout";
    }
  };
  return (
    <div className={Styles.card}>
      <div className={Styles.additional}>
        <div className={Styles.user_card}>
          <div className={`${Styles.level} ${Styles.center}`}>
            {"Level - " + props.Level}{" "}
          </div>
          <div className={`${Styles.points} ${Styles.center}`}>
            {"Points- " + props.Point}{" "}
          </div>
          <img src={"https://recstudentportal.com/" + props.Photo}></img>
        </div>
        <div className={Styles.more_info}>
          <br></br>
          <h1 style={{ fontSize: "30px" }}>{props.Name} </h1>
          <div className={Styles.coords}>
            <span style={{ float: "left" }}>Year</span>
            <span>{checkYear(props.Year)} </span>
          </div>
          <br></br>
          <div className={Styles.coords}>
            <span style={{ float: "left" }}>Branch</span>
            <span>{checkBranch(props.Branch)} </span>
          </div>
          <br></br>
          <div className={Styles.coords}>
            <span style={{ float: "left" }}>Session</span>
            <span>{props.Session}</span>
          </div>

          <div className={Styles.stats}>
            <div>
              <div className={Styles.title}>Rank</div>
              <div className={Styles.value}>{props.Rank} </div>
            </div>
            <div>
              <div className={Styles.title}>Round</div>
              <div className={Styles.value}>{props.Round} </div>
            </div>
            <div>
              <div className={Styles.title}>Time</div>
              <div className={Styles.value}>{props.Time}</div>
            </div>
            <div>
              <div className={Styles.title}>Total Pts</div>
              <div className={Styles.value}>{props.contest==="2ndcodewar1"?(props.Round==="3"?"2":"7"):props.Total} </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.general}>
        <br></br>
        <p>
          <h2>{props.title}</h2>It is a quiz contest which is conducted by Rec
          student portal. Details of Rank {props.Rank}.
        </p>
        <span className={Styles.more}>
          Congratulation <span className={Styles.cong_name}>{props.Name}</span>
        </span>
      </div>
    </div>
  );
}
