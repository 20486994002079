import React,{useRef} from "react";
import {useIntersection} from "react-use";
import gsap from "gsap";
import "./AppFeatures.css";
import downloadNow from "../../assets/Images/play.png";
import { Link } from "wouter";

const AppFeature = () => {
  const sectionRef = useRef(null);
    const intersection = useIntersection(sectionRef,{
      root:null,
      rootMargin:"0px",
      threshold:0.3
    })
    const fadeIn2 = (element)=>{
      gsap.to(element,1,{
        opacity:1,
        y:0,
        ease:"power4.out",
        stagger:{
          amount:0.3
        }
      })
    }

    const fadeOut = (element)=>{
      gsap.to(element,1,{
        opacity:0,
        y:40,
        ease:"power4.out",
        stagger:{
          amount:0.3
        }
      })
    }
    intersection&&intersection.intersectionRatio<0.2?fadeOut(".fadeIn2"):fadeIn2(".fadeIn2")
  return (
    <div  className="AppFeatureBox">
      <h3 className="fadeIn2" ref={sectionRef}>App Features</h3>
      <p ref={sectionRef} className="appPara fadeIn2">
        Keeping in mind the basic and all necessary requirements of RECians,
        your lionized Team UDAAN developed it's official Android App which will
        cover all the esteemed parts of https://recstudentportal.com
      </p>
      <div>
      <div ref={sectionRef} className="FeatureBox fadeIn2">
            <span><i className="material-icons">import_contacts</i></span>
            <p>Question Paper</p>
            <p id="sPara">Offline download through app available</p>
        </div>
        <div ref={sectionRef} className="FeatureBox fadeIn2">
            <span><i className="material-icons">notification_important</i></span>
            <p>Notifications</p>
            <p id="sPara">Real time notifications about events</p>
        </div>
        <Link to={"result"}>
        <div ref={sectionRef} className="FeatureBox fadeIn2">
            <span><i className="material-icons">library_add_check</i></span>
            <p>Results</p>
            
            <p id="sPara">Semester Result PDF available, check now</p>
            
        </div>
        </Link>
        <div ref={sectionRef} className="FeatureBox fadeIn2">
            <span><i className="material-icons">library_books</i></span>
            <p>Syllabus</p>
            <p id="sPara">JUT / VBU Syllabus PDF available</p>
        </div>
        <div ref={sectionRef} className="FeatureBox fadeIn2">
            <span><i className="material-icons">favorite</i></span>
            <p>Newsfeed</p>
            <p id="sPara">Our own Newsfeed for cureent and passouts</p>
        </div>
        <div ref={sectionRef} className="FeatureBox fadeIn2">
            <span><i className="material-icons">photo_library</i></span>
            <p>Gallery</p>
            <p id="sPara">Memories of college events like TechFiesta</p>
        </div>


       
      </div>

      <div className="PlayDiv fadeIn2">
        <p>Get It on Google Play Sore</p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.udaan.recstudentportalV2"
        >
          <img className="Play" alt="playStore" src={downloadNow} />
        </a>
      </div>
    </div>
  );
};

export default AppFeature;
