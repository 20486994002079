import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useIntersection } from "react-use";
import gsap from "gsap";
import "./style.css";
import Layout from "../Layout/Layout";
import Loading from "../Loading/Loading";
export default function Personlist() {
  const [persons, setPersons] = useState([]);
  const [load,setLoad] = useState(true);

  useEffect(() => {
    if(load){

      axios
        .get(
          "https://recstudentportal.com/api/v1/getExMembers.php?key=cmVhY3RBcHBQb3J0YWwyNjc=",
          {withCredentials: true}
        )
        .then((res) => {
          //  console.log(res);
          setPersons(res.data);
          setLoad(false)
        });
    }
  },[]);

  const sectionRef = useRef(null);
  const intersection = useIntersection(sectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });
  const fadeIn = (element) => {
    gsap.to(element, 1, {
      opacity: 1,
      y: -60,
      ease: "power4.out",
      stagger: {
        amount: 0.3,
      },
    });
  };

  const fadeOut = (element) => {
    gsap.to(element, 1, {
      opacity: 0,
      y: -20,
      ease: "power4.out",
      stagger: {
        amount: 0.3,
      },
    });
  };

  intersection && intersection.intersectionRatio < 0.5
    ? fadeOut(".fadeIn")
    : fadeIn(".fadeIn");

  let colors = [
    "#1d2339",
    "#625523",
    "#b4ab17",
    "#2978b9",
    "#c5a065",
    "#d5c9d2",
    "#153faf",
    "#ca4b83",
    "#827765",
  ];

  return (
    <>
    {load?<Loading/>:
    <Layout>
      <div className="container-fluid">
        <div className="row">
          {persons.map((person, index) => (
            <div key={index} className="col-lg-3">
              <div className="card">
                <div>
                  <img
                    className="img"
                    src={
                      "https://recstudentportal.com/udaanmembers/" +
                      person.profile
                    }
                    alt=""
                  />
                </div>
                <div
                  className="content"
                  style={{
                    background:
                      "linear-gradient(0deg," + colors[index] + ",transparent)",
                  }}
                >
                  <br />
                  <h2>
                    {person.name}
                    <br />
                    <span class="pos">{person.designation}</span>
                  </h2>
                  <h2 style={{ fontSize: "16px" }}>
                    {"Session : " + person.session}
                    <br />
                    <span
                      style={{
                        fontSize: "16px",
                        color: colors[index],
                        fontWeight: "bold",
                      }}
                    >
                      <br />

                      {person.stream}
                    </span>
                  </h2>

                  <ul>
                    <li>
                      <a
                        href={"https://www.facebook.com/" + person.fb_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <abbr title="Facebook">
                          <i class="fab fa-facebook-f" aria-hidden="true" />
                        </abbr>
                      </a>
                    </li>
                    <li>
                      <a href={"tel:+91" + person.mobile}>
                        <abbr title="Phone">
                          <i
                            class="fa fa-phone"
                            aria-hidden="true"
                            style={{ transform: "rotateZ(100deg)" }}
                          />
                        </abbr>
                      </a>
                    </li>
                    <li>
                      <a
                        href={"mailto:" + person.email}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <abbr title="Mail">
                          <i class="fa fa-envelope" />
                        </abbr>
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://www.linkedin.com/feed/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <abbr title="Linkedin">
                          <i class="fab fa-linkedin-in"></i>
                        </abbr>
                      </a>
                    </li>
                    <li>
                      <a
                        href={"https://github.com/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <abbr title="GitHub">
                          <i class="fab fa-github"></i>
                        </abbr>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
    }
    </>
  );
}
