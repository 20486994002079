import React,{useState,useEffect} from 'react'
import Styles from "./Card.module.css"
import Aos from "aos";
import "aos/dist/aos.css";
import { Button } from '@material-ui/core';
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
function Card(props) {
	const [persons, setPersons] = useState([]);
  const [load, setLoad] = useState(true);
  useEffect(() => {
    Aos.init({
      offset: 100,
      duration: 1000,
      delay: 500,
    });
  });

  if (props.value != "-1" && load) {
    if (props.value === "1") {
      setPersons("Computer Science");
    }
    if (props.value === "2") {
      setPersons("Electronics and Communication");
    }
    if (props.value === "3") {
      setPersons("Civil Engineering");
    }
    if (props.value === "4") {
      setPersons("Mechnical Engineering");
    }
    if (props.value === "5") {
      setPersons("Electrical Engineering");
    }
    setLoad(false);
  }
	return (
    <div className={Styles.container} data-aos="fade-up">
      <div class={Styles.card}>
        <div class={Styles.imgBx}>
          <img src={"https://recstudentportal.com/" + props.image} />
        </div>
        <div className={Styles.contentBx}>
          <h2>{props.name}</h2>
          <div className={Styles.size}>
            <h3>Rank: {props.rank}</h3>
          </div>
          <div class={Styles.color}>
            <h3>Points : {props.points}</h3>
          </div>
          <p className={Styles.a}>{persons}</p>
          <Button color="primary">Primary</Button>
        </div>
      </div>
    </div>
  );
}

export default Card
