import React,{useRef} from "react";
import CSS from "./About.module.css";
import icFb from '../../assets/Images/ic_fb.png'
import icTwiter from '../../assets/Images/ic_twitter.png'
import icInsta from '../../assets/Images/ic_insta.png'
// import {useIntersection} from "react-use";
// import gsap from "gsap";
const AboutUs = () => {
  const sectionRef = useRef(null);
    // const intersection = useIntersection(sectionRef,{
    //   root:null,
    //   rootMargin:"0px",
    //   threshold:0.5
    // })
    // const fadeIn6 = (element)=>{
    //   gsap.to(element,1,{
    //     opacity:1,
    //     y:0,
    //     ease:"power4.out",
    //     stagger:{
    //       amount:0.3
    //     }
    //   })
    // }

    // const fadeOut = (element)=>{
    //   gsap.to(element,1,{
    //     opacity:0,
    //     y:40,
    //     ease:"power4.out",
    //     stagger:{
    //       amount:0.3
    //     }
    //   })
    // }

    //intersection&&intersection.intersectionRatio<0.5?fadeOut(".fadeIn6"):fadeIn6(".fadeIn6")
    
  return (
    <div ref={sectionRef} className={CSS.About}>
      <div className={CSS.AboutRow + " row"}>
      <div className={CSS.blank+' col-md-2'}></div>
        <div className={CSS.location+' col-md-2'}>
          <div><i className={"material-icons "+CSS.increase}>location_on</i></div>
          <div>324, Ramgarh Engineering College</div>
          <div>Murubanda, Ramgarh, Jharkhand</div>
        </div>
        <div className={CSS.mail+' col-md-2'}>
        <div><i className={"material-icons "+CSS.increase}>mail</i></div>
          <br/>
          <div className={CSS.someLeft}>recstudentportal@gmail.com</div>
          
        </div>
        <div className={CSS.phone+' col-md-2'}>
          <div><i className={"material-icons "+CSS.increase}>phone</i></div>
            <div>+91-91232-91240</div>
            <div>+91-74795-39191</div>
            <div>+91-62997-99588</div>
        </div>
        <div className={CSS.social+' col-md-2'}>
        <div className={CSS.icoImg}>
        <span className={CSS.LittleSpace}>
          <a href={"https://fb.com/recstudentportal/recstudentportal"}>
            <img style={{ width: "24px", height: "24px" }} alt="" src={icFb} />
          </a>
        </span>
        <span className={CSS.LittleSpace}>
          <a href={"https://twitter.com/recstudentportal"}>
            <img style={{ width: "24px", height: "24px" }} alt="" src={icTwiter} />
          </a>
        </span>
        <span className={CSS.LittleSpace}>
          <a href={"https://instagram.com/recstudentportal"}>
            <img style={{ width: "24px", height: "24px" }} alt="" src={icInsta} />
          </a>
        </span>
        <br/><br/><br/>
  <div><p>&copy; REC Student Portal, {new Date().getFullYear()}</p></div>
      </div>
        </div>
        <div className={CSS.blank+' col-md-2'}></div>
      </div>
    </div>
  );
};

export default AboutUs;
