import React, { useEffect, useState } from "react";
import "./Login.css";
import InputType from "../../components/UI/InputType/InputType";
import Toolbar from "../../components/Navigation/Toolbar/Toolbar";
import Sidedrawer from "../../components/Navigation/Sidedrawer/Sidedrawer";
import axios from "axios";
import { setCookie, getCookie } from "../../components/Utils/Cookie";
import { Subscribe } from "unstated";
import RECSPContainer from "../../Store/recspContainer";
import GoogleLogin from "react-google-login";
import { useLocation } from "wouter";

export function Layout2(props) {
  const [state, setState] = useState({
    showSideDrawer: false
  });
 
  function sideDrawerCloseHandler() {
    setState({
      showSideDrawer: false
    });
  };
  function openToogleMenu() {
    setState({
      showSideDrawer: true
    });
  };

  useEffect(() => {
    if (getCookie("_RSPRFTOKEN") !== "") {
    }
  }, []);

  return (
    <React.Fragment>
      <Toolbar openMenu={openToogleMenu} />
      <Sidedrawer
        open={state.showSideDrawer}
        closed={sideDrawerCloseHandler}
      />
      <main>{props.children}</main>
    </React.Fragment>
  );
}

function Login(props) {
  const [location, setLocation] = useLocation();
  const [state, setState] = useState({
    error: null,
    email: null,
    password: null,
    loading: false,
    redirect: null,
    google_email: null,
    google_token: null
  }
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("rdr")) {
      setState(s => ({
        ...s,
        redirect: query.get("rdr")
      }));
    }
  }, []);

  function onEmailChangeHandler(event) {
    isValidEmail(event.target.value);
  }
  function onPasswordChangeHandler(event) {
    isValidPassword(event.target.value);
  }

  function isValidPassword(data) {
    if (data.length >= 8) {
      setState(s => ({
        ...s,
        password: data,
        error: null
      }));
    } else {
      setState(s => ({
        ...s,
        error: "Password must be 8 characters long"
      }));
    }

    if (data.length === 0) {
      setState(s => ({
        ...s,
        error: null,
        password: null
      }));
    }
  }

  function isValidEmail(data) {
    data = data.trim();

    if (data.includes("@") && data.includes(".")) {
      setState(s => ({
        ...s,
        email: data,
        error: null
      }));
    } else {
      setState(s => ({
        ...s,
        error: "Invalid Email"
      }));

    }

    if (data.length === 0) {
      setState(s => ({
        ...s,
        error: null,
        email: null
      }));
    }
  }

  function loginButtonClickHandler(doLogin) {
    if (state.email !== null && state.password !== null) {
      setState(s => ({
        ...s,
        loading: true
      }));
      let postData = {
        email: state.email,
        password: state.password,
        key: "cmVhY3RBcHBQb3J0YWwyNjc="
      };
      axios
        .post(
          "https://recstudentportal.com/api/v1/authenticateUser.php",
          postData,
          {
            withCredentials: true,
            headers: { "Content-Type": "application/json" }
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.status === 201) {
            setCookie("_RSPRFTOKEN", response.data.data.refreshToken, 30);
            doLogin(response.data.data);
            document.body.style.backgroundImage = "none";
            if (state.redirect !== null) {
              if (state.redirect === "adminpanel") {
                setLocation("/adminpanel");
              } else {
                setLocation("/");
              }
            } else {
              setLocation("/");
            }
          } else if (response.data.status === 202) {
            setState(
              s => ({
                ...s,
                error: response.data.message,
                loading: false
              })
            );
          } else {
            setState(s => ({
              ...s,
              error: "Something went wrong, contact admin",
              loading: false
            }));
          }
        })
        .catch((error) => {
          console.log(error);
          setState(
            s => ({
              ...s,
              error: "Something went wrong, contact admin",
              loading: false
            })
          );
        });
    } else {
      setState(s => ({
        ...s,
        error: "Please enter email and password"
      }));
    }
  }

  function resGoogle(response, doLogin) {
    // console.log(response);
    setState(
      s => ({
        ...s,
        google_email: response.profileObj.email,
        google_token: response.accessToken
      })
    );

    if (state.google_email !== null) {
      setState({
        loading: true
      });
      let postData = {
        email: state.google_email,
        accessToken: state.google_token,
        key: "cmVhY3RBcHBQb3J0YWwyNjc="
      };

      axios
        .post("https://recstudentportal.com/api/v1/googleAuth.php", postData, {
          withCredentials: true,
          headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
          // console.log(response);
          if (response.data.status === 201) {
            setCookie("_RSPRFTOKEN", response.data.data.refreshToken, 30);
            doLogin(response.data.data);
            document.body.style.backgroundImage = "none";
            if (state.redirect !== null) {
              if (state.redirect === "adminpanel") {
                setLocation("/adminpanel");
              } else {
                setLocation("/");
              }
            } else {
              setLocation("/");
            }
          } else if (response.data.status === 202) {
            setState(s => ({
              ...s,
              error: response.data.message,
              loading: false
            }));
          } else {
            setState(s => ({
              ...s,
              error: "Something went wrong, contact admin",
              loading: false
            }));
          }
        })
        .catch((error) => {
          console.log(error);
          setState(s => ({
            ...s,
            error: "Something went wrong, contact admin",
            loading: false
          }));
        });
    } else {
      setState(s => ({
        ...s,
        error: "Something went wrong, contact admin",
        loading: false
      }));
    }

    // console.log(email);
  }
  function googleFailue(response) {
    // console.log(response);
  }
  function BlurImage() {

    document.getElementById("content").style.filter = "blur(8px)";
  }
  function removeBlur() {
    document.getElementById("content").style.filter = "blur(0px)";

  }
  return (
    <React.Fragment>
      <Layout2>
        <div className="container-fluid">
          <div id="content" onMouseEnter={removeBlur}></div>
          <div className="row mform">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div className="LoginFrom" onMouseEnter={BlurImage}  >
                <div className="head">
                  <div className="spanlogin">
                    <span id="L">L</span>
                    <span id="O">O</span>
                    <span id="G">G</span>
                    <span id="I">I</span>
                    <span id="N">N</span>
                  </div>
                  <br></br>
                  <br></br>
                  <br></br>
                  <p>One Account for EveryThing</p>
                </div>
                <hr />
                <InputType
                  onChange={onEmailChangeHandler}
                  name="regid"
                  type="text"
                  placeholder="Email"
                  required
                />
                <InputType
                  onChange={onPasswordChangeHandler}
                  name="password"
                  type="password"
                  placeholder="Password"
                  required
                />
                <div className="loginError">
                  {state.error === null ? "" : state.error}
                </div>
                <br />
                <br></br>
                <div>
                  <Subscribe to={[RECSPContainer]}>
                    {(recspContainer) => (
                      <button
                        disabled={state.loading === true ? true : false}
                        onClick={() =>
                          loginButtonClickHandler(recspContainer.doLogin)
                        }
                        className="btn logBtn"
                      >
                        Login
                      </button>
                    )}
                  </Subscribe>
                </div>
                {/* <br></br> */}
                <Subscribe to={[RECSPContainer]}>
                  {(recspContainer) => (
                    <GoogleLogin
                      className="googlebtn"
                      clientId="966541688745-ml0v25mp23b44st2pnoojif5gfsh8juu.apps.googleusercontent.com" // Work Only on localhost:3000 or recstudentportal.com or new.studentportal.com 
                      buttonText="Login with Google"
                      onSuccess={(data) =>
                        resGoogle(data, recspContainer.doLogin)
                      }
                      onFailure={googleFailue}
                      cookiePolicy={"single_host_origin"}
                      width={200}

                    />
                  )}
                </Subscribe>
              </div>
            </div>
          </div>
        </div>
      </Layout2>
    </React.Fragment>
  );
}

export default Login;
