
import axios from "axios";
import React, { useState } from "react";
import Animation5 from "../Animation/Animation5/Animation";
import Styles from "./Result.module.css";
import Card from "./Card/Card";
import Card1 from "./Card1/Card";
function Result(props) {
  const [load, setLoad] = useState(true);
  const [data1, setData1] = useState(null);
  const [load1,setLoad1] = useState(true);
  const [data, setData] = useState(null);
  
  if (load) {
    var forData = {
      secret: "getRankOnlyUser",
      year: props.check.state.year,
    };
    axios
      .post(
        props.check.state.apiUrl +
          "getQuizRank.php?key=" +
          props.check.state.publicKey,
          forData
      )
      .then((res) => {
                if (res.data) {
          setLoad(false);
          setData1(res.data)
        }
      });
  }
  if(load1){
    var forData = {
      secret: "getRankOnlyAdmin",
      year: props.check.state.year,
    };
    axios
      .post(
        props.check.state.apiUrl +
          "getQuizRank.php?key=" +
          props.check.state.publicKey,
        forData
      )
      .then((res) => {
                if (res.data) {
          setLoad1(false);
          setData(res.data);
        }
      });
  }
  // if(data1.length!=3 && load1){
  //   let fil = data.filter((da) => {
  //     return da.type.includes("user");
  //   });
  //   setLoad1(false);
  //   setData1(fil)
  // }
  return (
    <>
      <div className={Styles.header}>
        <div className={Styles.title}>
          <span>Udaan</span>
          <span>Quizathon</span>
        </div>
        <div className={Styles.quit}></div>
      </div>
      {load && load1 ? (
        <Animation5 />
      ) : (
        <>
          {/* <h6>
            Top 30 are qualified for the final round. Congratulations to them
            and Thank you to all for participating in Quizathon. See you next
            year.Top 30 will get the round 2 details on mail
          </h6> */}
          {props.check.state.userType == "admin" ? (
            <>
              <div className="container-fluid">
                <div className={Styles.result__rank}>
                  {data?.map((info, i) => (
                    <>
                      {i == 0 || i == 1 || i == 2 ? (
                        <Card1
                          name={info.name}
                          rank={i + 1}
                          image={info.image}
                          value={info.branch}
                          backcolor="gold"
                          color="white"
                          circle
                          points={info.pointOverall}
                        />
                      ) : (
                        <>{""}</>
                      )}
                    </>
                  ))}
                </div>
                <div className={Styles.result__rank1}>
                  {data?.map((info, i) => (
                    <>
                      {i == 0 || i == 1 || i == 2 ? (
                        ""
                      ) : (
                        <Card
                          name={info.name}
                          regid={info.regid}
                          rank={i + 1}
                          image={info.image}
                          value={info.branch}
                          color="black"
                          backcolor="white"
                          points={info.pointOverall}
                          circle={i < 30 ? true : true}
                        />
                      )}
                    </>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="container-fluid">
                <div className={Styles.result__rank}>
                  {data1?.map((info, i) => (
                    <>
                      {i == 0 || i == 1 || i == 2 ? (
                        <Card1
                          name={info.name}
                          rank={i + 1}
                          image={info.image}
                          value={info.branch}
                          backcolor="gold"
                          color="white"
                          circle
                          regid={info.regid}
                          points={info.pointOverall}
                        />
                      ) : (
                        <>{""}</>
                      )}
                    </>
                  ))}
                </div>
                <div className={Styles.result__rank1}>
                  {data1?.map((info, i) => (
                    <>
                      {i == 0 || i == 1 || i == 2 ? (
                        ""
                      ) : (
                        <Card
                          name={info.name}
                          rank={i + 1}
                          image={info.image}
                          value={info.branch}
                          regid={info.regid}
                          color="black"
                          backcolor="white"
                          points={info.pointOverall}
                          circle={i < 30 ? false : false}
                        />
                      )}
                    </>
                  ))}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Result;
