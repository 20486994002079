import React, { useEffect } from "react";
import "./App.css";
import HomePage from "./components/HomePage/HomePage";
import Notice from "./components/notice/notice";
import ExMem from "./components/member/personlist";
import Login from "./containers/Login/Login";
import axios from "axios";
import { getCookie } from "./components/Utils/Cookie";
import { Subscribe } from "unstated";
import RECSPContainer from "./Store/recspContainer";
import Adminpanel from "./containers/AdminPanel/AdminPanel";
import Winner from "./components/Winner/Winner";
import Profile from "./components/Profile/Profile";
import { Route, Switch } from "wouter";
import Post from "./Quiz/Panel/Post/Post";
import Landing from "./Quiz/Landing/Landing";
import Quiz from "./Quiz/Quiz";
import Practice from "./Quiz/Pratice";
import Invalid from "./Quiz/Invalid/Invalid";
import Thank from "./Quiz/Thank/Thank";
import Query from "./Quiz/Panel/Query/Query";
import Update from "./Quiz/Panel/Update/Update";
import Result from "./Quiz/Result/Result";
import Response from "./Quiz/Response/Response";
import Resp from "./Quiz/Response/Resp/Resp";
// import ExamResult from "./components/Result/Result"
export default function App(props) {

  useEffect(() => {

    checkLoginState();
  }
    , []);

  function getLoggedIn() {
    return props.mainContainer.state.isLoggedIn;
  }

  function checkLoginState() {
    if (getCookie("_RSPRFTOKEN") !== "") {
      let rft = getCookie("_RSPRFTOKEN");
      // console.log(rft);
      let postData = {
        refToken: rft,
        key: props.mainContainer.state.publicKey,
      };
      axios
        .post(
          `${props.mainContainer.state.apiUrl}fromRefToken.php`,
          postData,
          {
            withCredentials: true,
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((response) => {
          // console.log(response.data.data);
          if (response.data.status === 201) {
            props.mainContainer.doLogin(response.data.data);
          }
          if (response.data.status === 202) {
            // console.log(response);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }


  return (
    <div className="App">

      <Switch>
        <Route exact path="/">
          <HomePage
            store={props.mainContainer}
            isLoggedIn={getLoggedIn}
          />
        </Route>
        <Route path="/error">
          <Invalid></Invalid>
        </Route>
        <Route path="/thank">
          <Thank></Thank>
        </Route>
        <Route path="/practice">
          <Practice></Practice>
        </Route>

        <Route
          path="/quiz/complain"
          exact
          component={() => (
            <Subscribe to={[RECSPContainer]}>
              {(recspContainer) => <Query check={recspContainer} />}
            </Subscribe>
          )}
        ></Route>
        <Route
          path="/quiz/response"
          exact
          component={() => (
            <Subscribe to={[RECSPContainer]}>
              {(recspContainer) => <Response check={recspContainer} />}
            </Subscribe>
          )}
        ></Route>

        <Route path="/quiz/resp/:id" exact component={Resp} />
        <Route
          path="/quiz/panel/question"
          exact
          component={() => (
            <Subscribe to={[RECSPContainer]}>
              {(recspContainer) => <Post check={recspContainer} />}
            </Subscribe>
          )}
        ></Route>
        <Route
          path="/quiz/panel/complain"
          exact
          component={() => (
            <Subscribe to={[RECSPContainer]}>
              {(recspContainer) => <Update check={recspContainer} />}
            </Subscribe>
          )}
        ></Route>
        <Route
          path="/quiz/play"
          exact
          component={() => (
            <Subscribe to={[RECSPContainer]}>
              {(recspContainer) => <Quiz check={recspContainer} />}
            </Subscribe>
          )}
        ></Route>
        <Route
          path="/quiz/rank"
          exact
          component={() => (
            <Subscribe to={[RECSPContainer]}>
              {(recspContainer) => <Result check={recspContainer} />}
            </Subscribe>
          )}
        ></Route>

        <Route
          path="/quiz"
          exact
          component={() => (
            <Subscribe to={[RECSPContainer]}>
              {(recspContainer) => <Landing check={recspContainer} />}
            </Subscribe>
          )}
        ></Route>

        {/* <Route
          path="/result"
          exact
          component={() => (
            <Subscribe to={[RECSPContainer]}>
              {(recspContainer) => <ExamResult check={recspContainer} />}
            </Subscribe>
          )}
        ></Route> */}

        <Route
          path="/notice"
          component={() => (
            <Subscribe to={[RECSPContainer]}>
              {(recspContainer) => <Notice check={recspContainer} />}
            </Subscribe>
          )}
        ></Route>

        <Route path="/winner">
          <Winner />
        </Route>

        <Route path="/ex-members">
          <ExMem />
        </Route>
        <Route path="/login" component={Login} />

        <Route
          path="/profile"
          component={() => (
            <Subscribe to={[RECSPContainer]}>
              {(recspContainer) => <Profile check={recspContainer} />}
            </Subscribe>
          )}
        ></Route>

        <Route
          path="/adminpanel"
          component={() => (
            <Subscribe to={[RECSPContainer]}>
              {(recspContainer) => <Adminpanel check={recspContainer} />}
            </Subscribe>
          )}
        ></Route>
      </Switch>
    </div>
  );
}