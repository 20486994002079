import { Container } from "unstated";
import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";
// import {setCookie} from '../components/Utils/reloadLogin'
import { setCookie } from "../components/Utils/Cookie";
import { data } from "jquery";

class recspContainer extends Container {
  state = {
    isLoggedIn: false,
    regid: null,
    email: null,
    name: null,
    userType: null,
    accessToken: null,
    isAdmin: false,
    year: null,
    apiUrl: "https://recstudentportal.com/api/v1/",
    publicKey: "cmVhY3RBcHBQb3J0YWwyNjc=",
  };

  doLogin = (data) => {
    // console.log("LoggedIn");
    this.setState({
      isLoggedIn: true,
      regid: data.regid,
      email: data.email,
      name: data.name,
      userType: data.userType,
      accessToken: data.accessToken,
      isAdmin: data.userType,
      year: data.year,
      config: {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.accessToken}`,
        },
      },
    });

    if (data.userType === "admin") {
      var bytes = AES.encrypt("admin", this.state.publicKey);
      setCookie("_RSPTYPE", bytes.toString(), 30);
    }
  };

  doLogout = () => {
    this.setState({
      isLoggedIn: false,
      regid: null,
      email: null,
      name: null,
      userType: null,
      accessToken: null,
      year: 0,
    });

    delete_cookie("_RSPRFTOKEN");
    // console.log('Logged Out');
  };

  checkLogin = () => {
    return this.state.isLoggedIn;
  };

  checkAdmin = () => {
    // console.log(this.state.isLoggedIn)
    if (this.state.userType === "admin") {
      return true;
    }
    return false;
  };
  quizVariable = () => {
    var gameStart = 20;
    var gameEnd = 30;
    var quizTime = 90;
    var todayDate = "26";
    var gameMonth = "May";
    var day = "2";
    var round = "Round2Day" + day;

    return {
      gameStart,
      gameEnd,
      quizTime,
      todayDate,
      gameMonth,
      day,
      round,
    };
  };
}

function delete_cookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export default recspContainer;
