import React,{useRef} from 'react'

import {useIntersection} from "react-use";
import gsap from "gsap";
import CSS from './TeamInfo.module.css'
const TeamInfo=()=>{
    const sectionRef = useRef(null);
    const intersection = useIntersection(sectionRef,{
      root:null,
      rootMargin:"0px",
      threshold:0.5
    })
    const fadeIn = (element)=>{
      gsap.to(element,1,{
        opacity:1,
        y:0,
        ease:"power4.out",
        stagger:{
          amount:0.3
        }
      })
    }

    const fadeOut = (element)=>{
      gsap.to(element,1,{
        opacity:0,
        y:40,
        ease:"power4.out",
        stagger:{
          amount:0.3
        }
      })
    }

    intersection&&intersection.intersectionRatio<0.5?fadeOut(".fadeIn4"):fadeIn(".fadeIn4")
    return(
        <div ref={sectionRef} className={CSS.TeamInfo+" row"}>
        
            <div className={CSS.Heading+" col-md-3 fadeIn4"}>Who are we?</div>
            <div className={CSS.TeamPara+" col-md-9 fadeIn4"}>UDAAN is the technical team of Ramgarh Engineeing College.Team members work on the
Web Designing and Development, Android, Robotics etc.The Udaan launched
www.recstudentportal.com on 15th September, 2016. By this web application, we put a lot of data online.
It has a newsfeed where students can post doubts and anybody who knows the answer can reply to them.
We brought information like notice, notes, assignments and question bank at the fingertips of the students.</div>
        </div>
    )
}

export default TeamInfo