import React,{useRef} from 'react'
import { useIntersection } from "react-use";
import gsap from "gsap";
import CSS from './SuccessStories.module.css'
import successPng from '../../assets/Images/success.png'
const SuccessStory=()=>{
     const sectionRef = useRef(null);
    const intersection = useIntersection(sectionRef,{
      root:null,
      rootMargin:"0px",
      threshold:0.5
    })
    const fadeIn3 = (element)=>{
      gsap.to(element,1,{
        opacity:1,
        y:0,
        ease:"power4.out",
        stagger:{
          amount:0.3
        }
      })
    }

    const fadeOut = (element)=>{
      gsap.to(element,1,{
        opacity:0,
        y:40,
        ease:"power4.out",
        stagger:{
          amount:0.3
        }
      })
    }

    intersection&&intersection.intersectionRatio<0.4?fadeOut(".fadeIn3"):fadeIn3(".fadeIn3")
    return(
        <div ref ={sectionRef}className={CSS.outerDiv+" row"}>
            <div className={CSS.sucessImage+" col-md-4 fadeIn3"}>
                <img alt="successPng" src={successPng}/>
            </div>
            <div className={CSS.successInfo+" col-md-6 fadeIn3"}>
                <h3>Success Stories</h3>
                <span></span>
                <h4>Read And Get Inspired</h4>
                <p>They came...They saw..They conquered... Ramgarh Engineering College is proud of the students who brought laurels to the institution with their hard work. Their stories continue to inspire hundreds of students in the campus. Udaan is proud to present you the interview of such students who by their dedication, achieved great heights.</p>
                <p>Download the app to read the interviews.</p>
            </div>
        </div>
    )
}

export default SuccessStory