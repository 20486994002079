import { Style } from "@material-ui/icons"
import React from "react"
import Styles from "./Card.module.css"
export default function Card(props){
    return(
        // <div className={Styles.card}>
        //         <h1 className={Styles.topic}>{props.topic}</h1>
        //         <br></br>
        //         <p className={Styles.data}>{props.data}</p>
        //       </div>
        <div className={Styles.card}>
            <span>{props.data}</span>
            <p>{props.topic}</p>
        </div>
    )
} 