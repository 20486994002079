import React from "react"
import css from "./Toolbar.module.css"
import Logo from "../../Logo/Logo"
import NavigationItems from "../NavigationItems/NavigationItems"
import ToggleMenu from "../../Navigation/NavigationItems/NavigationItem/ToggleMenu"

const toolbar=(props)=>(
    <header className={css.Toolbar}>
        <ToggleMenu openMenu={props.openMenu} />
        <span className={css.mobileHead}><h5>REC Student Portal</h5></span>
        <Logo height="80%" />
        <span className={css.desktopHead}><h5>REC Student Portal</h5></span>
        <nav className={css.DesktopOnly}>
           <NavigationItems />
        </nav>
    </header>
)

export default toolbar;