import React from "react";

// Option type -->

export default function Question(idx) {
  var ques = [
    [
      {
        id: "245",
        qset: "1",
        question:
          "Which of the following pairs disease is/are correctly matched? (1) Vitamin B1: Beriberi  	(2) Vitamin A : Rickets 	(3) Vitamin C : Scurvy	(4) Calcium: Cretinism    Select the correct answer using the code given below:",
        op1: "2 only",
        op2: "1 and 3 only",
        op3: "1, 3 and 4",
        op4: "All of the above",
        ansOption: "1 and 3 only",
      },
      {
        id: "247",
        qset: "1",
        question:
          "Sequence the following sentences in a coherent passage.  P: This fortuitous geological event generated a colossal amount of energy and heat that resulted in the rocks rising to an average height of 4 km across the contact zone.  Q: Thus, the geophysicists tend to think of the Himalayas as an active geological event rather than as a static geological feature.   R: The natural process of the cooling of this massive edifice absorbed large quantities of atmospheric carbon dioxide, altering the earth’s atmosphere and making it better suited for life.  S: Many millennia ago, a breakaway chunk of bedrock from the Antarctic Plate collided with the massive Eurasian Plate.",
        op1: "QPSR",
        op2: "QSPR",
        op3: "SRPQ",
        op4: "SPRQ",
        ansOption: "SPRQ",
      },
      {
        id: "252",
        qset: "1",
        question:
          "In the given text, the blanks are numbered (i)−(iv). Select the best match for all the blanks. Steve was advised to keep his head (i) before heading (ii) to bat; for, while he had a head (iii) batting, he could only do so with a cool head s (iv) his shoulders.",
        op1: "(i) down (ii) down (iii) on (iv) for ",
        op2: "(i) on (ii) down (iii) for (iv) on ",
        op3: "(i) down (ii) out (iii) for (iv) on ",
        op4: "(i) on (ii) out (iii) on (iv) for",
        ansOption: "(i) down (ii) out (iii) for (iv) on",
      },
      {
        id: "260",
        qset: "1",
        question:
          "Two wizards try to create a spell using all the four elements, water, air, fire, and earth. For this, they decide to mix all these elements in all possible orders. They also decide to work independently. After trying all possible combination of elements, they conclude that the spell does not work. How many attempts does each wizard make before coming to this conclusion, independently?",
        op1: "24",
        op2: "48",
        op3: "16",
        op4: "12",
        ansOption: "24",
      },
      {
        id: "262",
        qset: "1",
        question:
          "If ‘→’ denotes increasing order of intensity, then the meaning of the words [sick → infirm → moribund] is analogous to [silly → _______ → daft]. Which one of the given options is appropriate to fill the blank?",
        op1: "frown",
        op2: "fawn",
        op3: "vein",
        op4: "vain",
        ansOption: "vain",
      },
      {
        id: "268",
        qset: "1",
        question:
          "The number of coins of ₹1, ₹5, and ₹10 denominations that a person has are in the ratio 5:3:13. Of the total amount, the percentage of money in ₹5 coins is",
        op1: "21%",
        op2: "14.2 % ",
        op3: "10%",
        op4: "30%",
        ansOption: "10%",
      },
      {
        id: "272",
        qset: "1",
        question:
          "Consider the following pair: 1. The French Revolution: 1789-1799  2. The Haitian Revolution:1790-1791 3. The October Revolution:1911-1917 4. Green Revolution: 1965-1968  The correct pair of revolution with their year are",
        op1: "1 and 3 only",
        op2: "2 and 4 only",
        op3: "1 and 4 only",
        op4: "2 and 3 only",
        ansOption: "1 and 4 only",
      },
      {
        id: "278",
        qset: "1",
        question:
          "How many 4-digit positive integers divisible by 3 can be formed using only the digits {1, 3,4, 6, 7}, such that no digit appears more than once in a number?",
        op1: "24",
        op2: "72",
        op3: "48",
        op4: "12",
        ansOption: "48",
      },
      {
        id: "285",
        qset: "1",
        question:
          "If two distinct non-zero real variables 𝑥 and 𝑦 are such that (𝑥 + 𝑦) is proportional to (𝑥 − 𝑦) then the value of 𝑥/y",
        op1: "depends on 𝑥𝑦",
        op2: "depends only on 𝑥 and not on 𝑦",
        op3: "depends only on 𝑦 and not on 𝑥",
        op4: "is a constant",
        ansOption: "is a constant",
      },
      {
        id: "287",
        qset: "1",
        question: "The green planet in the solar system is",
        op1: "Mars",
        op2: "Uranus",
        op3: "Venus",
        op4: "Earth",
        ansOption: "Uranus",
      },
    ],
    [
      {
        id: "243",
        qset: "2",
        question:
          "In the given text, the blanks are numbered (i)−(iv). Select the best match for all the blanks. Steve was advised to keep his head (i) before heading (ii) to bat; for, while he had a head (iii) batting, he could only do so with a cool head s (iv) his shoulders",
        op1: "(i) down (ii) down (iii) on (iv) for ",
        op2: "(i) on (ii) down (iii) for (iv) on ",
        op3: "(i) down (ii) out (iii) for (iv) on ",
        op4: "(i) on (ii) out (iii) on (iv) for",
        ansOption: "(i) down (ii) out (iii) for (iv) on ",
      },
      {
        id: "249",
        qset: "2",
        question:
          "Two wizards try to create a spell using all the four elements, water, air, fire, and earth. For this, they decide to mix all these elements in all possible orders. They also decide to work independently. After trying all possible combination of elements, they conclude that the spell does not work. How many attempts does each wizard make before coming to this conclusion, independently?",
        op1: "24",
        op2: "48",
        op3: "16",
        op4: "12",
        ansOption: "24",
      },
      {
        id: "255",
        qset: "2",
        question:
          "Which of the following pairs disease is/are correctly matched?(1) Vitamin B1: Beriberi  	(2) Vitamin A : Rickets 	(3) Vitamin C : Scurvy	(4) Calcium: Cretinism  Select the correct answer using the code given below:",
        op1: "2 only",
        op2: "1 and 3only ",
        op3: "1, 3 and 4",
        op4: "All of the above",
        ansOption: "1 and 3only ",
      },
      {
        id: "257",
        qset: "2",
        question:
          "The number of coins of ₹1, ₹5, and ₹10 denominations that a person has are in the ratio 5:3:13. Of the total amount, the percentage of money in ₹5 coins is",
        op1: "21%",
        op2: "14.2%",
        op3: "10%",
        op4: "30%",
        ansOption: "10%",
      },
      {
        id: "263",
        qset: "2",
        question:
          "Consider the following pair: 1. The French Revolution: 1789-1799 2. The Haitian Revolution:1790-1791 3. The October Revolution:1911-1917 4. Green Revolution: 1965-1968 The correct pair of revolution with their year are",
        op1: "1 and 3 only",
        op2: "2 and 4 only",
        op3: "1 and 4 only",
        op4: "2 and 3 only",
        ansOption: "1 and 4 only",
      },
      {
        id: "265",
        qset: "2",
        question:
          "Sequence the following sentences in a coherent passage. P: This fortuitous geological event generated a colossal amount of energy and heat that resulted in the rocks rising to an average height of 4 km across the contact zone. Q: Thus, the geophysicists tend to think of the Himalayas as an active geological event rather than as a static geological feature. R: The natural process of the cooling of this massive edifice absorbed large quantities of atmospheric carbon dioxide, altering the earth’s atmosphere and making it better suited for life. S: Many millennia ago, a breakaway chunk of bedrock from the Antarctic Plate collided with the massive Eurasian Plate.",
        op1: "QPSR",
        op2: "QSPR",
        op3: "SRPQ",
        op4: "SPRQ",
        ansOption: "SPRQ",
      },
      {
        id: "269",
        qset: "2",
        question:
          "How many 4-digit positive integers divisible by 3 can be formed using only the digits {1, 3,4, 6, 7}, such that no digit appears more than once in a number?",
        op1: "24",
        op2: "72",
        op3: "48",
        op4: "12",
        ansOption: "48",
      },
      {
        id: "275",
        qset: "2",
        question:
          "If ‘→’ denotes increasing order of intensity, then the meaning of the words [sick → infirm → moribund] is analogous to [silly → _______ → daft]. Which one of the given options is appropriate to fill the blank?",
        op1: "frown",
        op2: "fawn",
        op3: "vein",
        op4: "vain",
        ansOption: "vain",
      },
      {
        id: "279",
        qset: "2",
        question:
          " Which of the three banks will be merged with the other two to create India’s third-largest bank?",
        op1: "Punjab National Bank",
        op2: "Indian Bank",
        op3: "Bank of Baroda",
        op4: "Dena Bank",
        ansOption: "Indian Bank",
      },
      {
        id: "282",
        qset: "2",
        question:
          " The world’s nation 5G mobile network was launched by which country?",
        op1: "Japan",
        op2: "Asia",
        op3: "South Korea",
        op4: "Malaysia",
        ansOption: "South Korea",
      },
    ],
    [
      {
        id: "241",
        qset: "3",
        question:
          " If ‘→’ denotes increasing order of intensity, then the meaning of the words [dry → arid → parched] is analogous to [diet → fast → ________ ]. Which one of the given options is appropriate to fill the blank?",
        op1: "starve",
        op2: "reject",
        op3: "feast",
        op4: "deny",
        ansOption: "starve",
      },
      {
        id: "250",
        qset: "3",
        question:
          " If two distinct non-zero real variables 𝑥 and 𝑦 are such that (𝑥 + 𝑦) is proportional to (𝑥 − 𝑦) then the value of 𝑥/y",
        op1: "depends on 𝑥𝑦",
        op2: "depends only on 𝑥 and not on 𝑦",
        op3: "depends only on 𝑦 and not on 𝑥",
        op4: "is a constant",
        ansOption: "is a constant",
      },
      {
        id: "253",
        qset: "3",
        question:
          "Consider the following sample of numbers: 9, 18, 11, 14, 15, 17, 10, 69, 11, 13 The median of the sample is.",
        op1: "13.5",
        op2: "14",
        op3: "11",
        op4: "18.7",
        ansOption: "13.5",
      },
      {
        id: "258",
        qset: "3",
        question:
          "The number of coins of ₹1, ₹5, and ₹10 denominations that a person has are in the ratio 5:3:13. Of the total amount, the percentage of money in ₹5 coins is",
        op1: "21%",
        op2: "14%",
        op3: "10%",
        op4: "30%",
        ansOption: "10%",
      },
      {
        id: "266",
        qset: "3",
        question: "The green planet in the solar system is?",
        op1: "Mars",
        op2: "Uranus",
        op3: "Venus",
        op4: "Earth",
        ansOption: "Uranus",
      },
      {
        id: "270",
        qset: "3",
        question:
          "In the given text, the blanks are numbered (i)−(iv). Select the best match for all the blanks. Steve was advised to keep his head (i) before heading (ii) to bat;for, while he had a head (iii)batting, he could only do so with a cool heads (iv)his shoulders.",
        op1: "(i) down (ii) down (iii) on (iv) for",
        op2: "(i) on (ii) down (iii) for (iv) on",
        op3: "(i) down (ii) out (iii) for (iv) on",
        op4: "(i) on (ii) out (iii) on (iv) for",
        ansOption: "(i) down (ii) out (iii) for (iv) on",
      },
      {
        id: "273",
        qset: "3",
        question:
          "A rectangular paper sheet of dimensions 54 cm × 4 cm is taken. The two longer edges of the sheet are joined together to create a cylindrical tube. A cube whose surface area is equal to the area of the sheet is also taken. Then, the ratio of the volume of the cylindrical tube to the volume of the cube is",
        op1: "1/π",
        op2: "2/π",
        op3: "3/π",
        op4: "4/π",
        ansOption: "1/π",
      },
      {
        id: "276",
        qset: "3",
        question:
          "Which of the three banks will be merged with the other two to create India’s third-largest bank?",
        op1: "Punjab National Bank",
        op2: "Indian Bank",
        op3: "Bank of Baroda",
        op4: "Dena Bank",
        ansOption: "Indian Bank",
      },
      {
        id: "280",
        qset: "3",
        question:
          "A rectangular paper of 20 cm × 8 cm is folded 3 times. Each fold is made along the line of symmetry, which is perpendicular to its long edge. The perimeter of the finalfolded sheet (in cm) is",
        op1: "18",
        op2: "24",
        op3: "20",
        op4: "21",
        ansOption: "18",
      },
      {
        id: "283",
        qset: "3",
        question:
          "The world’s nation 5G mobile network was launched by which country?",
        op1: "Japan",
        op2: "Asia",
        op3: "South Korea",
        op4: "Malaysia",
        ansOption: "South Korea",
      },
    ],
  ];
  return ques[idx];
}

// One word type -->

// export default function Question(idx) {
//   var ques = [
//     [
//       {
//         id: "245",
//         qset: "1",
//         question:
//           "A man arranges too pay off a debt of Rs 3600 by 40 annual installments which form an arithmetic series. When 30 of the instalments are paid, he dies leaving one-third of the debt unpaid, the value of the first installment is p. What is (p-51)?",
//         type: "None Decimal Number",
//         ansOption: "0",
//       },
//       {
//         id: "247",
//         qset: "1",
//         question:
//           " A speaks truth in 75% cases and B in 80% of the cases. Percentage of the cases are they likely to contradict each other, narrating the same incident is X.. What is 2X/35 ?",
//         type: "None Decimal Number",
//         ansOption: "2",
//       },
//       {
//         id: "252",
//         qset: "1",
//         question:
//           "In an election between two candidates, one got 55% of the total valid votes, 20% of the votes were invalid. \nIf the total number of votes was 6500, the number of valid votes that the other candidate got, was :",
//         type: "None Decimal Number",
//         ansOption: "2340",
//       },
//       {
//         id: "260",
//         qset: "1",
//         question:
//           "Tickets numbered 1 to 10 are mixed up and then a ticket is drawn at random. What is the probability that the ticket drawn has a number which is a multiple of 3 or 5?",
//         type: "Fraction",
//         ansOption: "2/5",
//       },
//       {
//         id: "262",
//         qset: "1",
//         question:
//           "A boat takes 8 hours to move downstream from point P to Q and to return to point P moving upstream. If the \nspeed of the stream is 4 km/hr and speed of the boat in still water is 6 km/hr, what is the distance between point P and Q? (in km)\n",
//         type: "None Decimal Number",
//         ansOption: "18",
//       },
//       {
//         id: "268",
//         qset: "1",
//         question:
//           "The LCM of two prime number x and y is 129. If x>y, find the value of 16y-7x.",
//         type: "None Decimal Number",
//         ansOption: "-17",
//       },
//       {
//         id: "272",
//         qset: "1",
//         question:
//           "There are two examinations rooms A and B. If 15 students are sent from A to B, then the number of students in each room is the same. If 20 candidates are sent from B to A, then the number of students in A is double the number of students in B. The number of students in room A is:",
//         type: "None Decimal Number",
//         ansOption: "110",
//       },
//       {
//         id: "278",
//         qset: "1",
//         question:
//           "I collected some money by raising subscription for opening a society. If the whole amount collected by 500 currency notes of 1000 denomination and each person subscribed as many rupees as twice the number of subscribers, find the number of subscribers.",
//         type: "None Decimal Number",
//         ansOption: "500",
//       },
//       {
//         id: "285",
//         qset: "1",
//         question: "5.) Find the missing words.\nFXRN:HWTM::?:RVLD",
//         type: "One Word",
//         ansOption: "PWJE",
//       },
//       {
//         id: "287",
//         qset: "1",
//         question: " Find the missing(?) letters.\nFLQV:JISU::DINR:?",
//         type: "One Word",
//         ansOption: "HFPQ",
//       },
//     ],
//     [
//       {
//         id: "243",
//         qset: "2",
//         question:
//           "The manufacturer of a certain item can sell all he can produce at the selling price of Rs 60 each. It costs him Rs 40 in materials and labour to produce each item and he has overhead expenses of Rs 3000 per week in order to operate the plant. The number of units he should produce and sell in order to make a profit of at least Rs 1000 per week is n. What is 4n/100 ?",
//         type: "None Decimal Number",
//         ansOption: "8",
//       },
//       {
//         id: "249",
//         qset: "2",
//         question:
//           " The supplement of ([dozen sixes] + 80) degrees is T. What is [(T/10)+1.2] ?",
//         type: "None Decimal Number",
//         ansOption: "4",
//       },
//       {
//         id: "255",
//         qset: "2",
//         question:
//           "Two numbers are respectively  50% and 80% more than a third number. The ratio of the two numbers is:",
//         type: "None Decimal Number",
//         ansOption: "5:6",
//       },
//       {
//         id: "257",
//         qset: "2",
//         question:
//           "A problem is given to three students whose chances of solving it are 1/5, 1/6 and 1/7 respectively. What is the probability that the problem will be solved?",
//         type: "Fraction",
//         ansOption: "34/35",
//       },
//       {
//         id: "263",
//         qset: "2",
//         question:
//           "A boat takes 8 hours to move downstream from point P to Q and to return to point P moving upstream. If the \nspeed of the stream is 4 km/hr and speed of the boat in still water is 6 km/hr, what is the distance between point P and Q? (in km)\n",
//         type: "None Decimal Number",
//         ansOption: "18",
//       },
//       {
//         id: "265",
//         qset: "2",
//         question:
//           "The ages of Ram and Shyam differ by 15 years. 5 years ago, the Ram was 2 times as old as Shyam. What is the present age of Ram? (in years)",
//         type: "None Decimal Number",
//         ansOption: "35",
//       },
//       {
//         id: "269",
//         qset: "2",
//         question:
//           "The LCM of two prime number x and y is 129. If x>y, find the value of 16y-7x.",
//         type: "None Decimal Number",
//         ansOption: "-17",
//       },
//       {
//         id: "275",
//         qset: "2",
//         question:
//           "A boatman goes 4 km against the current of the stream in 1 hour and goes 1 km along the current in 10 minutes. How long will it take to go 5 km in stationary water?( in hours)",
//         type: "None Decimal Number",
//         ansOption: "1",
//       },
//       {
//         id: "279",
//         qset: "2",
//         question:
//           "I collected some money by raising subscription for opening a society. If the whole amount collected by 500 currency notes of 1000 denomination and each person subscribed as many rupees as twice the number of subscribers, find the number of subscribers.",
//         type: "None Decimal Number",
//         ansOption: "500",
//       },
//       {
//         id: "282",
//         qset: "2",
//         question: " If 19@6=46; 22@8=56; 35@5=76, then 55@13=?",
//         type: "None Decimal Number",
//         ansOption: "132",
//       },
//     ],
//     [
//       {
//         id: "241",
//         qset: "3",
//         question:
//           "Three boys step off together from the same spot. Their steps measure\n63 cm, 70 cm and 77 cm respectively. The maximum distance each should\ncover so that all can cover distance in complete steps is S. What is\n[(S+70)/1000] (in cm.)",
//         type: "None Decimal Number",
//         ansOption: "7",
//       },
//       {
//         id: "250",
//         qset: "3",
//         question:
//           " The supplement of ([dozen sixes] + 80) degrees is T. What is [(T/10)+1.2] ?",
//         type: "None Decimal Number",
//         ansOption: "4",
//       },
//       {
//         id: "253",
//         qset: "3",
//         question:
//           "In an election between two candidates, one got 55% of the total valid votes, 20% of the votes were invalid. \nIf the total number of votes was 6500, the number of valid votes that the other candidate got, was :",
//         type: "None Decimal Number",
//         ansOption: "2340",
//       },
//       {
//         id: "258",
//         qset: "3",
//         question:
//           "A problem is given to three students whose chances of solving it are 1/5, 1/6 and 1/7 respectively. What is the probability that the problem will be solved?",
//         type: "Fraction",
//         ansOption: "34/35",
//       },
//       {
//         id: "266",
//         qset: "3",
//         question:
//           "The ages of Ram and Shyam differ by 15 years. 5 years ago, the Ram was 2 times as old as Shyam. What is the present age of Ram? (in years)",
//         type: "None Decimal Number",
//         ansOption: "35",
//       },
//       {
//         id: "270",
//         qset: "3",
//         question:
//           "The LCM of two prime number x and y is 129. If x>y, find the value of 16y-7x.",
//         type: "None Decimal Number",
//         ansOption: "-17",
//       },
//       {
//         id: "273",
//         qset: "3",
//         question:
//           "There are two examinations rooms A and B. If 15 students are sent from A to B, then the number of students in each room is the same. If 20 candidates are sent from B to A, then the number of students in A is double the number of students in B. The number of students in room A is:",
//         type: "None Decimal Number",
//         ansOption: "110",
//       },
//       {
//         id: "276",
//         qset: "3",
//         question:
//           "A boatman goes 4 km against the current of the stream in 1 hour and goes 1 km along the current in 10 minutes. How long will it take to go 5 km in stationary water?( in hours)",
//         type: "None Decimal Number",
//         ansOption: "1",
//       },
//       {
//         id: "280",
//         qset: "3",
//         question:
//           "I collected some money by raising subscription for opening a society. If the whole amount collected by 500 currency notes of 1000 denomination and each person subscribed as many rupees as twice the number of subscribers, find the number of subscribers.",
//         type: "None Decimal Number",
//         ansOption: "500",
//       },
//       {
//         id: "283",
//         qset: "3",
//         question: " If 19@6=46; 22@8=56; 35@5=76, then 55@13=?",
//         type: "None Decimal Number",
//         ansOption: "132",
//       },
//     ],
//   ];
//   return ques[idx];
// }
